import APIDriver from './api/APIDriver';

const api = new APIDriver('permissions');

const service = {
    get: (id) => {
        api.setBearerProtection();
        return api.get('', id);
    },
    list: () => {
        api.setBearerProtection();
        return api.getAll('');
    },
    create: (data) => {
        api.setBearerProtection();
        return api.post('', data);
    },
    update: (id, data) => {
        api.setBearerProtection();
        return api.patch('', id, data);
    },
    remove: (id) => {
        api.setBearerProtection();
        return api.delete('', id);
    },
    listNormalized: () => {
        api.setBearerProtection();
        return api.getAll('normalized');
    },
    listNormalizedByRole: (roleId) => {
        api.setBearerProtection();
        return api.get('role-normalized', roleId);
    }
}

export default service;