import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import {
    MDBAnimation,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardFooter,
    MDBBtn,
} from 'mdb-react-ui-kit'
import versionService from '../../services/versions'
import encryption from '../../services/encryption'
import activityTypeService from '../../services/activityTypes'
import formService from '../../services/forms'
import activityService from '../../services/activities'
import slotService from '../../services/slots'
import SiteHeader from '../../components/SiteHeader'
import Breadcrumbs from '../../components/Breadcrumbs'
import modulePermissions from '../../utilities/modulePermissions'

export default function Index() {

    const navigateTo = useNavigate();
    const [selectedProfile, setSelectedProfile] = useState(null);
    const { id } = useParams()
    const [versionData, setVersionData] = React.useState({})
    const [modules, setModules] = React.useState({})
    const [activityTypeData, setActivityTypeData] = React.useState([])
    const [formData, setFormData] = React.useState([])
    const [activityData, setActivityData] = React.useState([])
    const [slotData, setSlotData] = React.useState([])

    const handleSelectedProfile = () => {
        let token = localStorage.getItem('selectedProfile');
        if (token) {
            let descryptedToken = encryption.decrypt(token);
            setSelectedProfile(descryptedToken);
        }
    }

    const handleLoadVersion = async () => {
        let data = await versionService.get(id)
        setVersionData(data)
    }

    const handlePageModules = async () => {
        let modulesData = {};
        modulesData = {
            activityTypes: [],
            forms: [],
            activities: [],
            slots: []
        }
        let available = false;

        available = await modulePermissions(selectedProfile?.roleName, 'activityTypes', 'list');
        if (available) modulesData.activityTypes.push('list');
        available = await modulePermissions(selectedProfile?.roleName, 'forms', 'list');
        if (available) modulesData.forms.push('list');
        available = await modulePermissions(selectedProfile?.roleName, 'activities', 'list');
        if (available) modulesData.activities.push('list');
        available = await modulePermissions(selectedProfile?.roleName, 'slots', 'list');
        if (available) modulesData.slots.push('list');
        setModules(modulesData);
    }

    const loadActivityTypes = async () => {
        try {
            let activityTypeList;
            activityTypeList = await activityTypeService.listByVersion(id)
            setActivityTypeData([
                ...activityTypeList
            ]);
        } catch (error) {
            console.log(error);
        }
    }

    const loadSlots = async () => {
        try {
            let slotList;
            slotList = await slotService.listByVersion(id)
            setSlotData([
                ...slotList
            ]);
        } catch (error) {
            console.log(error);
        }
    }

    const loadActivities = async () => {
        try {
            let activityList;
            activityList = await activityService.listByVersion(id)
            setActivityData([
                ...activityList
            ]);
        } catch (error) {
            console.log(error);
        }
    }

    const loadForms = async () => {
        try {
            let formList;
            formList = await formService.listByVersion(id)
            setFormData([
                ...formList
            ]);
        } catch (error) {
            console.log(error);
        }
    }

    const btnActivityTypes = () => {
        navigateTo(`/activity-types/${id}`);
    }

    const btnActivities = () => {
        navigateTo(`/activities/${id}`);
    }

    const btnForms = () => {
        navigateTo(`/forms/${id}`);
    }

    const btnSlots = () => {
        navigateTo(`/slots/${id}`);
    }

    const btnTree = () => {
        navigateTo(`/tree/self-evaluation/${id}`);
    }

    const breadCrumbsData = [
        {
            text: 'Inicio',
            href: `/home`
        },
        {
            text: 'Versiones',
            href: `/versions`
        },
        {
            text: `${versionData.versionName}`,
        },
    ];

    useEffect(() => {
        handleSelectedProfile()
    }, [])

    useEffect(() => {
        if (selectedProfile != null) {
            handlePageModules();
        }
    }, [selectedProfile])

    useEffect(() => {
        if (modules !== {}) {
            loadActivityTypes();
            loadForms();
            loadActivities();
            loadSlots();
            handleLoadVersion()
            console.log(modules)
        }
    }, [modules])

    return (
        <>
            <SiteHeader
                selectedProfile={selectedProfile}
            />
            <MDBAnimation
                animation='fade-in'
                start='onLoad'
                duration={700}
            >
                <MDBRow className='my-4 ms-4'>
                    <Breadcrumbs
                        items={breadCrumbsData}
                    />
                </MDBRow>
                <MDBContainer fluid>
                    <MDBRow className='my-4 ms-4'>
                        <h4>Versión {versionData.versionName}</h4>
                    </MDBRow>
                    <MDBRow className='my-4 ms-4'>
                        {
                            selectedProfile && modules?.activityTypes?.includes('list') && (
                                <MDBCol size={'3'}>
                                    <MDBCard>
                                        <MDBCardBody>
                                            <MDBCardTitle>
                                                Tipos de Actividad
                                            </MDBCardTitle>
                                            <MDBCardText>
                                                Cantidad: {activityTypeData?.length}
                                            </MDBCardText>
                                        </MDBCardBody>
                                        <MDBCardFooter className='text-end'>
                                            <MDBBtn
                                                color='primary'
                                                size='xl'
                                                onClick={btnActivityTypes}
                                            >
                                                Ver
                                            </MDBBtn>
                                        </MDBCardFooter>
                                    </MDBCard>
                                </MDBCol>
                            )
                        }
                        {
                            selectedProfile && modules?.forms?.includes('list') && (
                                <MDBCol size={'3'}>
                                    <MDBCard>
                                        <MDBCardBody>
                                            <MDBCardTitle>
                                                Formularios
                                            </MDBCardTitle>
                                            <MDBCardText>
                                                Cantidad: {formData?.length}
                                            </MDBCardText>
                                        </MDBCardBody>
                                        <MDBCardFooter className='text-end'>
                                            <MDBBtn
                                                color='primary'
                                                size='xl'
                                                onClick={btnForms}
                                            >
                                                Ver
                                            </MDBBtn>
                                        </MDBCardFooter>
                                    </MDBCard>
                                </MDBCol>
                            )
                        }
                        {
                            selectedProfile && modules?.activities?.includes('list') && (
                                <MDBCol size={'3'}>
                                    <MDBCard>
                                        <MDBCardBody>
                                            <MDBCardTitle>
                                                Actividades
                                            </MDBCardTitle>
                                            <MDBCardText>
                                                Cantidad: {activityData?.length}
                                            </MDBCardText>
                                        </MDBCardBody>
                                        <MDBCardFooter className='text-end'>
                                            <MDBBtn
                                                color='primary'
                                                size='xl'
                                                onClick={btnActivities}
                                            >
                                                Ver
                                            </MDBBtn>
                                        </MDBCardFooter>
                                    </MDBCard>
                                </MDBCol>
                            )
                        }
                        {
                            selectedProfile && modules?.slots?.includes('list') && (
                                <MDBCol size={'3'}>
                                    <MDBCard>
                                        <MDBCardBody>
                                            <MDBCardTitle>
                                                Cupos
                                            </MDBCardTitle>
                                            <MDBCardText>
                                                Cantidad: {
                                                    slotData?.reduce((a, b) => parseInt(a) + parseInt(b.slotQuantity), 0)
                                                }
                                            </MDBCardText>
                                        </MDBCardBody>
                                        <MDBCardFooter className='text-end'>
                                            <MDBBtn
                                                color='primary'
                                                size='xl'
                                                onClick={btnSlots}
                                            >
                                                Ver
                                            </MDBBtn>
                                        </MDBCardFooter>
                                    </MDBCard>
                                </MDBCol>
                            )
                        }
                        <MDBCol size={'3'} className='mt-4'>
                            <MDBCard>
                                <MDBCardBody>
                                    <MDBCardTitle>
                                        Autodiagnósticos
                                    </MDBCardTitle>
                                    <MDBCardText>
                                        
                                    </MDBCardText>
                                </MDBCardBody>
                                <MDBCardFooter className='text-end'>
                                    <MDBBtn
                                        color='primary'
                                        size='xl'
                                        onClick={btnTree}
                                    >
                                        Ver
                                    </MDBBtn>
                                </MDBCardFooter>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBAnimation>

        </>
    )
}
