import React, { useEffect, useState } from 'react'
import SiteHeader from '../components/SiteHeader'
import Breadcrumbs from '../components/Breadcrumbs'
import encryption from '../services/encryption'
import { MDBAnimation, MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardText, MDBCardTitle, MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router-dom'
import modulePermissions from '../utilities/modulePermissions'
import auth from '../services/authentication'
import version from '../services/versions'
import institution from '../services/institutions'
import user from '../services/users'
import files from '../services/files'

export default function Home() {

    const navigateTo = useNavigate();
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [versionData, setVersionData] = useState([]);
    const [institutionData, setInstitutionData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [modules, setModules] = useState({});

    const handleSelectedProfile = () => {
        let token = localStorage.getItem('selectedProfile');
        if (token) {
            let descryptedToken = encryption.decrypt(token);
            setSelectedProfile(descryptedToken);
        }
    }

    const breadCrumbsData = [
        {
            text: 'Inicio'
        }
    ];

    const loadVersions = async () => {
        let versionList = await version.list();
        setVersionData([
            ...versionList
        ]);
    }

    const loadInstitutions = async () => {
        let credentials = localStorage.getItem('token');
        let descryptedToken = encryption.decrypt(credentials);

        try {
            let institutionList;
            if (["Establecimiento", "Asesor"].includes(selectedProfile?.roleName)) {
                institutionList = await auth.get_institutions(descryptedToken.id, selectedProfile?.roleId)
            } else {
                institutionList = await institution.list()
            }
            setInstitutionData([
                ...institutionList
            ]);
        } catch (error) {
            console.log(error);
        }
    }

    const loadUsers = async () => {
        let userList = await user.list();
        setUserData([
            ...userList
        ]);
    }

    const btnVersions = () => {
        navigateTo(`/versions`);
    }

    const btnInstitutions = () => {
        navigateTo(`/institutions`);
    }

    const btnUsers = () => {
        navigateTo(`/users`);
    }

    const btnPermissions = () => {
        navigateTo(`/permissions`);
    }

    const btnSchedule = () => {
        navigateTo(`/schedule-view`);
    }

    const handlePageModules = async () => {
        let modulesData = {};
        modulesData = {
            versions: [],
            institutions: [],
            users: []
        }
        let available = false;

        available = await modulePermissions(selectedProfile.roleName, 'versions', 'list');
        if (available) modulesData.versions.push('list');
        available = await modulePermissions(selectedProfile.roleName, 'institutions', 'list');
        if (available) modulesData.institutions.push('list');
        available = await modulePermissions(selectedProfile.roleName, 'users', 'list');
        if (available) modulesData.users.push('list');

        setModules(modulesData);
    }

    useEffect(() => {

    }, [modules]);

    useEffect(() => {
        handleSelectedProfile();
    }, []);

    useEffect(() => {
        if (!selectedProfile) {
            return;
        } else {
            console.log(selectedProfile)
        }

        handlePageModules();
    }, [selectedProfile]);

    const loadFiles = async () => {
        //const fileResults = await files.list();
        //console.log(fileResults);
    }

    useEffect(() => {
        if (!modules) return;
        loadFiles();
        loadVersions();
        loadInstitutions();
        loadUsers();
    }, [modules]);

    useEffect(() => {
        if (selectedProfile?.roleName === 'Asesor') {
            navigateTo(`/schedule-view`);
        }
        /*
        if (selectedProfile === null) {
            navigateTo(`/profile-selector`);
        }
        */
        if (institutionData.length > 0) {
            if(institutionData.length === 1 && selectedProfile?.roleName === 'Establecimiento'){
                navigateTo(`/institution/${institutionData[0].id}`);
            }
        }
    }, [institutionData]);

    return (
        <>
            <SiteHeader
                selectedProfile={selectedProfile}
            />
            <MDBAnimation
                animation='fade-in'
                start='onLoad'
                duration={700}
            >
                <MDBRow className='my-4 mx-4'>
                    <Breadcrumbs
                        items={breadCrumbsData}
                    />
                </MDBRow>
                <MDBContainer fluid>
                    <MDBRow className='my-4 ms-4'>
                        <h4>Inicio</h4>
                    </MDBRow>
                    <MDBRow className='mx-4'>
                        {
                            selectedProfile === null && (
                                <MDBCol>
                                    <p>
                                        No se ha seleccionado un perfil válido. Puede hacerlo  <a href='/profile-selector'>acá</a>
                                    </p>
                                </MDBCol>
                            )
                        }
                        {
                            selectedProfile?.roleName === 'Administrador' && (
                                <>
                                    <MDBCol size={'3'}>
                                        <MDBCard>
                                            <MDBCardBody>
                                                <MDBCardTitle>
                                                    Permisos
                                                </MDBCardTitle>
                                                <MDBCardText>
                                                    Administración de capacidades por rol
                                                </MDBCardText>
                                            </MDBCardBody>
                                            <MDBCardFooter className='text-end'>
                                                <MDBBtn
                                                    color='primary'
                                                    size='xl'
                                                    onClick={btnPermissions}
                                                >
                                                    Ver
                                                </MDBBtn>
                                            </MDBCardFooter>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol size={'3'}>
                                        <MDBCard>
                                            <MDBCardBody>
                                                <MDBCardTitle>
                                                    Agenda
                                                </MDBCardTitle>
                                                <MDBCardText>
                                                    Revisión de agenda de asesores
                                                </MDBCardText>
                                            </MDBCardBody>
                                            <MDBCardFooter className='text-end'>
                                                <MDBBtn
                                                    color='primary'
                                                    size='xl'
                                                    onClick={btnSchedule}
                                                >
                                                    Ver
                                                </MDBBtn>
                                            </MDBCardFooter>
                                        </MDBCard>
                                    </MDBCol>
                                </>
                            )
                        }
                        {
                            selectedProfile && modules?.versions?.includes('list') && (
                                <MDBCol size={'3'}>
                                    <MDBCard>
                                        <MDBCardBody>
                                            <MDBCardTitle>
                                                Versiones
                                            </MDBCardTitle>
                                            <MDBCardText>
                                                Cantidad: {versionData?.length}
                                            </MDBCardText>
                                        </MDBCardBody>
                                        <MDBCardFooter className='text-end'>
                                            <MDBBtn
                                                color='primary'
                                                size='xl'
                                                onClick={btnVersions}
                                            >
                                                Ver
                                            </MDBBtn>
                                        </MDBCardFooter>
                                    </MDBCard>
                                </MDBCol>
                            )
                        }
                        {
                            selectedProfile && modules?.institutions?.includes('list') && (
                                <MDBCol size={'3'}>
                                    <MDBCard>
                                        <MDBCardBody>
                                            <MDBCardTitle>
                                                Establecimientos
                                            </MDBCardTitle>
                                            <MDBCardText>
                                                Cantidad: {institutionData?.length}
                                            </MDBCardText>
                                        </MDBCardBody>
                                        <MDBCardFooter className='text-end'>
                                            <MDBBtn
                                                color='primary'
                                                size='xl'
                                                onClick={btnInstitutions}
                                            >
                                                Ver
                                            </MDBBtn>
                                        </MDBCardFooter>
                                    </MDBCard>
                                </MDBCol>
                            )
                        }
                        {
                            selectedProfile && modules?.users?.includes('list') && (
                                <MDBCol size={'3'}>
                                    <MDBCard>
                                        <MDBCardBody>
                                            <MDBCardTitle>
                                                Usuarios
                                            </MDBCardTitle>
                                            <MDBCardText>
                                                Cantidad: {userData?.length}
                                            </MDBCardText>
                                        </MDBCardBody>
                                        <MDBCardFooter className='text-end'>
                                            <MDBBtn
                                                color='primary'
                                                size='xl'
                                                onClick={btnUsers}
                                            >
                                                Ver
                                            </MDBBtn>
                                        </MDBCardFooter>
                                    </MDBCard>
                                </MDBCol>
                            )
                        }
                    </MDBRow>
                </MDBContainer>
            </MDBAnimation>
        </>
    )
}
