import React, { useState } from 'react';
import {
    MDBInput,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBContainer,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter

} from 'mdb-react-ui-kit';
import LOGO from '../assets/img/logo-color.png';
import { Link, useNavigate } from 'react-router-dom';
import auth from '../services/authentication'

export default function ErrorNotFound() {

    const [loading, setLoading] = useState(false);

    const navigateTo = useNavigate();
    return (

        <MDBContainer className='vh-100'>
            <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                {
                    loading ? (
                        <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>Cargando...</span>
                        </div>
                    ) : (
                        <MDBCol lg={'6'} md={'8'}>
                            <MDBRow className=' text-center'>
                                <MDBCol
                                    size="12"
                                    className='my-4'
                                >
                                    <img src={LOGO} alt='Logo' className='img-fluid mx-auto w-75' />
                                    <hr />
                                    <h1 className='text-center'>404 No encontrado</h1>
                                </MDBCol>
                            </MDBRow>
                                <MDBRow className='text-center'>
                                
                                    <p style={
                                        {
                                            display: 'inline-block',
                                        }
                                    }>
                                        Para volver, haga click <Link to='/home'>aquí</Link>
                                    </p>
                                    

                            </MDBRow>
                        </MDBCol>
                    )
                }
            </MDBRow>

        </MDBContainer>

    );
}