import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SiteHeader from '../components/SiteHeader'
import Breadcrumbs from '../components/Breadcrumbs'
import {
    MDBAnimation,
    MDBContainer,
    MDBRow,
    MDBDatatable,
    MDBBtn,
    MDBIcon,
    MDBCol,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBSelect,
} from 'mdb-react-ui-kit'
import encryption from '../services/encryption'
import stateService from '../services/states'
import cityService from '../services/cities'
import institution from '../services/institutions'
import userService from '../services/users'
import userRoleService from '../services/userRoles'
import notificationService from '../services/notifications'
import roleService from '../services/roles'
import modulePermissions from '../utilities/modulePermissions'
import auth from '../services/authentication'
import { read } from 'xlsx'

export default function Institution() {

    const moduleName = 'institution';
    const navigateTo = useNavigate();
    const [urlParams, setUrlParams] = useSearchParams();
    const fileInputRef = useRef();

    const [selectedProfile, setSelectedProfile] = useState(null);

    const [file, setFile] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [modalBulkImportShow, setModalBulkImportShow] = useState(false);
    const [modalImportShow, setModalImportShow] = useState(false);
    const [modalImportMessage, setModalImportMessage] = useState('');
    const [modalSendShow, setModalSendShow] = useState(false);
    const [modalAddShow, setModalAddShow] = useState(false);
    const [modalEditShow, setModalEditShow] = useState(false);
    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    const [modalAssignInstitutionUserShow, setModalAssignInstitutionUserShow] = useState(false);
    const [modalAssignAdviserUserShow, setModalAssignAdviserUserShow] = useState(false);
    const [userId, setUserId] = useState(null);
    const [entryId, setEntryId] = useState(null);
    const [state, setState] = useState({
        state_id: 1,
        city_id: 1,
        institutionName: '',
        institutionRBD: '',
        institutionAddress1: '',
        institutionAddress2: '',
        institutionPhone: '',

    });

    const [cityData, setCityData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [userRoleData, setUserRoleData] = useState([]);
    const [modules, setModules] = useState({});

    const breadCrumbsData = [
        {
            text: 'Inicio',
            href: `/home`
        },
        {
            text: 'Establecimientos',
        }
    ];

    const loadStateData = async () => {
        let stateList = await stateService.list()
            .then((response) => {
                return response.map((item) => {
                    return {
                        value: item.id,
                        text: item.stateName,
                    }
                })
            })

        setStateData([
            ...stateList
        ]);
    }

    const loadCityData = async (stateId) => {
        let cityList = await cityService.listByState(stateId)
            .then((response) => {
                return response?.map((item) => {
                    return {
                        value: item.id,
                        text: item.cityName,
                        defaultSelected: item.id == state?.city_id
                    }
                })
            });

        setCityData([
            ...cityList
        ]);
    }

    const loadUserData = async () => {
        let userList = await userService.list();
        setUserData(
            userList.map((item) => {
                return {
                    value: item.id,
                    text: item.userName
                }
            })
        )
    }

    const loadRoleData = async () => {
        let roleList = await roleService.list();
        setRoleData(
            roleList.map((item) => {
                return {
                    value: item.id,
                    text: item.roleName
                }
            })
        )
    }

    const handleSelectedProfile = () => {
        let token = localStorage.getItem('selectedProfile');
        if (token) {
            let descryptedToken = encryption.decrypt(token);
            setSelectedProfile(descryptedToken);
        }
    }

    const toggleImportModal = () => {
        if (modalImportShow === false) {
            //setModalImportMessage('');
        }
        setModalImportShow(!modalImportShow);
    }

    const toggleAddModal = () => {
        if (modalAddShow === false) {
            setState({
                state_id: 1,
                city_id: 1,
                institutionName: '',
                institutionRBD: '',
                institutionAddress1: '',
                institutionAddress2: '',
                institutionPhone: '',
            });
        }
        setModalAddShow(!modalAddShow);
    }

    const toggleEditModal = (newState) => {
        setModalEditShow(newState);
    }

    const toggleDeleteModal = (newState) => {
        setModalDeleteShow(newState);
    }

    const toggleAssignInstitutionUserModal = (newState) => {
        setModalAssignInstitutionUserShow(newState);
    }

    const toggleAssignAdviserUserModal = (newState) => {
        setModalAssignAdviserUserShow(newState);
    }

    const toggleSendModal = (newState) => {
        setModalSendShow(newState);
    }

    const toggleBulkImportModal = (newState) => {
        if (newState === false) {
            setFile(null);
            setFileData([]);
        }
        setModalBulkImportShow(newState);
    }

    const btnActionEdit = (item, id) => {
        let newState = {
            ...item,
            state_id: parseInt(item.state_id),
        };
        console.log(item)
        delete newState.id;
        setEntryId(id);
        setState(newState);
        toggleEditModal(true);
    }

    const btnActionDelete = (item, id) => {
        let newState = { ...item };
        delete newState?.id;
        setEntryId(id);
        setState(newState);
        toggleDeleteModal(true);
    }

    const btnActionAssignInstitutionUser = (item, id) => {
        setEntryId(id);
        let newState = { ...item };
        setState(newState);
        toggleAssignInstitutionUserModal(true);
    }

    const btnActionAssignAdviserUser = (item, id) => {
        setEntryId(id);
        let newState = { ...item };
        setState(newState);
        toggleAssignAdviserUserModal(true);
    }

    const [asyncData, setAsyncData] = useState({
        columns: [
            {
                label: 'ID',
                field: 'id',
            },
            {
                label: 'Nombre',
                field: 'institutionName',
            },
            {
                label: 'RBD',
                field: 'institutionRBD',
            },
            {
                label: 'Región',
                field: 'state_id',
            },
            {
                label: 'Acciones',
                field: 'actions',
            },
        ],
        rows: []
    });

    const [dataLoading, setDataLoading] = useState(false);

    const handleDataLoad = async () => {

        let credentials = localStorage.getItem('token');
        let descryptedToken = encryption.decrypt(credentials);

        let institutions;
        if (["Establecimiento", "Asesor"].includes(selectedProfile?.roleName)) {
            institutions = await auth.get_institutions(descryptedToken.id, selectedProfile?.roleId);
        } else {
            institutions = await institution.list()
        }

        setAsyncData({
            columns: asyncData.columns,
            rows: institutions.map((item, index) => {
                return {
                    id: index + 1,
                    institutionName: item.institutionName,
                    institutionRBD: item.institutionRBD,
                    state_id: item.state.stateName,
                    actions: <>
                        {
                            selectedProfile && modules?.institutions?.includes('update') && (
                                <MDBBtn color='info' size='lg' className='me-2' floating onClick={() => {
                                    console.log(item)
                                    btnActionEdit(item, item.id)
                                }}>
                                    <MDBIcon icon='edit' />
                                </MDBBtn>
                            )
                        }
                        {
                            selectedProfile && modules?.institutions?.includes('delete') && (
                                <MDBBtn color='danger' size='lg' className='me-2' floating onClick={() => btnActionDelete(item, item.id)}>
                                    <MDBIcon icon='trash' />
                                </MDBBtn>
                            )
                        }
                        {
                            selectedProfile?.roleName === 'Administrador' && (
                                <>
                                    <MDBBtn color='primary' size='lg' className='me-2' floating onClick={() => {
                                        //alert asignar Usuario Establecimiento
                                        btnActionAssignInstitutionUser(item, item.id)
                                    }
                                    }>
                                        <MDBIcon icon='user-plus' />
                                    </MDBBtn>
                                </>
                            )
                        }
                        {
                            selectedProfile && modules?.institutions?.includes('internal') && (
                                <MDBBtn
                                    color='secondary'
                                    size='lg'
                                    floating
                                    type='button'
                                    onClick={() => {
                                        navigateTo(`/${moduleName}/${item.id}`);
                                    }}
                                >
                                    <MDBIcon icon='eye' />
                                </MDBBtn>
                            )
                        }
                    </>
                }
            })

        })
    }

    const handleSend = async () => {
        const result = await notificationService.notifyCreatedAccounts();
        toggleSendModal(false);
    }

    const handleCreate = async () => {
        await institution.create(state);
        handleDataLoad();
        toggleAddModal();
        setState({
            state_id: 1,
            city_id: 1,
            institutionName: '',
            institutionRBD: '',
            institutionAddress1: '',
            institutionAddress2: '',
            institutionPhone: '',
        });
    }

    const handleUpdate = async () => {
        await institution.update(entryId, {
            ...state
        });
        handleDataLoad();
        toggleEditModal(false);
    }

    const handleDelete = async () => {
        await institution.remove(entryId);
        handleDataLoad();
        setState({
            state_id: 1,
            city_id: 1,
            institutionName: '',
            institutionRBD: '',
            institutionAddress1: '',
            institutionAddress2: '',
            institutionPhone: '',
        });
        toggleDeleteModal(false);
    }

    const handleAssignInstitutionUser = async (newUserId) => {
        setUserId(newUserId);
        let institution_id = entryId;
        let user_id = newUserId;
        //get role "Establecimiento"
        let role = roleData.find((item) => item.text === 'Establecimiento');

        let userRoleInInstitution = await userRoleService.list()
        userRoleInInstitution = userRoleInInstitution.find((item) => item.user_id === user_id && item.role_id === role.value && item.institution_id === institution_id);
        if (userRoleInInstitution) {
            let onConfirm = confirm('El usuario ya tiene asignado el rol de Establecimiento en esta entrada ¿Desea remover el rol de Establecimiento al usuario?')
            if (onConfirm) {
                await userRoleService.remove(userRoleInInstitution.id);
                alert('Usuario removido correctamente del establecimiento');
            } else {
                alert('El usuario no fue removido del establecimiento')
            }
            toggleAssignInstitutionUserModal(false);
        } else {
            await userRoleService.create({
                user_id,
                role_id: role.value,
                institution_id
            });
            alert('Usuario asignado correctamente');
            toggleAssignInstitutionUserModal(false);
        }
    }

    const handleBulkImport = async () => {

        const bulkData = {
            items: fileData
        };

        console.log(bulkData);

        const result = await institution.bulkCreate(bulkData);

        if (result) {
            let completeMessage = '';
            
            //alert('Registros creados: ' + result.created + ' de ' + result.amount)

            completeMessage += result.message + "\r\n";
            completeMessage += 'Registros creados: ' + result.created + ' de ' + result.amount + "\r\n";

            if (result.created > 0) {
                let rbds = [];
                let rbdString = '';
                result.data.forEach((item) => {
                    rbds.push(item.institutionRBD);
                    rbdString += item.institutionRBD + ', ';
                })
                rbdString = rbdString.substring(0, rbdString.length - 2);
                //alert('RBDs creados: ' + rbdString);
                completeMessage += 'RBDs creados: ' + rbdString + "\r\n";
            }
            setModalImportMessage(completeMessage);
            toggleBulkImportModal(false);
            toggleImportModal(true);

        } else {
            alert('Error al importar');
        }

        handleDataLoad();

    }

    useEffect(() => {
        //handleDataLoad();
    }, [dataLoading, asyncData.columns]);

    useEffect(() => {
        if (asyncData.rows.length === 0) {
            setDataLoading(true);
        } else {
            setDataLoading(false);
        }
    }, [asyncData.rows]);

    useEffect(() => {
        handleSelectedProfile();
    }, []);

    useEffect(() => {
        loadUserData();
        loadRoleData();
        loadStateData();
    }, []);

    useEffect(() => {
        if (state?.state_id) {
            loadCityData(state?.state_id);
        }
    }, [state]);

    const handlePageModules = async () => {
        let modulesData = {};
        modulesData = {
            institutions: []
        }
        let available = false;

        available = await modulePermissions(selectedProfile.roleName, 'institutions', 'create');
        if (available) modulesData.institutions.push('create');
        available = await modulePermissions(selectedProfile.roleName, 'institutions', 'update');
        if (available) modulesData.institutions.push('update');
        available = await modulePermissions(selectedProfile.roleName, 'institutions', 'delete');
        if (available) modulesData.institutions.push('delete');
        available = await modulePermissions(selectedProfile.roleName, 'institutions', 'internal');
        if (available) modulesData.institutions.push('internal');

        setModules(modulesData);
    }

    useEffect(() => {
        handleDataLoad();
    }, [modules]);

    useEffect(() => {
        if (selectedProfile) {
            handlePageModules();
        }
    }, [selectedProfile]);

    useEffect(() => {
        if (!modalBulkImportShow) {
            setFile(null);
            setFileData([]);
            fileInputRef.current.value = '';
        }
    }, [modalBulkImportShow]);

    useEffect(() => {
        if (fileData?.length > 0) {
            console.log(fileData)
        }
    }, [fileData]);

    return (
        <>
            <SiteHeader
                selectedProfile={selectedProfile}
            />
            <MDBAnimation
                animation='fade-in'
                start='onLoad'
                duration={700}
            >
                <MDBRow className='my-4 ms-4'>
                    <Breadcrumbs
                        items={breadCrumbsData}
                    />
                </MDBRow>
                <MDBContainer>
                    <MDBRow className='my-4 ms-4'>
                        <h4>Establecimientos</h4>
                    </MDBRow>
                    {
                        selectedProfile && (
                            <MDBRow className='text-end'>
                                <MDBCol className='me-0 pe-0'>
                                    {
                                        modules?.institutions?.includes('create') && (
                                            <>
                                                <MDBBtn color='primary' className='my-4' size='lg' floating onClick={() => {
                                                    toggleAddModal();
                                                }}>
                                                    <MDBIcon icon='plus' />
                                                </MDBBtn>
                                                <MDBBtn color='primary' outline className='my-4 ms-2' size='lg' floating onClick={() => {
                                                    toggleBulkImportModal(true);
                                                }}>
                                                    <MDBIcon icon='file-import' />
                                                </MDBBtn>
                                            </>
                                        )
                                    }
                                    {
                                        selectedProfile.roleName == 'Administrador' && (
                                            <MDBBtn color='info' className='my-4 ms-2' size='lg' floating onClick={() => {
                                                toggleSendModal(true);
                                            }}>
                                                <MDBIcon icon='paper-plane' />
                                            </MDBBtn>
                                        )
                                    }
                                </MDBCol>
                            </MDBRow>
                        )
                    }
                    <MDBRow>
                        <MDBDatatable
                            data={asyncData}
                            rowsText='Filas'
                            ofText='de'
                            searchLabel='Buscar'
                            search
                            noFoundMessage='No se encontraron resultados'
                            isLoading={dataLoading}
                            searchInputProps={
                                {
                                    'className': 'pb-1',
                                }
                            }
                        />
                    </MDBRow>
                </MDBContainer>
            </MDBAnimation>

            <MDBModal tabIndex='-1' show={modalAddShow} setShow={setModalAddShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Agregar establecimiento</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleAddModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput
                                label='Nombre establecimiento'
                                value={state?.institutionName}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        institutionName: e.target.value
                                    });
                                }}
                                className='mb-4'
                            />
                            <MDBInput
                                label='RBD'
                                value={state?.institutionRBD}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        institutionRBD: e.target.value
                                    });
                                }}
                                className='mb-4'
                            />
                            <MDBInput
                                label='Dirección 1'
                                value={state?.institutionAddress1}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        institutionAddress1: e.target.value
                                    });
                                }}
                                className='mb-4'
                            />
                            <MDBInput
                                label='Dirección 2'
                                value={state?.institutionAddress2}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        institutionAddress2: e.target.value
                                    });
                                }}
                                className='mb-4'
                            />
                            <MDBSelect
                                label='Región'
                                data={stateData}
                                onValueChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            state_id: e.value
                                        });
                                    }
                                }
                                className='mb-4'
                                search
                                searchLabel='Buscar'
                            />
                            <MDBSelect
                                label='Comuna'
                                data={cityData}
                                onValueChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            city_id: e.value
                                        });
                                    }
                                }
                                className='mb-4'
                                search
                                searchLabel='Buscar'
                            />
                            <MDBInput
                                label='Teléfono'
                                value={state?.institutionPhone}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        institutionPhone: e.target.value
                                    });
                                }}
                                className='mb-4'
                            />

                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={toggleAddModal}>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                color='primary'
                                onClick={
                                    () => {
                                        handleCreate()
                                    }
                                }

                            >Insertar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1' show={modalEditShow} setShow={setModalEditShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Editar establecimiento</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleEditModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput
                                label='Nombre establecimiento'
                                value={state?.institutionName}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        institutionName: e.target.value
                                    });
                                }}
                                className='mb-4'
                            />
                            <MDBInput
                                label='RBD'
                                value={state?.institutionRBD}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        institutionRBD: e.target.value
                                    });
                                }}
                                className='mb-4'
                            />
                            <MDBInput
                                label='Dirección 1'
                                value={state?.institutionAddress1}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        institutionAddress1: e.target.value
                                    });
                                }}
                                className='mb-4'
                            />
                            <MDBInput
                                label='Dirección 2'
                                value={state?.institutionAddress2}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        institutionAddress2: e.target.value
                                    });
                                }}
                                className='mb-4'
                            />
                            <MDBSelect
                                label='Región'
                                data={
                                    stateData.map((item) => {
                                        return {
                                            text: item.text,
                                            value: item.value,
                                            defaultSelected: item.value == state?.state_id
                                        }
                                    })
                                }
                                onValueChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            state_id: e.value
                                        });
                                    }
                                }
                                className='mb-4'
                                search
                                searchLabel='Buscar'
                            />
                            <MDBSelect
                                label='Comuna'
                                data={cityData}
                                onValueChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            city_id: e.value
                                        });
                                    }
                                }
                                className='mb-4'
                                search
                                searchLabel='Buscar'
                            />
                            <MDBInput
                                label='Teléfono'
                                value={state?.institutionPhone}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        institutionPhone: e.target.value
                                    });
                                }}
                                className='mb-4'
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleEditModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    handleUpdate();
                                }}
                            >Actualizar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1' show={modalDeleteShow} setShow={setModalDeleteShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Eliminar establecimiento</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleDeleteModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>¿Está seguro que desea eliminar el establecimiento {state?.institutionName}?</p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleDeleteModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    handleDelete();
                                }}
                            >Eliminar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1'
                show={modalAssignInstitutionUserShow}
                setShow={setModalAssignInstitutionUserShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Asignar usuario</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleAssignInstitutionUserModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>
                                ¿Está seguro que desea asignar el usuario al establecimiento {state?.institutionName}?
                            </p>
                            <MDBSelect
                                label='Usuario'
                                data={
                                    userData.map((user) => {
                                        return {
                                            ...user,
                                            defaultSelected: user.id === userId
                                        }
                                    })
                                }
                                onValueChange={
                                    (e) => {
                                        setUserId(e.value);
                                    }
                                }
                                className='mb-4'
                                search
                                searchLabel='Buscar'
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleAssignInstitutionUserModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    if (userId == null) {
                                        handleAssignInstitutionUser(userData[0].value);
                                    } else {
                                        handleAssignInstitutionUser(userId);
                                    }
                                }}
                            >Asignar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1' show={modalSendShow} setShow={setModalSendShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Notificar registrados</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleSendModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            Está a punto de enviar una notificación a los usuarios recién registrados.
                            Esto enviará un correo electrónico a los usuarios que se registraron, con un enlace para activar sus cuentas.
                            <br /><br />
                            ¿Desea continuar?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleSendModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    handleSend();
                                }}
                            >Notificar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1' show={modalImportShow} setShow={setModalImportShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Resultado importación</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleImportModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody style={
                            {
                                whiteSpace: 'pre-wrap'
                            }
                        }>
                            {modalImportMessage}
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleImportModal(false)
                            }>
                                Ok
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1'
                show={modalBulkImportShow}
                setShow={setModalBulkImportShow}
            >
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Importación masiva</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleBulkImportModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            Está a punto de importar usuarios desde un archivo XLSX

                            <div className='input-group my-4'>
                                <MDBInput
                                    ref={fileInputRef}
                                    type='file'
                                    accept='.xlsx'
                                    onChange={(e) => {
                                        if (e.target.files.length > 0) {
                                            setFile(e.target.files[0]);
                                            //console.log(e.target.files[0])
                                            const reader = new FileReader();

                                            reader.onload = (e) => {
                                                const data = e.target.result;
                                                const workbook = read(data);
                                                
                                                const institutionSheet = workbook.Sheets?.Establecimientos || null;
                                                if (institutionSheet != null) {
                                                    //read letters from A to I
                                                    const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
                                                    const institutions = [];
                                                    let i = 2;
                                                    let institution = null;
                                                    while (true) {
                                                        institution = {};
                                                        for (let j = 0; j < letters.length; j++) {
                                                            const letter = letters[j];
                                                            const cell = institutionSheet[letter + i];
                                                            if (cell == null) {
                                                                continue;
                                                            }
                                                            institution[letter] = cell.v;
                                                        }
                                                        if (Object.keys(institution).length === 0) {
                                                            break;
                                                        }
                                                        institutions.push(institution);
                                                        i++;
                                                    }
                                                    //change name of keys
                                                    for (let i = 0; i < institutions.length; i++) {
                                                        const institution = institutions[i];
                                                        const newInstitution = {};
                                                        for (const key in institution) {
                                                            if(key == 'A'){
                                                                newInstitution['institutionName'] = institution[key];
                                                            }
                                                            if (key == 'B') {
                                                                newInstitution['institutionRBD'] = institution[key];
                                                            }
                                                            if (key == 'C') {
                                                                //Acá está el código de la región, el cual no es usado en el backend pues se rescata a partir de cityName
                                                            }
                                                            if (key == 'D') {
                                                                newInstitution['cityName'] = institution[key];
                                                            }
                                                            if (key == 'E') {
                                                                newInstitution['institutionAddress1'] = institution[key];
                                                            }
                                                            if(key == 'F'){
                                                                newInstitution['institutionAddress2'] = institution[key];
                                                            }
                                                            if (key == 'G') {
                                                                newInstitution['institutionPhone'] = institution[key];
                                                            }
                                                            if (key == 'H') {
                                                                newInstitution['userName'] = institution[key];
                                                            }
                                                            if (key == 'I') {
                                                                newInstitution['userEmail'] = institution[key];
                                                            }
                                                        }
                                                        institutions[i] = newInstitution;
                                                    }
                                                    setFileData(institutions);

                                                } else {
                                                    alert('No se encontró la hoja de "Establecimientos" en la planilla')
                                                }

                                            };

                                            reader.readAsArrayBuffer(e.target.files[0]);
                                        } else {
                                            setFileData([]);
                                            setFile(null);
                                        }
                                    }}
                                />
                            </div>

                        </MDBModalBody>
                        <MDBModalFooter>
                            {
                                fileData.length > 0 ?
                                    <>
                                        <MDBBtn color='secondary' outline onClick={
                                            () => toggleBulkImportModal(false)
                                        }>
                                            Cancelar
                                        </MDBBtn>
                                        <MDBBtn
                                            onClick={() => {
                                                handleBulkImport();
                                            }}
                                        >Importar
                                        </MDBBtn>
                                    </>
                                    :
                                    <MDBBtn color='secondary' outline onClick={
                                        () => toggleBulkImportModal(false)
                                    }>
                                        Cerrar
                                    </MDBBtn> 
                            }
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}
