import React, { useEffect, useState } from 'react'
import SiteHeader from '../../components/SiteHeader'
import Breadcrumbs from '../../components/Breadcrumbs'
import {
    MDBAnimation,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardText,
    MDBCardTitle,
    MDBRow,
    MDBCol,
    MDBContainer,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBSelect,
} from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router-dom'
import encryption from '../../services/encryption'
import auth from '../../services/authentication'
import activityService from '../../services/activities'
import activityTypeService from '../../services/activityTypes'
import institutionService from '../../services/institutions'
import versionService from '../../services/versions'
import userRoleService from '../../services/userRoles'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import moment from 'moment'
import momenttz from 'moment-timezone'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faStar,
    faStarHalfAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
    faStar as faStarRegular
} from '@fortawesome/free-regular-svg-icons'

export default function ScheduleView() {

    const navigateTo = useNavigate();

    let credentials = localStorage.getItem('bearer-token');
    let decryptedCredentials = JSON.parse(atob(credentials));

    const [activeVersion, setActiveVersion] = useState(null);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [loadedData, setLoadedData] = useState(false);
    const [versionData, setVersionData] = useState([]);
    const [institutionData, setInstitutionData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [modules, setModules] = useState({});
    const [events, setEvents] = useState([]);
    const [activityFormData, setActivityFormData] = useState({});
    const [activityTypes, setActivityTypes] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [institutionList, setInstitutionList] = useState([]);
    const [adviserList, setAdviserList] = useState([]);
    const [activityComponentList, setActivityComponentList] = useState([]);
    const [modalActivityWarningShow, setModalActivityWarningShow] = useState(false);
    const [modalActivityWarningMessage, setModalActivityWarningMessage] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentInstitution, setCurrentInstitution] = useState('');
    const [currentAdviser, setCurrentAdviser] = useState('');
    const [activityNameList, setActivityNameList] = useState(
        [
            {
                component: 'liderazgo',
                name: 'Reunión de planificación estratégica'
            },
            {
                component: 'liderazgo',
                name: 'Reunión liderazgo 2'
            },
            {
                component: 'liderazgo',
                name: 'Reunión liderazgo 3'
            },
            {
                component: 'pedagógico',
                name: 'Reunión pedagógico 1'
            },
            {
                component: 'pedagógico',
                name: 'Reunión pedagógico 2'
            },
            {
                component: 'pedagógico',
                name: 'Reunión pedagógico 3'
            },
            {
                component: 'energético',
                name: 'Reunión energético 1'
            },
            {
                component: 'energético',
                name: 'Reunión energético 2'
            }
        ]
    );

    const [firstSelectedDay, setFirstSelectedDay] = useState(null);
    const [lastSelectedDay, setLastSelectedDay] = useState(null);
    const [calendarView, setCalendarView] = useState('dayGridMonth');

    const today = new Date();

    const handleSelectedProfile = () => {
        let token = localStorage.getItem('selectedProfile');
        if (token) {
            let descryptedToken = encryption.decrypt(token);
            setSelectedProfile(descryptedToken);
        }
    }

    const breadCrumbsData = [
        {
            text: 'Inicio'
        }
    ];

    const toggleActivityWarningModal = (newState) => {
        setModalActivityWarningShow(newState);
    }

    const handleEventOnClick = async (eventItem) => {

        if (eventItem.event.extendedProps?.extraData?.id) {
            const activity = await activityService.get(eventItem.event.extendedProps.extraData.id);
            let newData = {
                id: activity.id,
                version_id: activity.version_id,
                activityCustomName: activity.activityCustomName,
                activityDateStart: activity.activityDateStart,
                activityDateEnd: activity.activityDateEnd,
                activityComponent: activity.activityComponent,
                institution_id: activity.institution.id,
                activityType_id: activity.activityType.id,
                activityStatus: activity.activityStatus
            };

            setActivityFormData(newData);
            setShowEditModal(true);
        }
    }

    const handleFieldChange = (event) => {
        setActivityFormData({
            ...activityFormData,
            [event.target.name]: event.target.value
        });
    }

    const handleAddActivity = async () => {

        try {

            //check if any of activityFormData is empty

            let messages = [];

            //if activity form has error, set class in input to show error

            if (!activityFormData.activityCustomName) {
                //messages.push('Debe ingresar un nombre para la actividad.');
            }

            let field;

            if (!activityFormData.activityDateStart) {
                messages.push("Debe ingresar una fecha de inicio para la actividad.");

                field = document.getElementById('activityDateStart');
                field.classList.add('is-invalid');
            }

            if (!activityFormData.activityDateEnd) {
                messages.push("Debe ingresar una fecha de término para la actividad.");
                field = document.getElementById('activityDateEnd');
                field.classList.add('is-invalid');
            }

            if (!activityFormData.activityType_id) {
                messages.push("Debe seleccionar un tipo de actividad.");
            }

            if (!activityFormData.institution_id) {
                messages.push("Debe seleccionar un establecimiento.");
                field = document.getElementById('institution_id');
                field.classList.add('is-invalid');
            }

            setModalActivityWarningMessage(messages);
            if (messages.length > 0) {
                toggleActivityWarningModal(true);
                return;
            }

            setActivityFormData({})
            setShowAddModal(false)

            await activityService.create(activityFormData);
            handleLoadExtraInfo();
        } catch (error) {

        }

    }

    const handleEditActivity = async () => {

        try {

            //check if any of activityFormData is empty

            let messages = [];

            //if activity form has error, set class in input to show error

            if (!activityFormData.activityCustomName) {
                //messages.push('Debe ingresar un nombre para la actividad.');
            }

            let field;

            if (!activityFormData.activityDateStart) {
                messages.push("Debe ingresar una fecha de inicio para la actividad.");

                field = document.getElementById('activityDateStart');
                field.classList.add('is-invalid');
            }

            if (!activityFormData.activityDateEnd) {
                messages.push("Debe ingresar una fecha de término para la actividad.");
                field = document.getElementById('activityDateEnd');
                field.classList.add('is-invalid');
            }

            if (!activityFormData.activityType_id) {
                messages.push("Debe seleccionar un tipo de actividad.");
            }

            if (!activityFormData.institution_id) {
                messages.push("Debe seleccionar un establecimiento.");
                field = document.getElementById('institution_id');
                field.classList.add('is-invalid');
            }

            setModalActivityWarningMessage(messages);
            if (messages.length > 0) {
                toggleActivityWarningModal(true);
                return;
            }

            setActivityFormData({})
            setShowEditModal(false)

            //await activityService.create(activityFormData);

            await activityService.update(activityFormData.id, activityFormData);

            handleLoadExtraInfo();
        } catch (error) {

        }

    }

    const handleLoadExtraInfo = async (
        props
    ) => {
        let adviserFilter = props?.adviserFilter || '';
        let institutionFilter = props?.institutionFilter || '';

        //first and last date of month

        //is not set props.startDate or props.endDate

        let firstDayOfMonth, lastDayOfMonth;
        if (!props?.startDate || !props?.endDate) {
            //fix to America/Santiago timezone
            firstDayOfMonth = momenttz().tz('America/Santiago').startOf('month').format('YYYY-MM-DD HH:mm:ss');
            lastDayOfMonth = momenttz().tz('America/Santiago').endOf('month').format('YYYY-MM-DD HH:mm:ss');
            SetDateRange(firstDayOfMonth, lastDayOfMonth);
        } else {
            firstDayOfMonth = props?.startDate;
            lastDayOfMonth = props?.endDate;
        }

        try {
            const versionResult = await versionService.list();

            let thisActiveVersion = versionResult.find(
                (version) => version.versionStatus === 'activa'
            ).id

            setActiveVersion(thisActiveVersion);

            const activityTypesResult = await activityTypeService.listByVersion(
                thisActiveVersion
            );

            let institutionIdList, adviserIdList, filteredRoleAdviser, notRepeatedAdviser;

            if (selectedProfile.roleName === 'Asesor') {
                const userRoleResult = await userRoleService.byUser(decryptedCredentials.id);
                filteredRoleAdviser = userRoleResult.filter(
                    (userRole) => userRole.role_id === selectedProfile.roleId
                );
                adviserIdList = filteredRoleAdviser.map(
                    (userRole) => userRole.user_id
                );
                notRepeatedAdviser = userRoleResult.filter(
                    (userRole) => {
                        return adviserIdList.includes(userRole.user_id)
                    }
                ).map(
                    (userRole) => {
                        return userRole.user;
                    }
                );
            } else if (selectedProfile.roleName === 'Administrador') {
                const userRoleResult = await userRoleService.list();
                filteredRoleAdviser = userRoleResult.filter(
                    (userRole) => userRole.role_id === '4'
                );
                adviserIdList = filteredRoleAdviser.map(
                    (userRole) => userRole.user_id
                );
                notRepeatedAdviser = userRoleResult.filter(
                    (userRole) => {
                        return adviserIdList.includes(userRole.user_id)
                    }
                ).map(
                    (userRole) => {
                        return userRole.user;
                    }
                );
            }

            institutionIdList = filteredRoleAdviser.map(
                (userRole) => userRole.institution_id
            );

            setActivityTypes(
                activityTypesResult.filter(
                    (activityType) => activityType.activityTypeCategory !== 'self-evaluation'
                )
            );

            /*
            const activityResult = await activityService.listByVersion(
                versionResult.find(
                    (version) => version.versionStatus === 'activa'
                ).id
            );
            */

            const activityResult = await activityService.listByVersionAndDateRange(
                versionResult.find(
                    (version) => version.versionStatus === 'activa'
                ).id,
                firstDayOfMonth,
                lastDayOfMonth
            );

            if (activityResult.length === 0) {
                setLoadedData(true);
                setEvents([]);
            } else {
                let notRepeatedInstitution = activityResult.filter(
                    (activity) => {
                        return institutionIdList.includes(activity.institution.id)
                    }
                ).map(
                    (activity) => {
                        return activity.institution;
                    }
                );

                if (adviserFilter !== '') {
                    //institutionIdList should be filtered from filteredRoleAdviser by adviserFilter
                    let temporalInstitutions = filteredRoleAdviser.filter(
                        (userRole) => userRole.user_id === adviserFilter
                    ).map(
                        (userRole) => userRole.institution_id
                    );
                    notRepeatedInstitution = notRepeatedInstitution.filter(
                        (institution) => temporalInstitutions.includes(institution.id)
                    );
                }

                notRepeatedAdviser = notRepeatedAdviser.filter(
                    (adviser, index, self) => index === self.findIndex((t) => (t.id === adviser.id))
                );

                notRepeatedInstitution = notRepeatedInstitution.filter(
                    (institution, index, self) => index === self.findIndex((t) => (t.id === institution.id))
                );

                let emptyInstitution = {
                    id: '',
                    label: 'Todos los establecimientos'
                }

                let newAdviserList = [
                    {
                        id: '',
                        label: 'Todos los asesores'
                    },
                    ...notRepeatedAdviser.map(
                        (adviser) => {
                            return {
                                id: adviser.id,
                                label: adviser.userName + ' - ' + adviser.userEmail
                            }
                        }
                    )
                ]

                const newInstitutionList = [
                    emptyInstitution,
                    ...notRepeatedInstitution.map(
                        (institution) => {
                            return {
                                id: institution.id,
                                label: institution.institutionName + ' - ' + institution.institutionRBD
                            }
                        }
                    )
                ]

                setAdviserList(
                    newAdviserList
                );

                setInstitutionList(
                    newInstitutionList
                );

                setInstitutions(
                    notRepeatedInstitution
                );

                let newEvents = [
                    ...activityResult.filter(
                        (activity) => {

                            let validActivity;
                            validActivity = activity.activityType.activityTypeCategory !== 'self-evaluation'

                            if (adviserFilter !== '') {
                                //adviser's institutions only

                                let temporalInstitutions = filteredRoleAdviser.filter(
                                    (userRole) => userRole.user_id === adviserFilter
                                ).map(
                                    (userRole) => userRole.institution_id
                                );

                                validActivity = validActivity && temporalInstitutions.includes(activity.institution.id)
                            }

                            if (institutionFilter !== '') {
                                validActivity = validActivity && activity.institution.id === institutionFilter
                            }

                            //also, filter activities by institutionIdList

                            validActivity = validActivity && institutionIdList.includes(activity.institution.id)

                            return validActivity
                        }
                    ).map(
                        (activity) => {

                            let componentColor = '#000000';

                            if (activity.activityType.activityTypeCategory === 'component') {

                                switch (activity.activityComponent) {
                                    case 'liderazgo':
                                        componentColor = '#EE6666';
                                        break;
                                    case 'pedagógico':
                                        componentColor = '#66AA66';
                                        break;
                                    case 'energético':
                                        componentColor = '#0099EE';
                                        break;
                                    default:
                                        componentColor = '#666666';
                                        break;
                                }

                            }

                            let title = activity.activityType.activityTypeName + " - " + activity.activityCustomName;

                            return {
                                title: title,
                                start: activity.activityDateStart ? moment(activity.activityDateStart).format('YYYY-MM-DD HH:mm:ss') : null,
                                end: activity?.activityDateEnd ? moment(activity.activityDateEnd).format('YYYY-MM-DD HH:mm:ss') : null,
                                id: activity.id,
                                extraData: activity,
                                color: componentColor
                            }
                        }
                    ),
                ]

                setEvents(
                    newEvents || []
                )
                setLoadedData(true);
            }


        } catch (error) {

        }
    }

    const SetDateRange = (startDate, endDate) => {
        setFirstSelectedDay(startDate);
        setLastSelectedDay(endDate);
    }

    useEffect(() => {

    }, [events]
    );

    useEffect(() => {
        setCurrentAdviser('');
        setCurrentInstitution('');
        handleSelectedProfile()
    }, []);

    useEffect(() => {

        handleLoadExtraInfo()
        setActivityComponentList([
            {
                value: '',
                text: '',
                defaultSelected: '' == activityFormData.activityComponent
            },
            {
                value: 'liderazgo',
                text: 'Liderazgo',
                defaultSelected: 'liderazgo' == activityFormData.activityComponent
            },
            {
                value: 'pedagógico',
                text: 'Pedagógico',
                defaultSelected: 'pedagógico' == activityFormData.activityComponent
            },
            {
                value: 'energético',
                text: 'Energético',
                defaultSelected: 'energético' == activityFormData.activityComponent
            },
        ]);

    }, [selectedProfile]);

    useEffect(() => {
        if (!selectedProfile) return;
    }, [selectedProfile]);

    return (
        <>
            <SiteHeader
                selectedProfile={selectedProfile}
            />
            <MDBRow className='my-4 ms-4'>
                <Breadcrumbs
                    items={breadCrumbsData}
                />
            </MDBRow>
            <MDBContainer fluid>
                <MDBRow className='my-4 mx-4'>
                    <h4>Agenda</h4>
                </MDBRow>
                {
                    !loadedData &&
                    <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                        <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>Cargando...</span>
                        </div>
                    </MDBRow>
                }
                {
                    loadedData &&
                    <>
                        <MDBRow className='my-4 mx-4 d-flex justify-content-between'>
                            {
                                selectedProfile.roleName === 'Administrador' && <>
                                    <div className='col-md-4'>
                                        <MDBSelect
                                            id='adviser_id'
                                            name='adviser_id'
                                            className='mb-4'
                                            data={
                                                adviserList.map(
                                                    (adviser) => {
                                                        return {
                                                            text: adviser.label,
                                                            value: adviser.id,
                                                            defaultSelected: adviser.id === currentAdviser
                                                        }
                                                    }
                                                )
                                            }
                                            onValueChange={
                                                (e) => {
                                                    setLoadedData(false);
                                                    setCurrentAdviser(e.value);
                                                    handleLoadExtraInfo(
                                                        {
                                                            adviserFilter: e.value,
                                                            institutionFilter: ''
                                                        }
                                                    );
                                                }
                                            }
                                            search
                                            searchLabel='Buscar'
                                            label='Filtrar por Asesor'
                                        />
                                    </div>
                                    <div className='col-md-4'>
                                        <MDBSelect
                                            id='institution_id'
                                            name='institution_id'
                                            className='mb-4'
                                            data={
                                                institutionList.map(
                                                    (institution) => {
                                                        return {
                                                            text: institution.label,
                                                            value: institution.id,
                                                            defaultSelected: institution.id === currentInstitution
                                                        }
                                                    }
                                                )
                                            }
                                            onValueChange={
                                                (e) => {
                                                    setLoadedData(false);
                                                    setCurrentInstitution(e.value);
                                                    handleLoadExtraInfo(
                                                        {
                                                            adviserFilter: currentAdviser,
                                                            institutionFilter: e.value
                                                        }
                                                    );
                                                }
                                            }
                                            search
                                            searchLabel='Buscar'
                                            label='Filtrar por Establecimiento'
                                        />
                                    </div>
                                </>
                            }
                            {
                                selectedProfile.roleName === 'Asesor' && <>
                                    <div className='col-md-4'>
                                        <MDBSelect
                                            id='institution_id'
                                            name='institution_id'
                                            className='mb-4'
                                            data={
                                                institutionList.map(
                                                    (institution) => {
                                                        return {
                                                            text: institution.label,
                                                            value: institution.id,
                                                            defaultSelected: institution.id === currentInstitution
                                                        }
                                                    }
                                                )
                                            }
                                            onValueChange={
                                                (e) => {
                                                    setLoadedData(false);
                                                    setCurrentInstitution(e.value);
                                                    handleLoadExtraInfo(
                                                        {
                                                            institutionFilter: e.value
                                                        }
                                                    );
                                                }
                                            }
                                            search
                                            searchLabel='Buscar'
                                            label='Filtrar por Establecimiento'
                                        />
                                    </div>
                                    <div className='col-md-4'></div>
                                </>
                            }
                            <div className='col-md-4 text-end'>
                                <MDBBtn
                                    color='primary'
                                    onClick={
                                        () => {
                                            setActivityFormData({
                                                version_id: activeVersion,
                                                activityCustomName: '',
                                                activityDateStart: '',
                                                activityDateEnd: '',
                                                activityComponent: '',
                                                institution_id: institutions[0].id,
                                                activityType_id: activityTypes[0].id,
                                                activityStatus: 'creada'
                                            });
                                            setShowAddModal(true)
                                        }
                                    }
                                >
                                    Agregar actividad
                                </MDBBtn>
                            </div>
                        </MDBRow>
                        <MDBRow className='my-4 mx-4'>
                            {
                                events &&
                                <>
                                    <div className='col-md-12'>
                                        <FullCalendar
                                            height={650}
                                            plugins={
                                                [
                                                    dayGridPlugin,
                                                    timeGridPlugin
                                                ]
                                            }
                                            initialView={calendarView}
                                            //if calendarView is changed, update state
                                            datesSet={
                                                (dateInfo) => {
                                                    setCalendarView(dateInfo.view.type);
                                                    let startDate = momenttz(dateInfo.start).format('YYYY-MM-DD HH:mm:ss');
                                                    let endDate = momenttz(dateInfo.end).format('YYYY-MM-DD HH:mm:ss');
                                                    SetDateRange(startDate, endDate);
                                                    handleLoadExtraInfo(
                                                        {
                                                            startDate: startDate,
                                                            endDate: endDate
                                                        }
                                                    );
                                                }
                                            }
                                            headerToolbar={
                                                {
                                                    left: 'prev,next',
                                                    center: 'title',
                                                    right: 'timeGridDay,timeGridWeek,dayGridMonth'
                                                }
                                            }
                                            events={
                                                events
                                            }
                                            eventClick={handleEventOnClick}
                                            weekends={false}
                                            locale='es'
                                            allDayText='Todo el día'
                                            buttonText={
                                                {
                                                    today: 'Hoy',
                                                    month: 'Mes',
                                                    week: 'Semana',
                                                    day: 'Día',
                                                    list: 'Lista'
                                                }
                                            }
                                            eventContent={
                                                (info, view) => {

                                                    var componentIcon;
                                                    switch (info.event.extendedProps.extraData.activityStatus) {
                                                        case 'creada':
                                                            componentIcon = faStarRegular;
                                                            break;
                                                        case 'en proceso':
                                                            componentIcon = faStarHalfAlt;
                                                            break;
                                                        case 'finalizada':
                                                            componentIcon = faStar;
                                                            break;
                                                        default:
                                                            break;
                                                    }

                                                    return <div className="fc-event-main-frame">
                                                        <div className='d-flex align-items-center'>
                                                            {
                                                                info.event.extendedProps.extraData.activityStatus === 'creada' && <>
                                                                    <div className="fc-daygrid-event-dot"
                                                                        style={{
                                                                            borderColor: info.event._def.ui.borderColor,
                                                                            borderWidth: '1px',
                                                                            float: 'left',
                                                                            verticalAlign: 'middle',
                                                                            width: '1rem',
                                                                            height: '1rem',
                                                                            borderRadius: '1rem'
                                                                        }}
                                                                    ></div>
                                                                </>
                                                            }
                                                            {
                                                                info.event.extendedProps.extraData.activityStatus === 'en proceso' && <>
                                                                    <div className="fc-daygrid-event-dot"
                                                                        style={{
                                                                            borderColor: info.event._def.ui.borderColor,
                                                                            borderWidth: '1px',
                                                                            float: 'left',
                                                                            verticalAlign: 'middle',
                                                                            width: '0.5rem',
                                                                            height: '1rem',
                                                                            borderRadius: '5rem 0 0 5rem',
                                                                            backgroundColor: info.event._def.ui.backgroundColor,
                                                                            marginRight: '-1px'
                                                                        }}
                                                                    ></div>
                                                                    <div className="fc-daygrid-event-dot"
                                                                        style={{
                                                                            borderColor: info.event._def.ui.borderColor,
                                                                            borderWidth: '1px',
                                                                            float: 'left',
                                                                            verticalAlign: 'middle',
                                                                            width: '0.5rem',
                                                                            height: '1rem',
                                                                            borderRadius: '0 10rem 10rem 0',
                                                                            marginLeft: '-1px'
                                                                        }}
                                                                    ></div>
                                                                </>
                                                            }
                                                            {
                                                                info.event.extendedProps.extraData.activityStatus === 'finalizada' && <>
                                                                    <div className="fc-daygrid-event-dot"
                                                                        style={{
                                                                            borderColor: info.event._def.ui.borderColor,
                                                                            borderWidth: '1px',
                                                                            float: 'left',
                                                                            verticalAlign: 'middle',
                                                                            width: '1rem',
                                                                            height: '1rem',
                                                                            borderRadius: '1rem',
                                                                            backgroundColor: info.event._def.ui.backgroundColor
                                                                        }}
                                                                    ></div>
                                                                </>
                                                            }
                                                            <div className="fc-event-time ms-2">
                                                                {info.timeText} / {info.event.extendedProps.extraData?.institution?.institutionName}
                                                            </div>
                                                        </div>
                                                        <div className="fc-event-title-container ms-4">
                                                            <div className="fc-event-title ms-3">
                                                                {/*}
                                                                <FontAwesomeIcon
                                                                    icon={componentIcon}
                                                                    className='text-warning me-2'
                                                                    fontSize={16}
                                                                    
                                                                /> { */}{info.event.title}

                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            }
                                            loading={
                                                () => {
                                                    return <div className='d-flex justify-content-center align-items-center h-100'>
                                                        <div className='spinner-border text-primary' role='status'>
                                                            <span className='visually-hidden'>Cargando...</span>
                                                        </div>
                                                    </div>
                                                }
                                            }

                                        />
                                    </div>
                                </>
                            }
                        </MDBRow>
                        <MDBModal
                            show={showAddModal}
                            setShow={setShowAddModal}
                            tabIndex='-1'
                        >
                            <MDBModalDialog>
                                <MDBModalContent>
                                    <MDBModalHeader>
                                        <MDBModalTitle>Agregar actividad</MDBModalTitle>
                                        <MDBBtn className='btn-close' color='none' onClick={
                                            () => setShowAddModal(false)
                                        }></MDBBtn>
                                    </MDBModalHeader>
                                    <MDBModalBody>
                                        <MDBRow className='my-4'>
                                            <MDBCol md='12'>
                                                {
                                                    <>
                                                        <label>Componente</label>
                                                        <MDBSelect
                                                            id='activityComponent'
                                                            name='activityComponent'
                                                            className='mb-4'
                                                            data={
                                                                activityComponentList.map(
                                                                    (activityComponent) => {
                                                                        return {
                                                                            text: activityComponent.text,
                                                                            value: activityComponent.value,
                                                                            defaultSelected: activityComponent.value === activityFormData.activityComponent
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                            onValueChange={
                                                                (e) => {
                                                                    handleFieldChange({
                                                                        target: {
                                                                            name: 'activityComponent',
                                                                            value: e.value
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </>
                                                }
                                                {
                                                    activityFormData.activityComponent === '' ? <>
                                                        <MDBInput
                                                            label='Nombre de la Actividad'
                                                            id='activityCustomName'
                                                            name='activityCustomName'
                                                            type='text'
                                                            onChange={handleFieldChange}
                                                            className='mb-4'
                                                            value={activityFormData.activityCustomName || ''}
                                                        />
                                                    </>
                                                        :
                                                        <>
                                                            <label>Nombre de la Actividad</label>
                                                            <MDBSelect
                                                                id='activityCustomName'
                                                                name='activityCustomName'
                                                                className='mb-4'
                                                                data={
                                                                    activityNameList.filter(
                                                                        (activityName) => activityName.component === activityFormData.activityComponent
                                                                    ).map(
                                                                        (activityName) => {
                                                                            return {
                                                                                text: activityName.name,
                                                                                value: activityName.name,
                                                                                defaultSelected: activityName.name === activityFormData.activityCustomName
                                                                            }
                                                                        }
                                                                    )
                                                                }
                                                                onValueChange={
                                                                    (e) => {
                                                                        handleFieldChange({
                                                                            target: {
                                                                                name: 'activityCustomName',
                                                                                value: e.value
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            />
                                                        </>
                                                }
                                                <label>Fecha de inicio</label>
                                                <MDBInput
                                                    id='activityDateStart'
                                                    name='activityDateStart'
                                                    type='datetime-local'
                                                    onChange={handleFieldChange}
                                                    className='mb-4'
                                                    value={activityFormData.activityDateStart || ''}
                                                />
                                                <label>Fecha de término</label>
                                                <MDBInput
                                                    id='activityDateEnd'
                                                    name='activityDateEnd'
                                                    type='datetime-local'
                                                    onChange={handleFieldChange}
                                                    className='mb-4'
                                                    value={activityFormData.activityDateEnd || ''}
                                                />
                                                <label>Tipo de actividad</label>
                                                <MDBSelect
                                                    id='activityType_id'
                                                    name='activityType_id'
                                                    className='mb-4'
                                                    data={
                                                        activityTypes.map(
                                                            (activityType) => {
                                                                return {
                                                                    text: activityType.activityTypeName,
                                                                    value: activityType.id,
                                                                    defaultSelected: activityType.id === activityFormData.activityType_id
                                                                }
                                                            }
                                                        )
                                                    }
                                                    onValueChange={
                                                        (e) => {
                                                            handleFieldChange({
                                                                target: {
                                                                    name: 'activityType_id',
                                                                    value: e.value
                                                                }
                                                            })
                                                        }
                                                    }
                                                />

                                                <label>Establecimiento</label>
                                                <MDBSelect
                                                    id='institution_id'
                                                    name='institution_id'
                                                    className='mb-4'
                                                    data={
                                                        institutions.map(
                                                            (institution) => {
                                                                return {
                                                                    text: institution.institutionName + ' - ' + institution.institutionRBD,
                                                                    value: institution.id,
                                                                    defaultSelected: institution.id === activityFormData.institution_id
                                                                }
                                                            }
                                                        )
                                                    }
                                                    onValueChange={
                                                        (e) => {
                                                            handleFieldChange({
                                                                target: {
                                                                    name: 'institution_id',
                                                                    value: e.value
                                                                }
                                                            })
                                                        }
                                                    }
                                                    search
                                                    searchLabel='Buscar'
                                                />

                                            </MDBCol>
                                        </MDBRow>
                                    </MDBModalBody>

                                    <MDBModalFooter>
                                        <MDBBtn color='secondary' onClick={
                                            () => setShowAddModal(false)
                                        }>
                                            Cerrar
                                        </MDBBtn>
                                        <MDBBtn
                                            onClick={
                                                () => {
                                                    handleAddActivity()
                                                }
                                            }
                                        >
                                            Guardar
                                        </MDBBtn>
                                    </MDBModalFooter>
                                </MDBModalContent>
                            </MDBModalDialog>
                        </MDBModal>
                        <MDBModal
                            show={showEditModal}
                            setShow={setShowEditModal}
                            tabIndex='-1'
                        >
                            <MDBModalDialog>
                                <MDBModalContent>
                                    <MDBModalHeader>
                                        <MDBModalTitle>Editar actividad</MDBModalTitle>
                                        <MDBBtn className='btn-close' color='none' onClick={
                                            () => setShowEditModal(false)
                                        }></MDBBtn>
                                    </MDBModalHeader>
                                    <MDBModalBody>
                                        <MDBRow className='my-4'>
                                            <MDBCol md='12' className='text-end'>
                                                {
                                                    activityFormData.activityStatus !== 'finalizada' ?
                                                        <>
                                                            <MDBBtn
                                                                color='info'
                                                                onClick={
                                                                    () => {
                                                                        navigateTo('/do/generic-activity/' + activityFormData.id)
                                                                    }
                                                                }
                                                            >
                                                                Ejecutar
                                                            </MDBBtn>
                                                        </>
                                                        :
                                                        <>
                                                            <MDBBtn
                                                                color='info'
                                                                outline
                                                                onClick={
                                                                    () => {
                                                                        navigateTo('/check/generic-activity/' + activityFormData.id)
                                                                    }
                                                                }
                                                            >
                                                                Revisar
                                                            </MDBBtn>
                                                        </>
                                                }

                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className='my-4'>
                                            <MDBCol md='12'>
                                                {
                                                    <>
                                                        <label>Componente</label>
                                                        <MDBSelect
                                                            id='activityComponent'
                                                            name='activityComponent'
                                                            className='mb-4'
                                                            data={
                                                                activityComponentList.map(
                                                                    (activityComponent) => {
                                                                        return {
                                                                            text: activityComponent.text,
                                                                            value: activityComponent.value,
                                                                            defaultSelected: activityComponent.value === activityFormData.activityComponent
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                            onValueChange={
                                                                (e) => {
                                                                    handleFieldChange({
                                                                        target: {
                                                                            name: 'activityComponent',
                                                                            value: e.value
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </>
                                                }
                                                {
                                                    activityFormData.activityComponent === '' ? <>
                                                        <MDBInput
                                                            label='Nombre de la Actividad'
                                                            id='activityCustomName'
                                                            name='activityCustomName'
                                                            type='text'
                                                            onChange={handleFieldChange}
                                                            className='mb-4'
                                                            value={activityFormData.activityCustomName || ''}
                                                        />
                                                    </>
                                                        :
                                                        <>
                                                            <label>Nombre de la Actividad</label>
                                                            <MDBSelect
                                                                id='activityCustomName'
                                                                name='activityCustomName'
                                                                className='mb-4'
                                                                data={
                                                                    activityNameList.filter(
                                                                        (activityName) => activityName.component === activityFormData.activityComponent
                                                                    ).map(
                                                                        (activityName) => {
                                                                            return {
                                                                                text: activityName.name,
                                                                                value: activityName.name,
                                                                                defaultSelected: activityName.name === activityFormData.activityCustomName
                                                                            }
                                                                        }
                                                                    )
                                                                }
                                                                onValueChange={
                                                                    (e) => {
                                                                        handleFieldChange({
                                                                            target: {
                                                                                name: 'activityCustomName',
                                                                                value: e.value
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            />
                                                        </>
                                                }
                                                <label>Fecha de inicio</label>
                                                <MDBInput
                                                    id='activityDateStart'
                                                    name='activityDateStart'
                                                    type='datetime-local'
                                                    onChange={handleFieldChange}
                                                    className='mb-4'
                                                    value={activityFormData.activityDateStart || ''}
                                                />
                                                <label>Fecha de término</label>
                                                <MDBInput
                                                    id='activityDateEnd'
                                                    name='activityDateEnd'
                                                    type='datetime-local'
                                                    onChange={handleFieldChange}
                                                    className='mb-4'
                                                    value={activityFormData.activityDateEnd || ''}
                                                />
                                                <label>Tipo de actividad</label>
                                                <MDBSelect
                                                    id='activityType_id'
                                                    name='activityType_id'
                                                    className='mb-4'
                                                    data={
                                                        activityTypes.map(
                                                            (activityType) => {
                                                                return {
                                                                    text: activityType.activityTypeName,
                                                                    value: activityType.id,
                                                                    defaultSelected: activityType.id === activityFormData.activityType_id
                                                                }
                                                            }
                                                        )
                                                    }
                                                    onValueChange={
                                                        (e) => {
                                                            handleFieldChange({
                                                                target: {
                                                                    name: 'activityType_id',
                                                                    value: e.value
                                                                }
                                                            })
                                                        }
                                                    }
                                                />

                                                <label>Establecimiento</label>
                                                <MDBSelect
                                                    id='institution_id'
                                                    name='institution_id'
                                                    className='mb-4'
                                                    data={
                                                        institutions.map(
                                                            (institution) => {
                                                                return {
                                                                    text: institution.institutionName + ' - ' + institution.institutionRBD,
                                                                    value: institution.id,
                                                                    defaultSelected: institution.id === activityFormData.institution_id
                                                                }
                                                            }
                                                        )
                                                    }
                                                    onValueChange={
                                                        (e) => {
                                                            handleFieldChange({
                                                                target: {
                                                                    name: 'institution_id',
                                                                    value: e.value
                                                                }
                                                            })
                                                        }
                                                    }
                                                    search
                                                    searchLabel='Buscar'
                                                />

                                            </MDBCol>
                                        </MDBRow>
                                    </MDBModalBody>

                                    <MDBModalFooter>
                                        <MDBBtn color='secondary' onClick={
                                            () => setShowEditModal(false)
                                        }>
                                            Cerrar
                                        </MDBBtn>
                                        <MDBBtn
                                            onClick={
                                                () => {
                                                    handleEditActivity()
                                                }
                                            }
                                        >
                                            Guardar cambios
                                        </MDBBtn>
                                    </MDBModalFooter>
                                </MDBModalContent>
                            </MDBModalDialog>
                        </MDBModal>
                        <MDBModal tabIndex='-1'
                            show={modalActivityWarningShow}
                            setShow={setModalActivityWarningShow}>
                            <MDBModalDialog centered>
                                <MDBModalContent>
                                    <MDBModalHeader>
                                        <MDBModalTitle>Advertencia</MDBModalTitle>
                                        <MDBBtn className='btn-close' color='none' onClick={
                                            () => toggleActivityWarningModal(false)
                                        }></MDBBtn>
                                    </MDBModalHeader>
                                    <MDBModalBody>
                                        {
                                            modalActivityWarningMessage.map(
                                                (message, index) => {
                                                    return (
                                                        <p className='mt-0' key={index}>{message}</p>
                                                    )
                                                }
                                            )
                                        }
                                    </MDBModalBody>
                                    <MDBModalFooter>
                                        <MDBBtn color='secondary' outline onClick={
                                            () => toggleActivityWarningModal(false)
                                        }>
                                            Continuar
                                        </MDBBtn>
                                    </MDBModalFooter>
                                </MDBModalContent>
                            </MDBModalDialog>
                        </MDBModal>
                    </>
                }
            </MDBContainer>
        </>
    )
}
