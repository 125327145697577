import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SiteHeader from '../components/SiteHeader'
import Breadcrumbs from '../components/Breadcrumbs'
import {
    MDBAnimation,
    MDBContainer,
    MDBRow,
    MDBDatatable,
    MDBBtn,
    MDBIcon,
    MDBCol,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBSelect,
} from 'mdb-react-ui-kit'
import encryption from '../services/encryption'
import activityService from '../services/activities'
import modulePermissions from '../utilities/modulePermissions'
import auth from '../services/authentication'
import activityTypeService from '../services/activityTypes'
import institutionService from '../services/institutions'

export default function Activities() {

    const moduleName = 'activity';
    const navigateTo = useNavigate();
    const { version_id } = useParams();
    const [selectedProfile, setSelectedProfile] = useState(null);

    const [activityComponentList, setActivityComponentList] = useState([]);
    const [activityStatusList, setActivityStatusList] = useState([]);
    const [activityTypeList, setActivityTypeList] = useState([]);
    const [activityTypeCategoryIsComponent, setActivityTypeCategoryIsComponent] = useState(false);
    const [institutionList, setInstitutionList] = useState([]);

    const [modalAddShow, setModalAddShow] = useState(false);
    const [modalEditShow, setModalEditShow] = useState(false);
    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    const [entryId, setEntryId] = useState(null);
    const [state, setState] = useState({
        version_id: version_id,
        activityType_id: null,
        institution_id: null,
        activityTotalScore: '',
        activityStatus: 'creada',
        activityComponent: '',
        activityCustomName: '',
        activityDateStart: '',
        activityDateEnd: '',
    });

    const [modules, setModules] = useState({});

    const breadCrumbsData = [
        {
            text: 'Inicio',
            href: `/home`
        },
        {
            text: 'Versiones',
            href: `/version/${version_id}`
        },
        {
            text: 'Actividad',
        }
    ];

    const handleSelectedProfile = () => {
        let token = localStorage.getItem('selectedProfile');
        if (token) {
            let descryptedToken = encryption.decrypt(token);
            setSelectedProfile(descryptedToken);
        }
    }

    const toggleAddModal = async () => {
        if (modalAddShow === false) {
            const activityTypeData = await activityTypeService.listByVersion(version_id);
            const institutionData = await institutionService.list();
            setState({
                version_id: version_id,
                activityType_id: activityTypeData[0].id,
                institution_id: institutionData[0].id,
                activityTotalScore: '',
                activityStatus: 'creada',
                activityComponent: '',
                activityCustomName: '',
                activityDateStart: '',
                activityDateEnd: '',
            });
        }
        setModalAddShow(!modalAddShow);
    }

    const toggleEditModal = (newState) => {
        setModalEditShow(newState);
    }

    const toggleDeleteModal = (newState) => {
        setModalDeleteShow(newState);
    }

    const btnActionEdit = (item, id) => {
        let newState = { ...item };
        delete newState.id;
        setEntryId(id);
        setState(newState);
        toggleEditModal(true);
    }

    const btnActionDelete = (item, id) => {
        let newState = { ...item };
        delete newState.id;
        setEntryId(id);
        setState(newState);
        toggleDeleteModal(true);
    }

    const [asyncData, setAsyncData] = useState({
        columns: [
            {
                label: 'ID',
                field: 'id',
            },
            {
                label: 'Tipo',
                field: 'activityType',
            },
            {
                label: 'Institución',
                field: 'institution',
            },
            {
                label: 'Puntaje',
                field: 'activityTotalScore',
            },
            {
                label: 'Estado',
                field: 'activityStatus',
            },
            {
                label: 'Componente',
                field: 'activityComponent',
            },
            {
                label: 'Nombre Personalizado',
                field: 'activityCustomName',    
            },
            {
                label: 'Fecha Inicio',
                field: 'activityDateStart',
            },
            {
                label: 'Fecha Fin',
                field: 'activityDateEnd',
            },
            {
                label: 'Acciones',
                field: 'actions',
            },
        ],
        rows: []
    });

    const [dataLoading, setDataLoading] = useState(false);

    const handleDataLoad = async () => {

        const activities = await activityService.listByVersion(version_id)
        setAsyncData({
            columns: asyncData.columns,
            rows: activities.map((item, index) => {
                return {
                    id: index + 1,
                    activityType: item.activityType.activityTypeName,
                    institution: item.institution.institutionName,
                    activityTotalScore: item.activityTotalScore > 0 ? item.activityTotalScore : '-',
                    activityStatus: item.activityStatus,
                    activityComponent: item.activityComponent == '' ? '-' : item.activityComponent,
                    activityCustomName: item.activityCustomName == '' ? '-' : item.activityCustomName,
                    activityDateStart: item.activityDateStart,
                    activityDateEnd: item.activityDateEnd,
                    actions: <>
                        {
                            selectedProfile && modules?.activities?.includes('update') && (
                                <MDBBtn color='info' size='lg' className='me-2' floating onClick={() => btnActionEdit(item, item.id)}>
                                    <MDBIcon icon='edit' />
                                </MDBBtn>
                            )
                        }
                        {
                            selectedProfile && modules?.activities?.includes('delete') && (
                                <MDBBtn color='danger' size='lg' className='me-2' floating onClick={() => btnActionDelete(item, item.id)}>
                                    <MDBIcon icon='trash' />
                                </MDBBtn>
                            )
                        }
                        {
                            selectedProfile && modules?.activities?.includes('internal') && (
                                <MDBBtn
                                    color='secondary'
                                    size='lg'
                                    floating
                                    type='button'
                                    onClick={() => {
                                        navigateTo(`/check/self-evaluation/${item.id}`,
                                            {
                                                state: {
                                                    origin: `/activities`,
                                                    version: version_id,
                                                }
                                            }
                                        );
                                    }}
                                >
                                    <MDBIcon icon='eye' />
                                </MDBBtn>
                            )
                        }
                    </>
                }
            })

        })
    }

    const handleCreate = async () => {
        console.log(state);
        await activityService.create(state);
        handleDataLoad();
        toggleAddModal();
        setState({
            version_id: version_id,
            activityType_id: null,
            institution_id: null,
            activityStatus: 'creada',
            activityComponent: '',
            activityCustomName: '',
            activityDateStart: '',
            activityDateEnd: '',
        });
    }

    const handleUpdate = async () => {
        await activityService.update(entryId, {
            ...state
        });
        handleDataLoad();
        toggleEditModal(false);
    }

    const handleDelete = async () => {
        await activityService.remove(entryId);
        handleDataLoad();
        setState({
            version_id: version_id,
            activityType_id: null,
            institution_id: null,
            activityStatus: 'creada',
            activityComponent: '',
            activityCustomName: '',
            activityDateStart: '',
            activityDateEnd: '',
        });
        toggleDeleteModal(false);
    }

    useEffect(() => {
        handleSelectedProfile();
    }, []);

    useEffect(() => {
        handleDataLoad();
    }, [dataLoading, asyncData.columns, modules, selectedProfile]);

    useEffect(() => {
        if (asyncData.rows.length === 0) {
            setDataLoading(true);
        } else {
            setDataLoading(false);
        }
    }, [asyncData.rows]);

    useEffect(() => {
        setActivityComponentList([
            {
                value: '',
                text: '',
                defaultSelected: '' == state.activityComponent
            },
            {
                value: 'liderazgo',
                text: 'Liderazgo',
                defaultSelected: 'liderazgo' == state.activityComponent
            },
            {
                value: 'pedagógico',
                text: 'Pedagógico',
                defaultSelected: 'pedagógico' == state.activityComponent
            },
            {
                value: 'energético',
                text: 'Energético',
                defaultSelected: 'energético' == state.activityComponent
            },
        ]);
    }, [state.activityComponent]);

    useEffect(() => {
        setActivityStatusList([
            {
                value: 'creada',
                text: 'Creada',
                defaultSelected: 'creada' == state.activityStatus
            },
            {
                value: 'en proceso',
                text: 'En proceso',
                defaultSelected: 'en proceso' == state.activityStatus
            },
            {
                value: 'finalizada',
                text: 'Finalizada',
                defaultSelected: 'finalizada' == state.activityStatus
            },
            {
                value: 'cancelada',
                text: 'Cancelada',
                defaultSelected: 'cancelada' == state.activityStatus
            }
        ]);
    }, [state.activityStatus]);

    const handleActivityTypeListLoad = async () => {
        const data = await activityTypeService.listByVersion(version_id);
        setActivityTypeList(
            data.map((item) => {
                if (item.activityTypeCategory == 'component') {
                    setActivityTypeCategoryIsComponent(true);
                } else {
                    setActivityTypeCategoryIsComponent(false);
                }
                return {
                    value: item.id,
                    text: item.activityTypeName,
                    defaultSelected: item.id == state.activityType_id
                }
            })
        )
    }

    useEffect(() => {
        if (version_id) {
            handleActivityTypeListLoad();
        }
    }, [state.activityType_id]);

    const handleInstitutionListLoad = async () => { 
        const data = await institutionService.list();
        setInstitutionList(
            data.map((item) => {
                return {
                    value: item.id,
                    text: item.institutionName,
                    defaultSelected: item.id == state.institution_id
                }
            })
        )
    }

    useEffect(() => {
        if (version_id) {
            handleInstitutionListLoad();
        }
    }, [state.institution_id]);

    const handlePageModules = async () => {
        let modulesData = {};
        modulesData = {
            activities: []
        }
        let available = false;

        available = await modulePermissions(selectedProfile.roleName, 'activities', 'create');
        if (available) modulesData.activities.push('create');
        available = await modulePermissions(selectedProfile.roleName, 'activities', 'update');
        if (available) modulesData.activities.push('update');
        available = await modulePermissions(selectedProfile.roleName, 'activities', 'delete');
        if (available) modulesData.activities.push('delete');
        available = await modulePermissions(selectedProfile.roleName, 'activities', 'internal');
        if (available) modulesData.activities.push('internal');

        setModules(modulesData);
    }

    useEffect(() => {
    }, [modules]);

    useEffect(() => {
        if (selectedProfile) {
            handlePageModules();
        }
    }, [selectedProfile]);

    useEffect(() => {
        console.log(state)
    }, [state]);

    return (
        <>
            <SiteHeader
                selectedProfile={selectedProfile}
            />
            <MDBAnimation
                animation='fade-in'
                start='onLoad'
                duration={700}
            >
                <MDBRow className='my-4 ms-4'>
                    <Breadcrumbs
                        items={breadCrumbsData}
                    />
                </MDBRow>
                <MDBContainer>
                    <MDBRow className='my-4 ms-4'>
                        <h4>Actividades</h4>
                    </MDBRow>
                    {
                        selectedProfile && modules?.activities?.includes('create') && (
                            <MDBRow className='text-end'>
                                <MDBCol className='me-0 pe-0'>
                                    <MDBBtn color='primary' className='my-3' size='lg' floating onClick={() => {
                                        toggleAddModal();
                                    }}>
                                        <MDBIcon icon='plus' />
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        )
                    }
                    <MDBRow>
                        <MDBDatatable
                            data={asyncData}
                            rowsText='Filas'
                            ofText='de'
                            searchLabel='Buscar'
                            search
                            noFoundMessage='No se encontraron resultados'
                            isLoading={dataLoading}
                            searchInputProps={
                                {
                                    'className': 'pb-1',
                                }
                            }
                        />
                    </MDBRow>
                </MDBContainer>
            </MDBAnimation>

            <MDBModal tabIndex='-1' show={modalAddShow} setShow={setModalAddShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Agregar actividad</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleAddModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <input type='hidden' value={state.version_id} />
                            <MDBSelect
                                label='Institución'
                                data={institutionList}
                                onValueChange={(e) => {
                                    setState({
                                        ...state,
                                        institution_id: e.value
                                    });
                                }
                                }
                                className='my-3'
                            />
                            <MDBSelect
                                label='Tipo de actividad'
                                data={activityTypeList}
                                onValueChange={(e) => {
                                    setState({
                                        ...state,
                                        activityType_id: e.value
                                    });
                                }
                                }
                                className='my-3'
                            />
                            {
                                activityTypeCategoryIsComponent && (
                                    <MDBSelect
                                        label='Componente'
                                        data={activityComponentList}
                                        onValueChange={(e) => {
                                            setState({
                                                ...state,
                                                activityComponent: e.value
                                            });
                                        }
                                        }
                                        className='my-3'
                                    />
                                )
                            }
                            <MDBSelect
                                label='Estado'
                                data={activityStatusList}
                                onValueChange={(e) => {
                                    setState({
                                        ...state,
                                        activityStatus: e.value
                                    });
                                }
                                }
                                className='my-3'
                            />
                            <div id='helperDateStartC' className='form-text'>
                                Fecha de inicio de la actividad
                            </div>
                            <MDBInput
                                type='datetime-local'
                                value={state.activityDateStart}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        activityDateStart: e.target.value
                                    });
                                }}
                                aria-describedby='helperDateStartC'
                                className='my-3'
                            />
                            
                            <div id='helperDateEndC' className='form-text'>
                                Fecha de fin de la actividad
                            </div>
                            <MDBInput
                                type='datetime-local'
                                value={state.activityDateEnd}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        activityDateEnd: e.target.value
                                    });
                                }}
                                aria-describedby='helperDateEndC'
                                className='my-3'
                            />
                            <MDBInput
                                type='text'
                                label='Nombre de la actividad'
                                value={state.activityCustomName}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        activityCustomName: e.target.value
                                    });
                                }}
                                className='my-3'
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={toggleAddModal}>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                color='primary'
                                onClick={
                                    () => {
                                        handleCreate()
                                    }
                                }

                            >Insertar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1' show={modalEditShow} setShow={setModalEditShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Editar versión</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleEditModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <input type='hidden' value={state.version_id} />
                            <MDBSelect
                                label='Institución'
                                data={institutionList}
                                onValueChange={(e) => {
                                    setState({
                                        ...state,
                                        institution_id: e.value
                                    });
                                }
                                }
                                className='my-3'
                            />
                            <MDBSelect
                                label='Tipo de actividad'
                                data={activityTypeList}
                                onValueChange={(e) => {
                                    setState({
                                        ...state,
                                        activityType_id: e.value
                                    });
                                }
                                }
                                className='my-3'
                            />
                            {
                                activityTypeCategoryIsComponent && (
                                    <MDBSelect
                                        label='Componente'
                                        data={activityComponentList}
                                        onValueChange={(e) => {
                                            setState({
                                                ...state,
                                                activityComponent: e.value
                                            });
                                        }
                                        }
                                        className='my-3'
                                    />
                                )
                            }
                            <MDBSelect
                                label='Estado'
                                data={activityStatusList}
                                onValueChange={(e) => {
                                    setState({
                                        ...state,
                                        activityStatus: e.value
                                    });
                                }
                                }
                                className='my-3'
                            />
                            <div id='helperDateStartE' className='form-text'>
                                Fecha de inicio de la actividad
                            </div>
                            <MDBInput
                                type='datetime-local'
                                value={state.activityDateStart}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        activityDateStart: e.target.value
                                    });
                                }}
                                aria-describedby='helperDateStartE'
                                className='my-3'
                            />
                            <div id='helperDateEndC' className='form-text'>
                                Fecha de fin de la actividad
                            </div>
                            <MDBInput
                                type='datetime-local'
                                value={state.activityDateEnd}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        activityDateEnd: e.target.value
                                    });
                                }}
                                aria-describedby='helperDateEndE'
                                className='my-3'
                            />
                            <MDBInput
                                type='text'
                                label='Nombre de la actividad'
                                value={state.activityCustomName}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        activityCustomName: e.target.value
                                    });
                                }}
                                className='my-3'
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleEditModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    handleUpdate();
                                }}
                            >Actualizar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1' show={modalDeleteShow} setShow={setModalDeleteShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Eliminar actividad</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleDeleteModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>¿Está seguro que desea eliminar la actividad?</p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleDeleteModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    handleDelete();
                                }}
                            >Eliminar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}
