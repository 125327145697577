import React, { useEffect, useState } from 'react';
import {
    MDBNavbar,
    MDBContainer,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBCollapse,
    MDBIcon,
    MDBNavbarNav,
    MDBCol,
    MDBRow,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
} from 'mdb-react-ui-kit';
import auth from '../services/authentication';
import encryption from '../services/encryption';
import LOGO from '../assets/img/logo-color.png';

import { useNavigate } from 'react-router-dom';

export default function SiteHeader({
    selectedProfile,
    userCredentials
}) {
    const [showNavNoToggler, setShowNavNoToggler] = useState(false);
    const navigateTo = useNavigate();
    let profileRoleList = localStorage.getItem('profileRoleList');
    if (profileRoleList) {
        profileRoleList = encryption.decrypt(profileRoleList);
    }
    let credentials = localStorage.getItem('bearer-token');
    let decryptedCredentials = JSON.parse(atob(credentials));

    const handleLogout = () => {
        auth.sign_out();
        navigateTo('/');
    }

    const btnProfileSelector = () => {
        navigateTo('/profile-selector');
    }

    const btnProfile = () => {
        navigateTo(`/my-profile`);
    }

    const btnHome = () => {
        navigateTo(`/home`);
    }

    useEffect(() => {
        //console.log('SiteHeader: ', decryptedCredentials)
    }, []);

    return (
        <>
            <MDBNavbar expand='lg' light fixed bgColor='light' >
                <MDBContainer fluid>
                    <MDBNavbarToggler
                        type='button'
                        data-target='#navbarTogglerDemo01'
                        aria-controls='navbarTogglerDemo01'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => setShowNavNoToggler(!showNavNoToggler)}
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>
                    <MDBCollapse navbar show={showNavNoToggler} style={
                        {
                            height: 'auto',
                        }
                    }>
                        <MDBRow>
                            <MDBCol xs={'2'} className='text-end' style={
                                {
                                    paddingRight: '3rem',
                                }
                            }>
                                <MDBNavbarBrand
                                    style={
                                        {
                                            fontSize: '1.5rem',
                                            fontWeight: 'bold',
                                            marginTop: '0.5rem',
                                        }
                                    }
                                    className='text-end pe-0 me-0'
                                >
                                    <img
                                        src={LOGO}
                                        height='30'
                                        alt=''
                                        loading='lazy'
                                    />
                                </MDBNavbarBrand>
                            </MDBCol>
                        </MDBRow>
                        <MDBNavbarNav className='mb-2 mb-lg-0 d-flex justify-content-between'>
                            {
                                profileRoleList?.length > 1 && <>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink active aria-current='page' onClick={btnProfileSelector}>
                                            Seleccionar perfil
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                </>
                            }
                            <MDBNavbarItem>
                                <MDBNavbarLink active aria-current='page' onClick={btnHome}>
                                    Inicio &nbsp;
                                    {
                                        selectedProfile?.roleName === 'Administrador' && 'Administrador/a'
                                    }
                                    {
                                        selectedProfile?.roleName === 'Asesor' && 'Asesor/a'
                                    }
                                    {
                                        selectedProfile?.roleName === 'Coordinador' && 'Coordinador/a'
                                    }
                                    {
                                        ![
                                            'Administrador',
                                            'Asesor',
                                            'Coordinador'
                                        ].includes(selectedProfile?.roleName) && selectedProfile?.roleName
                                    }
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem className='ms-auto'>
                                <MDBDropdown>
                                    <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                                        Opciones
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu className='p-2 text-muted' style={{ maxWidth: '200px' }}>
                                        <MDBDropdownItem
                                            link
                                            onClick={btnProfile}
                                        >
                                            Mi Perfil
                                        </MDBDropdownItem>
                                        <MDBDropdownItem divider />
                                        <MDBDropdownItem
                                            link
                                            onClick={handleLogout}
                                        >
                                            Cerrar sesión
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </MDBNavbarItem>
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
        </>
    );
}