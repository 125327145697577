import encryption from '../services/encryption.jsx';

import APIDriver from './api/APIDriver';
const userApi = new APIDriver('users');
const userRoleApi = new APIDriver('user_roles');

const get_institutions = async (userId, roleId) => {
    userRoleApi.setBearerProtection();

    const roleData = await userRoleApi.get('user-profile', [userId, roleId]);

    let institutionList = [];

    for (var i = 0; i < roleData.length; i++) {
        let entry = {};
        entry.roleName = roleData[i].role.roleName;
        entry.roleId = roleData[i].role.id;
        if (roleData[i].institution != null) {
            entry.id = roleData[i].institution.id;
            entry.institutionName = roleData[i].institution.institutionName;
            entry.institutionId = roleData[i].institution.id;
            entry.institutionRBD = roleData[i].institution.institutionRBD;
            entry.institutionAddress1 = roleData[i].institution.institutionAddress1;
            entry.institutionAddress2 = roleData[i].institution.institutionAddress2;
            entry.institutionPhone = roleData[i].institution.institutionPhone;
            entry.city_id = roleData[i].institution.city_id;
            entry.state_id = roleData[i].institution.state_id;
            institutionList.push(entry);
        }
    }

    return institutionList;
}

const sign_in = async (userEmail, userPassword) => {

    userApi.unsetBearerProtection();
    const calling = await userApi.post('sign-in', {
        userEmail: userEmail,
        userPassword: userPassword
    });

    let decryptedData = atob(calling.token);
    decryptedData = JSON.parse(decryptedData);

    localStorage.setItem('bearer-token', calling.token);

    var basicData = {};
    basicData.id = decryptedData.id;
    basicData.userEmail = decryptedData.userEmail;
    basicData.userName = decryptedData.userName;
    basicData.roles = [];
    var profileRoleList = [];
    var uniqueArray = [];

    userRoleApi.setBearerProtection();
    const roleData = await userRoleApi.get('user', basicData.id);

    for (var i = 0; i < roleData.length; i++) {
        let entry = {};
        entry.roleName = roleData[i].role.roleName;
        entry.roleId = roleData[i].role.id;

        basicData.roles.push(entry);

        //if profileRoleList has an entry which entry.id is repeated, do not add again

        if (profileRoleList.indexOf(roleData[i].role.id) === -1) {
            profileRoleList.push(roleData[i].role.id);
            uniqueArray.push(entry);
        }

        //profileRoleList.push(roleData[i].role);
    }

    var token = encryption.encrypt(basicData);
    localStorage.setItem('token', token);

    profileRoleList = encryption.encrypt(uniqueArray);
    localStorage.setItem('profileRoleList', profileRoleList);

    return token;

};

const sign_out = () => {
    localStorage.clear();
}

const update_password = async (userEmail, userPassword, userPasswordConfirmation, userPasswordRecoveryToken) => {

    userApi.setBearerProtection();
    const calling = await userApi.post('update-password', {
        userEmail: userEmail,
        userPassword: userPassword,
        userPasswordConfirmation: userPasswordConfirmation,
        userPasswordRecoveryToken: userPasswordRecoveryToken
    });

    var basicData = {};
    basicData.id = calling.id;
    basicData.userEmail = calling.userEmail;
    var token = encryption.encrypt(basicData);
    localStorage.setItem('token', token);
    return token;

}

const generate_password_recover_token = async (userEmail) => {
    
        userApi.unsetBearerProtection();
        const calling = await userApi.post('generate-password-recovery-token', {
            userEmail: userEmail
        });
    
        return calling;
}

const password_recover_request = async (userEmail) => {
    
        userApi.unsetBearerProtection();
        const calling = await userApi.post('password-recover-request', {
            userEmail: userEmail
        });
    
        return calling;
    
}

const service = {
    sign_in,
    sign_out,
    update_password,
    get_institutions,
    generate_password_recover_token,
    password_recover_request
}

export default service