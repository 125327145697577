import React, { useState } from 'react';
import {
    MDBInput,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBContainer,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter

} from 'mdb-react-ui-kit';
import LOGO from '../assets/img/logo-color.png';
import { useNavigate } from 'react-router-dom';
import auth from '../services/authentication'

export default function SignIn() {

    const [loading, setLoading] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);

    const [formValue, setFormValue] = useState({
        userEmail: '',
        userPassword: ''
    });

    const navigateTo = useNavigate();

    const onChange = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const toggleShow = () => {
        setDisplayModal(!displayModal);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        setTimeout(async () => {
            try {
                await auth.sign_in(formValue.userEmail, formValue.userPassword)
                navigateTo('/profile-selector');
            } catch (error) {
                if (error.name === "AxiosError") {
                    console.log(error)
                    document.getElementById('modalMessage').innerHTML = error?.message || error
                    toggleShow();
                }
            }
            setLoading(false);
        }, 1000);

    };

    return (

        <MDBContainer className='vh-100'>
            <MDBModal tabIndex='-1' show={displayModal} setShow={setDisplayModal}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Aviso</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p id='modalMessage'>
                            </p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleShow}>
                                Ok
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                {
                    loading ? (
                        <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>Cargando...</span>
                        </div>
                    ) : (
                        <MDBCol lg={'6'} md={'8'}>
                            <MDBRow className=' text-center'>
                                <MDBCol
                                    size="12"
                                    className='my-4'
                                >
                                    <img src={LOGO} alt='Logo' className='img-fluid mx-auto w-75' />
                                    <hr />
                                    <h1 className='text-center'>Iniciar sesión</h1>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow tag="form" className='d-flex justify-content-center align-items-center'>
                                <MDBCol
                                    size="12"
                                    className='my-2'
                                >
                                    <MDBInput
                                        value={formValue.userEmail}
                                        name='userEmail'
                                        onChange={onChange}
                                        required
                                        label='Correo electrónico'
                                    />
                                </MDBCol>
                                <MDBCol
                                    size="12"
                                    className='my-2'
                                >
                                    <MDBInput
                                        value={formValue.userPassword}
                                        name='userPassword'
                                        onChange={onChange}
                                        required
                                        label='Contraseña'
                                        type='password'
                                        autoComplete='current-password'
                                    />
                                </MDBCol>
                                <MDBCol size="12" className='text-end my-2'>
                                    <MDBBtn
                                        type='submit'
                                        onClick={
                                            (e) => onSubmit(e)
                                        }
                                    >Entrar</MDBBtn>
                                </MDBCol>
                                <MDBCol size="12" className='text-end my-2'>
                                    ¿Olvidaste tu contraseña? Haz clic <a
                                        className='text-decoration-none'
                                        style={
                                            {
                                                cursor: 'pointer',
                                            }
                                        }
                                        onClick={() => {
                                            navigateTo('/password-recover-request');
                                        }}
                                    >
                                        aquí
                                    </a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    )
                }
            </MDBRow>

        </MDBContainer>

    );
}