import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import { MDBAnimation, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit'
import versionService from '../../services/versions'
import encryption from '../../services/encryption'
import SiteHeader from '../../components/SiteHeader'
import Breadcrumbs from '../../components/Breadcrumbs'

export default function Index() {

    const navigateTo = useNavigate();
    const [selectedProfile, setSelectedProfile] = useState(null);
    const { id } = useParams()
    const [versionData, setVersionData] = React.useState({})

    const handleLoadVersion = async () => {
        let data = await versionService.get(id)
        console.log(data)
        setVersionData(data)
    }

    const handleSelectedProfile = () => {
        let token = localStorage.getItem('selectedProfile');
        if (token) {
            let descryptedToken = encryption.decrypt(token);
            setSelectedProfile(descryptedToken);
        }
    }

    const breadCrumbsData = [
        {
            text: 'Inicio',
            href: `/home`
        },
        {
            text: 'Versiones',
            href: `/versions`
        },
        {
            text: `${versionData.versionName}`,
        },
    ];


    useEffect(() => {
        handleSelectedProfile()
        handleLoadVersion()
    }, [])

    return (
        <>
            <SiteHeader
                selectedProfile={selectedProfile}
            />
            <MDBAnimation
                animation='fade-in'
                start='onLoad'
                duration={700}
            >
                <MDBRow className='my-4 ms-4'>
                    <Breadcrumbs
                        items={breadCrumbsData}
                    />
                </MDBRow>
                <MDBContainer fluid>
                    <MDBRow className='my-4 ms-4'>
                        <h4>Versión {versionData.versionName}</h4>
                    </MDBRow>
                    <MDBRow className='my-4 ms-4'>
                        <MDBCol>
                            ...
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBAnimation>

        </>
    )
}
