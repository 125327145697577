import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SiteHeader from '../components/SiteHeader'
import Breadcrumbs from '../components/Breadcrumbs'
import {
    MDBAnimation,
    MDBContainer,
    MDBRow,
    MDBDatatable,
    MDBBtn,
    MDBIcon,
    MDBCol,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBSelect,
} from 'mdb-react-ui-kit'
import encryption from '../services/encryption'
import slot from '../services/slots'
import stateService from '../services/states'
import modulePermissions from '../utilities/modulePermissions'
import auth from '../services/authentication'

export default function Slots() {

    const moduleName = 'slot-cities';
    const navigateTo = useNavigate();
    const { version_id } = useParams();
    const [selectedProfile, setSelectedProfile] = useState(null);

    const [stateList, setStateList] = useState([]);
    const [modalEditShow, setModalEditShow] = useState(false);
    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    const [entryId, setEntryId] = useState(null);
    const [state, setState] = useState({
        version_id: version_id,
        state_id: '',
        slotQuantity: '',
    });

    const [modules, setModules] = useState({});

    const breadCrumbsData = [
        {
            text: 'Inicio',
            href: `/home`
        },
        {
            text: 'Versiones',
            href: `/version/${version_id}`
        },
        {
            text: 'Cupos',
        }
    ];

    const handleSelectedProfile = () => {
        let token = localStorage.getItem('selectedProfile');
        if (token) {
            let descryptedToken = encryption.decrypt(token);
            setSelectedProfile(descryptedToken);
        }
    }

    const toggleEditModal = (newState) => {
        setModalEditShow(newState);
    }

    const toggleDeleteModal = (newState) => {
        setModalDeleteShow(newState);
    }

    const btnActionEdit = (item, id) => {
        let newState = { ...item };
        delete newState.id;
        setEntryId(id);
        setState(newState);
        toggleEditModal(true);
    }

    const btnActionDelete = (item, id) => {
        let newState = { ...item };
        delete newState.id;
        setEntryId(id);
        setState(newState);
        toggleDeleteModal(true);
    }

    const [asyncData, setAsyncData] = useState({
        columns: [
            {
                label: 'ID',
                field: 'id',
            },
            {
                label: 'Región',
                field: 'stateName',
            },
            {
                label: 'Cupos',
                field: 'slotQuantity',
            },
            {
                label: 'Acciones',
                field: 'actions',
            },
        ],
        rows: []
    });

    const [dataLoading, setDataLoading] = useState(false);

    const handleDataLoad = async () => {
        const slots = await slot.listByVersion(version_id)
        setAsyncData({
            columns: asyncData.columns,
            rows: slots.map((item, index) => {
                return {
                    id: index + 1,
                    stateName: item.state.stateName,
                    slotQuantity: item.slotQuantity,
                    actions: <>
                        {
                            selectedProfile && modules?.slots?.includes('update') && (
                                <MDBBtn color='info' size='lg' className='me-2' floating onClick={() => btnActionEdit(item, item.id)}>
                                    <MDBIcon icon='edit' />
                                </MDBBtn>
                            )
                        }
                        {
                            selectedProfile && modules?.slots?.includes('delete') && (
                                <MDBBtn color='danger' size='lg' className='me-2' floating onClick={() => btnActionDelete(item, item.id)}>
                                    <MDBIcon icon='trash' />
                                </MDBBtn>
                            )
                        }
                        {
                            selectedProfile && modules?.slots?.includes('internal') && (
                                <MDBBtn
                                    color='secondary'
                                    size='lg'
                                    floating
                                    type='button'
                                    onClick={() => {
                                        navigateTo(`/${moduleName}/${version_id}/${item.state.id}`);
                                    }}
                                >
                                    <MDBIcon icon='eye' />
                                </MDBBtn>
                            )
                        }
                    </>
                }
            })

        })
    }

    const handleUpdate = async () => {
        await slot.update(entryId, {
            ...state
        });
        handleDataLoad();
        toggleEditModal(false);
    }

    const handleDelete = async () => {
        await slot.remove(entryId);
        handleDataLoad();
        setState({
            version_id: version_id,
            state_id: '',
            slotQuantity: '',
        });
        toggleDeleteModal(false);
    }

    useEffect(() => {
        handleSelectedProfile();
    }, []);

    useEffect(() => {
        handleDataLoad();
    }, [dataLoading, asyncData.columns, modules, selectedProfile]);

    useEffect(() => {
        if (asyncData.rows.length === 0) {
            setDataLoading(true);
        } else {
            setDataLoading(false);
        }
    }, [asyncData.rows]);

    const handleStateListLoad = async () => {
        const result = await stateService.list();
        setStateList(result);
    }

    const handlePageModules = async () => {
        let modulesData = {};
        modulesData = {
            slots: []
        }
        let available = false;

        available = await modulePermissions(selectedProfile.roleName, 'slots', 'update');
        if (available) modulesData.slots.push('update');
        available = await modulePermissions(selectedProfile.roleName, 'slots', 'delete');
        if (available) modulesData.slots.push('delete');
        available = await modulePermissions(selectedProfile.roleName, 'slots', 'internal');
        if (available) modulesData.slots.push('internal');

        setModules(modulesData);
    }

    useEffect(() => {
    }, [modules]);

    useEffect(() => {
        if (selectedProfile) {
            handlePageModules();
            handleStateListLoad();
        }
    }, [selectedProfile]);

    return (
        <>
            <SiteHeader
                selectedProfile={selectedProfile}
            />
            <MDBAnimation
                animation='fade-in'
                start='onLoad'
                duration={700}
            >
                <MDBRow className='my-4 ms-4'>
                    <Breadcrumbs
                        items={breadCrumbsData}
                    />
                </MDBRow>
                <MDBContainer>
                    <MDBRow className='my-4 ms-4'>
                        <h4>Cupos regionales</h4>
                    </MDBRow>
                    <MDBRow>
                        <MDBDatatable
                            data={asyncData}
                            rowsText='Filas'
                            ofText='de'
                            searchLabel='Buscar'
                            search
                            noFoundMessage='No se encontraron resultados'
                            isLoading={dataLoading}
                            searchInputProps={
                                {
                                    'className': 'pb-1',
                                }
                            }
                        />
                    </MDBRow>
                </MDBContainer>
            </MDBAnimation>

            <MDBModal tabIndex='-1' show={modalEditShow} setShow={setModalEditShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Editar cupo</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleEditModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <input type='hidden' value={state.version_id} />
                            <p>
                                Cupos en {
                                    stateList.find((item) => item.id === state.state_id) && (
                                        stateList.find((item) => item.id === state.state_id).stateName
                                    )
                                }
                                
                            </p>
                            <MDBInput
                                label='Cantidad de cupos'
                                value={state.slotQuantity}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        slotQuantity: e.target.value
                                    });
                                }}
                                className='mb-4'
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleEditModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    handleUpdate();
                                }}
                            >Actualizar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1' show={modalDeleteShow} setShow={setModalDeleteShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Eliminar cupo</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleDeleteModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>¿Está seguro que desea eliminar la cupo {state.slotName}?</p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleDeleteModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    handleDelete();
                                }}
                            >Eliminar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}
