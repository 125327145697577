import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SiteHeader from '../components/SiteHeader'
import Breadcrumbs from '../components/Breadcrumbs'
import {
    MDBAnimation,
    MDBContainer,
    MDBRow,
    MDBDatatable,
    MDBBtn,
    MDBIcon,
    MDBCol,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBSelect,
} from 'mdb-react-ui-kit'
import encryption from '../services/encryption'
import userService from '../services/users'
import userRoleService from '../services/userRoles'
import roleService from '../services/roles'

export default function Users() {

    const moduleName = 'users';
    const navigateTo = useNavigate();
    const [selectedProfile, setSelectedProfile] = useState(null);

    const [modalAddShow, setModalAddShow] = useState(false);
    const [modalEditShow, setModalEditShow] = useState(false);
    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    const [modalSystemRoleShow, setModalSystemRoleShow] = useState(false);
    const [entryId, setEntryId] = useState(null);
    const [accountStatusData, setAccountStatusData] = useState([]);
    const [systemRoleData, setSystemRoleData] = useState([]);
    const [userSystemRoleData, setUserSystemRoleData] = useState([]);
    const [state, setState] = useState({
        userName: '',
        userEmail: '',
        userPassword: '',
        userAccountStatus: 'creada',
    });

    const breadCrumbsData = [
        {
            text: 'Inicio',
            href: `/home`
        },
        {
            text: 'Usuarios',
        }
    ];

    const handleAccountStatusLoad = () => {
        setAccountStatusData([
            {
                value: 'creada',
                text: 'Creada',
            },
            {
                value: 'invitada',
                text: 'Invitada',
            },
            {
                value: 'activa',
                text: 'Activa',
            },
            {
                value: 'inactiva',
                text: 'Inactiva',
            },
            {
                value: 'eliminada',
                text: 'Eliminada',
            },
        ]
        );
    }

    const handleSystemRoleLoad = async () => {
        let response = await roleService.list();
        if (response) {
            setSystemRoleData(
                response.filter(item => item.id <= 3).map(item => {
                    return item;
                })
            );
        }
    }

    const handleUserSystemRoleLoad = async (user_id) => {
        let response = await userRoleService.byUser(user_id);
        if (response) {
            setUserSystemRoleData(
                //if role_id is 1,2 or 3, then it is a system role
                response.filter(item => item.role_id <= 3).map(item => {
                    return item;
                })
            );
        }
    }

    const handleSelectedProfile = () => {
        let token = localStorage.getItem('selectedProfile');
        if (token) {
            let descryptedToken = encryption.decrypt(token);
            setSelectedProfile(descryptedToken);
        }
    }

    const toggleAddModal = () => {
        if (modalAddShow === false) {
            setState({
                userName: '',
                userEmail: '',
                userPassword: '',
                userAccountStatus: 'creada',
            });
        }
        setModalAddShow(!modalAddShow);
    }

    const toggleEditModal = (newState) => {
        setModalEditShow(newState);
    }

    const toggleDeleteModal = (newState) => {
        setModalDeleteShow(newState);
    }

    const toggleSystemRoleModal = (newState) => {
        setModalSystemRoleShow(newState);
    }

    const btnActionEdit = (item, id) => {
        let newState = {
            ...item,
            userPassword: '',
        };
        delete newState.id;
        setEntryId(id);
        setState(newState);
        toggleEditModal(true);
    }

    const btnActionDelete = (item, id) => {
        let newState = { ...item };
        delete newState.id;
        setEntryId(id);
        setState(newState);
        toggleDeleteModal(true);
    }

    const btnActionSetSystemRole = async (item, id) => {
        setEntryId(id);
        let newState = { ...item };
        setState(newState);
        await handleUserSystemRoleLoad(id);
        toggleSystemRoleModal(true);
    }

    const [asyncData, setAsyncData] = useState({
        columns: [
            {
                label: 'ID',
                field: 'id',
            },
            {
                label: 'Nombre',
                field: 'userName',
            },
            {
                label: 'Email',
                field: 'userEmail',
            },
            {
                label: 'Estado',
                field: 'userAccountStatus',
            },
            {
                label: 'Acciones',
                field: 'actions',
            },
        ],
        rows: []
    });

    const [dataLoading, setDataLoading] = useState(false);

    const handleDataLoad = async () => {
        const data = await userService.list()

        setAsyncData({
            columns: asyncData.columns,
            rows: data.map((item, index) => {
                return {
                    id: index + 1,
                    userName: item.userName,
                    userEmail: item.userEmail,
                    userAccountStatus: accountStatusData?.find((accountStatus) => accountStatus.value === item.userAccountStatus)?.text ?? '',
                    actions: (
                        <>
                            {
                                selectedProfile?.roleName === 'Administrador' && (
                                    <>
                                        <MDBBtn color='info' outline size='lg' className='me-2' floating onClick={() => btnActionSetSystemRole(item, item.id)}>
                                            SR
                                        </MDBBtn>
                                        <MDBBtn color='info' size='lg' className='me-2' floating onClick={() => btnActionEdit(item, item.id)}>
                                            <MDBIcon icon='edit' />
                                        </MDBBtn>
                                        <MDBBtn color='danger' size='lg' className='me-2' floating onClick={() => btnActionDelete(item, item.id)}>
                                            <MDBIcon icon='trash' />
                                        </MDBBtn>
                                    </>
                                )
                            }
                        </>
                    )
                }
            })
        })
    }

    const handleCreate = async () => {
        await userService.create(
            {
                userName: state.userName,
                userEmail: state.userEmail,
                userPassword: state.userPassword,
                userAccountStatus: state.userAccountStatus,
            }
        );
        handleDataLoad();
        toggleAddModal();
        setState(
            {
                userName: '',
                userEmail: '',
                userPassword: '',
                userAccountStatus: 'creada',
            }
        );
    }

    const handleUpdate = async () => {
        await userService.update(entryId,
            {
                userName: state.userName,
                userEmail: state.userEmail,
                userPassword: state.userPassword,
                userAccountStatus: state.userAccountStatus,
            }
        );
        handleDataLoad();
        toggleEditModal(false);
    }

    const handleDelete = async () => {
        await userService.remove(entryId);
        handleDataLoad();
        setState({
            userName: '',
            userEmail: '',
            userPassword: '',
            userAccountStatus: 'creada',
        });
        toggleDeleteModal(false);
    }

    const handleSystemRole = async (id, hasRole) => {
        if (hasRole) {
            let userRole = userSystemRoleData.find((item) => item.role_id === id && item.user_id === entryId);
            await userRoleService.remove(userRole.id);
        } else {
            await userRoleService.create({
                user_id: entryId,
                role_id: id,
            });
        }
        handleUserSystemRoleLoad(entryId);
    }

    useEffect(() => {
        handleSystemRoleLoad();
        handleAccountStatusLoad();
        handleDataLoad();
    }, [dataLoading, asyncData.columns]);

    useEffect(() => {
        if (asyncData.rows.length === 0) {
            setDataLoading(true);
        } else {
            setDataLoading(false);
        }
    }, [asyncData.rows]);

    useEffect(() => {
        handleSelectedProfile();
    }, []);

    return (
        <>
            <SiteHeader
                selectedProfile={selectedProfile}
            />
            <MDBAnimation
                animation='fade-in'
                start='onLoad'
                duration={700}
            >
                <MDBRow className='my-4 ms-4'>
                    <Breadcrumbs
                        items={breadCrumbsData}
                    />
                </MDBRow>
                <MDBContainer>
                    <MDBRow className='my-4 ms-4'>
                        <h4>Usuarios</h4>
                    </MDBRow>
                    {
                        selectedProfile?.roleName === 'Administrador' && (
                            <MDBRow className='text-end'>
                                <MDBCol className='me-0 pe-0'>
                                    <MDBBtn color='primary' className='my-4' size='lg' floating onClick={() => {
                                        setState({
                                            userName: '',
                                            userEmail: '',
                                            userPassword: '',
                                            userAccountStatus: 'creada',
                                        });
                                        toggleAddModal();
                                    }}>
                                        <MDBIcon icon='plus' />
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        )
                    }
                    <MDBRow>
                        <MDBDatatable
                            data={asyncData}
                            rowsText='Filas'
                            ofText='de'
                            searchLabel='Buscar'
                            search
                            noFoundMessage='No se encontraron resultados'
                            isLoading={dataLoading}
                            searchInputProps={
                                {
                                    'className': 'pb-1',
                                }
                            }
                        />
                    </MDBRow>
                </MDBContainer>
            </MDBAnimation>

            <MDBModal tabIndex='-1' show={modalAddShow} setShow={setModalAddShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Agregar permiso</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleAddModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput
                                label='Nombre'
                                type='text'
                                value={state.userName}
                                onChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            userName: e.target.value
                                        })
                                    }
                                }
                                className='mb-4'
                            />
                            <MDBInput
                                label='Email'
                                type='email'
                                value={state.userEmail}
                                onChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            userEmail: e.target.value
                                        })
                                    }
                                }
                                className='mb-4'
                            />
                            <MDBInput
                                label='Contraseña'
                                type='password'
                                value={state.userPassword}
                                onChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            userPassword: e.target.value
                                        })
                                    }
                                }
                                className='mb-4'
                            />
                            <MDBSelect
                                label='Estado de la cuenta'
                                data={
                                    accountStatusData.map((e) => {
                                        return {
                                            text: e.text,
                                            value: e.value,
                                            defaultSelected: e.value === state.userAccountStatus
                                        }
                                    })
                                }
                                onValueChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            userAccountStatus: e.value
                                        })
                                    }
                                }
                                className='mb-4'
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={toggleAddModal}>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                color='primary'
                                onClick={
                                    () => {
                                        handleCreate()
                                    }
                                }

                            >Insertar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1' show={modalEditShow} setShow={setModalEditShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Editar permiso</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleEditModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput
                                label='Nombre'
                                type='text'
                                value={state.userName}
                                onChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            userName: e.target.value
                                        })
                                    }
                                }
                                className='mb-4'
                            />
                            <MDBInput
                                label='Email'
                                type='email'
                                value={state.userEmail}
                                onChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            userEmail: e.target.value
                                        })
                                    }
                                }
                                className='mb-4'
                            />
                            <MDBInput
                                label='(Ingrese una nueva contraseña para cambiarla)'
                                type='password'
                                value={state.userPassword}
                                onChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            userPassword: e.target.value
                                        })
                                    }
                                }
                                className='mb-4'
                            />
                            <MDBSelect
                                label='Estado de la cuenta'
                                data={
                                    accountStatusData.map((e) => {
                                        return {
                                            text: e.text,
                                            value: e.value,
                                            defaultSelected: e.value === state.userAccountStatus
                                        }
                                    })
                                }
                                onValueChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            userAccountStatus: e.value
                                        })
                                    }
                                }
                                className='mb-4'
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleEditModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    handleUpdate();
                                }}
                            >Actualizar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1' show={modalDeleteShow} setShow={setModalDeleteShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Eliminar usuario</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleDeleteModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>¿Está seguro que desea eliminar al usuario {state.userName} / {state.userEmail}?</p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleDeleteModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    handleDelete();
                                }}
                            >Eliminar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1'
                show={modalSystemRoleShow}
                setShow={setModalSystemRoleShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>
                                <p>Roles de Sistema</p>
                                <p>
                                    {state.userName} / {state.userEmail}
                                </p>
                            </MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleSystemRoleModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            
                            {
                                modalSystemRoleShow && systemRoleData.map((item, index) => {
                                    let isSelected = false;
                                    isSelected = userSystemRoleData.find((e) => {
                                        return e.role_id === item.id
                                    })
                                    return (
                                        <MDBRow key={index} className='mb-4'>
                                            <MDBCol>
                                                <MDBBtn
                                                    color={isSelected ? 'primary' : 'secondary'}
                                                    onClick={
                                                        () => {
                                                            handleSystemRole(item.id, isSelected)
                                                        }
                                                    }
                                                >
                                                    {isSelected ? 'Es' : 'No es'} {item.roleName}
                                                </MDBBtn>
                                            </MDBCol>
                                        </MDBRow>
                                    )
                                })
                            }

                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleSystemRoleModal(false)
                            }>
                                Listo
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}
