import permissionService from '../services/permissions';

const modulePermissions = async (profile, module, permission) => {
    const moduleMap = await permissionService.listNormalized(profile);

    if (!moduleMap[profile]) return false;

    if (!moduleMap[profile][module]) return false;

    if (!moduleMap[profile][module].includes(permission)) return false;

    return true;

}

export default modulePermissions;