import React, { useEffect, useState } from 'react'
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
} from 'mdb-react-ui-kit';
import auth from '../services/authentication';
import encryption from '../services/encryption';
import { useNavigate } from 'react-router-dom';

export default function ProfileSelector() {

    const navigateTo = useNavigate();
    const [decryptedProfileRoleList, setDecryptedProfileRoleList] = useState([]);

    const loadProfileData = () => {
        let profileRoleList = localStorage.getItem('profileRoleList');
        if (profileRoleList) {
            setDecryptedProfileRoleList(encryption.decrypt(profileRoleList));
        }
    }

    const handleProfileSelection = (role) => {
        let encryptedToken = encryption.encrypt(role);
        localStorage.setItem('selectedProfile', encryptedToken);
        navigateTo('/home');
    }

    const resetSelectedProfile = () => {
        localStorage.removeItem('selectedProfile');
    }

    const btnGoToSignIn = () => {
        auth.sign_out();
        navigateTo('/');
    }

    useEffect(() => {
        resetSelectedProfile();
        loadProfileData();
    }, [])

    useEffect(() => {
        if (decryptedProfileRoleList.length > 0) {
            if (decryptedProfileRoleList.length === 1) {
                handleProfileSelection(decryptedProfileRoleList[0]);
            }
        }
    }, [decryptedProfileRoleList])

    return (
        <>
            <MDBContainer className='vh-100'>
                <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                    <MDBCol lg={'6'} md={'8'}>
                        <MDBRow>
                            <MDBCol
                                size="12"
                                className='my-4'
                            >
                                <h2 className='text-center'>Seleccione su perfil</h2>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow tag="form" className='d-flex justify-content-center align-items-center'>
                            <MDBCol size="12" className='my-2'>
                                {
                                    decryptedProfileRoleList.map((role, index) => {
                                        return (
                                            <MDBBtn
                                                type='button'
                                                key={index}
                                                color='primary'
                                                className='w-100 my-2'
                                                onClick={() => {
                                                    handleProfileSelection(role)
                                                }}
                                            >
                                                {
                                                    role.roleName === 'Administrador' && 'Administrador/a'
                                                }
                                                {
                                                    role.roleName === 'Asesor' && 'Asesor/a'
                                                }
                                                {
                                                    role.roleName === 'Coordinador' && 'Coordinador/a'
                                                }
                                                {
                                                    ![
                                                        'Administrador',
                                                        'Asesor',
                                                        'Coordinador'
                                                    ].includes(role.roleName) && role.roleName
                                                }
                                            </MDBBtn>
                                        )
                                    })
                                }
                            </MDBCol>
                            <hr className='mt-4' />
                            <MDBCol size="12" className='my-2'>
                                <MDBBtn
                                    type='button'
                                    color='primary'
                                    outline
                                    className='w-100 my-2'
                                    onClick={btnGoToSignIn}
                                >
                                    Cerrar sesión
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>

            </MDBContainer>
        </>
    )
}
