import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  MDBAnimation,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBTextArea,
  MDBSelect,
  MDBCheckbox,
  MDBRadio,
  MDBFile,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import authService from '../../services/authentication'
import formService from '../../services/forms'
import activityService from '../../services/activities'
import stateService from '../../services/states'
import cityService from '../../services/cities'
import answerService from '../../services/answers'
import encryption from '../../services/encryption'
import SiteHeader from '../../components/SiteHeader'
import Breadcrumbs from '../../components/Breadcrumbs'
export default function SelfEvaluation() {
  const navigateTo = useNavigate();
  const [selectedProfile, setSelectedProfile] = useState(null);
  const { id } = useParams()
  const [answerScore, setAnswerScore] = useState([])
  const [formData, setFormData] = useState({})
  const [formAnswers, setFormAnswers] = useState([])
  const [formAnswersOnLoad, setFormAnswersOnLoad] = useState([])
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [navButtons, setNavButtons] = useState(false)
  const [activityData, setActivityData] = useState()
  const [firstTimeLoad, setFirstTimeLoad] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [modalHandler, setModalHandler] = useState({
    title: '',
    message: '',
    buttons: ''
  })
  const formRef = useRef()

  const handleLoadActivity = async () => {
    const activityDataObject = await activityService.get(id);
    if (!activityDataObject?.activityType?.form_id) {
      alert("No se ha asignado un formulario a esta actividad");
      authService.sign_out();
      navigateTo('/sign-in');
    }
    if (activityDataObject.length == 0) {
      alert("No se ha encontrado la actividad");
      authService.sign_out();
      navigateTo('/sign-in');
    }
    setActivityData(activityDataObject)
  }
  const handleLoadForm = async () => {
    let data = await formService.get(activityData.activityType.form_id);
    let fields = data.fields;
    fields.map((field, index) => {
      if (field.fieldType == 'select') {
        let options = field.fieldOptions.split(',');
        let dataArray = [];
        options.map((option, optionIndex) => {
          let valueArray = field.fieldValues.split(',');
          let scoreArray = field.fieldScores.split(',');
          let optionData = {
            text: options[optionIndex],
            value: valueArray[optionIndex],
            score: scoreArray[optionIndex]
          }
          dataArray.push(optionData)
        })
        field.dataArray = dataArray;
      }
    })
    data.fields = fields;
    setFormData(data)
  }
  const handleSelectedProfile = () => {
    let token = localStorage.getItem('selectedProfile');
    if (token) {
      let descryptedToken = encryption.decrypt(token);
      setSelectedProfile(descryptedToken);
    }
  }
  const handleLoadStates = async () => {
    let data = await stateService.list()
    setStateList(data)
  }
  const handleLoadCities = async (stateId) => {
    let data = await cityService.listByState(stateId)
    setCityList(data)
  }

  const originData = useLocation();

  let breadCrumbsData;
  let goBackRoute;
  if (originData.state?.origin == '/activities') {
    breadCrumbsData = [
      {
        text: 'Inicio',
        href: `/home`
      },
      {
        text: 'Versión',
        href: `/version/${activityData?.version_id}`
      },
      {
        text: 'Actividades',
        href: `/activities/${activityData?.version_id}`
      },
      {
        text: `Revisión ${formData?.formName}`,
      },
    ];
    goBackRoute = `/activities/${activityData?.version_id}`;
  }
  if (originData.state?.origin == '/tree/self-evaluation') {
    breadCrumbsData = [
      {
        text: 'Inicio',
        href: `/home`
      },
      {
        text: 'Versión',
        href: `/version/${activityData?.version_id}`
      },
      {
        text: 'Selección de establecimientos',
        href: `/tree/self-evaluation/${activityData?.version_id}`
      },
      {
        text: `Revisión ${formData?.formName}`,
      },
      goBackRoute = `/tree/self-evaluation/${activityData?.version_id}`
    ];
  }
  const handleAnswerOnLoad = async (activityId) => {
    const data = await answerService.listByActivity(activityId);
    setFormAnswersOnLoad(
      data.map((answer) => {
        if (answer.field.fieldType == 'select' && answer.field.fieldMultiple == 1 || answer.field.fieldType == 'checkbox' || answer.field.fieldType == 'radio') {
          return {
            fieldId: answer.field.fieldCode,
            fieldValue: answer.answerValue.split(',').length > 0 ? answer.answerValue.split(',') : []
          }
        } else {
          return {
            fieldId: answer.field.fieldCode,
            fieldValue: answer.answerValue
          }
        }
      })
    )
    setAnswerScore(
      data.map((answer) => {
        return {
          fieldId: answer.field.fieldCode,
          fieldValue: answer.answerScore
        }
      })
    )
  }
  const handleAnswerLoad = async (activityId) => {
    const data = await answerService.listByActivity(activityId);
    let newData = data.map((answer) => {
      if (answer.field.fieldType == 'select' && answer.field.fieldMultiple == 1 || answer.field.fieldType == 'checkbox') {
        return {
          fieldId: answer.field.fieldCode,
          fieldValue: answer.answerValue.split(',').length > 1 ? answer.answerValue.split(',') : []
        }
      }
      if (answer.field.fieldType == 'address_dropdown') {
        let stateValue = answer.answerValue.split(',')[0];
        let cityValue = answer.answerValue.split(',')[1];
        return {
          fieldId: answer.field.fieldCode,
          fieldValue: stateValue + ',' + cityValue
        }
      }
      else {
        return {
          fieldId: answer.field.fieldCode,
          fieldValue: answer.answerValue
        }
      }
    })

    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.field.fieldType == 'address_dropdown') {
        let stateValue = item.answerValue.split(',')[0];
        let cityValue = item.answerValue.split(',')[1];
        handleLoadCities(stateValue);
        newData.push(
          {
            fieldId: item.field.fieldCode + '_state',
            fieldValue: stateValue
          }
        )
        newData.push(
          {
            fieldId: item.field.fieldCode + '_city',
            fieldValue: cityValue
          }
        )
      }
    }

    setFormAnswers(newData)
  }

  const closeModal = () => {
    setModalHandler({
      title: '',
      message: '',
      buttons: ''
    })
    setModalShow(false)
  }

  const handleNextStatus = async (newStatus) => {

    //check all formData.fields and rescue answers
    let newAnswerData = [];
    let errorInField = false;
    formData.fields.map(async (field, index) => {
      let fieldIndex = formAnswers?.findIndex((answer) => answer.fieldId == field.fieldCode);
      if (fieldIndex > -1) {
        let answerValue;
        if (field.fieldType == 'select' && field.fieldMultiple == 1 || field.fieldType == 'checkbox') {
          answerValue = formAnswers[fieldIndex].fieldValue.join(',')
        } else {
          if (field.fieldType == 'address_dropdown') {
            //two fields: field.fieldCode + '_state' and field.fieldCode + '_city'
            let stateFieldIndex = formAnswers?.findIndex((answer) => answer.fieldId == field.fieldCode + '_state');
            let cityFieldIndex = formAnswers?.findIndex((answer) => answer.fieldId == field.fieldCode + '_city');
            answerValue = formAnswers[stateFieldIndex].fieldValue + ',' + formAnswers[cityFieldIndex].fieldValue
          } else {
            answerValue = formAnswers[fieldIndex].fieldValue
          }
        }
        if (newStatus == 'finalizada' && parseInt(field.fieldRequired) == 1 && answerValue == '') {
          errorInField = true;
          //set is-invalid class to the field
          let t = document.querySelectorAll('[name="' + field.fieldCode + '"]')
          t.forEach((item) => {
            item.classList.add('is-invalid')
          })
        }
        newAnswerData.push({
          activity_id: id,
          field_id: field.id,
          version_id: field.version_id,
          form_id: formData.id,
          answerValue: answerValue
        })
      } else {
        let answerValue, t;
        if (field.fieldType == 'select' && field.fieldMultiple == 1 || field.fieldType == 'checkbox') {
          answerValue = ''
        } else {
          if (field.fieldType == 'select') {
            t = document.querySelector('div[name="' + field.fieldCode + '"]')
            let selectedText = t.querySelector('input')?.value || ''
            //search the value inside the field.dataArray array of objects which text is equal to selectedText
            let selectedValue = field.dataArray.find((option) => option.text == selectedText)?.value || ''
            answerValue = selectedValue
          } else {
            answerValue = ''
          }
          if (field.fieldType == 'address_dropdown') {
            let t_state = document.querySelector('div[name="' + field.fieldCode + '_state"]')
            let selectedText_state = t_state.querySelector('input')?.value || ''
            let selectedValue_state = stateList.find((data) => data.stateName == selectedText_state)?.id || ''

            let t_city = document.querySelector('div[name="' + field.fieldCode + '_city"]')
            let selectedText_city = t_city.querySelector('input')?.value || ''
            let selectedValue_city = cityList.find((data) => data.cityName == selectedText_city)?.id || ''

            answerValue = selectedValue_state + ',' + selectedValue_city
          }
        }
        if (newStatus == 'finalizada' && parseInt(field.fieldRequired) == 1 && answerValue == '') {
          errorInField = true;
          //set is-invalid class to the field
          let t = document.querySelectorAll('[name="' + field.fieldCode + '"]')
          t.forEach((item) => {
            item.classList.add('is-invalid')
          })
        }
        newAnswerData.push({
          activity_id: id,
          field_id: field.id,
          version_id: field.version_id,
          form_id: formData.id,
          answerValue: answerValue
        })
      }
    })
    if (errorInField) {
      setModalHandler({
        title: 'Atención',
        message: 'Por favor, complete todos los campos obligatorios',
        buttons: <button className="btn btn-primary" onClick={closeModal}>Aceptar</button>
      })
      setModalShow(true)
      return;
    }

    let data = {
      activityStatus: newStatus
    }
    await activityService.update(id, data);
    const activityDataObject = await activityService.get(id);
    setActivityData(activityDataObject)

    let form = {
      fields: newAnswerData
    }

    if (newStatus == 'en proceso') {
      await answerService.answerActivity(id, form)
      setModalHandler({
        ...modalHandler,
        title: 'Éxito',
        message: 'La actividad ha sido guardada',
        buttons: <>
          <MDBBtn
            color="secondary"
            onClick={
              () => {
                setModalShow(false)
              }
            }
          >
            Cerrar
          </MDBBtn>
        </>
      })
      setModalShow(true)
    }
    if (newStatus == 'finalizada') {

      await answerService.answerActivity(id, form)
      setModalHandler({
        ...modalHandler,
        title: 'Éxito',
        message: 'La actividad ha sido guardada y cerrada',
        buttons: <>
          <MDBBtn
            color="secondary"
            onClick={
              () => {
                setModalShow(false)
              }
            }
          >
            Cerrar
          </MDBBtn>
        </>
      })
      setModalShow(true)
    }

  }

  const handleFieldValueChange = (fieldId, value, isArray = false, valueToRemove = null) => {
    let newFormAnswers = [...formAnswers];
    let fieldIndex = newFormAnswers?.findIndex((field) => field.fieldId == fieldId);
    if (fieldIndex > -1) {
      if (isArray) {
        let newArray = [...newFormAnswers[fieldIndex].fieldValue];
        if (value != null) {
          newArray.push(value)
          newFormAnswers[fieldIndex].fieldValue = newArray
        } else {
          let valueIndex = newArray.findIndex((val) => val == valueToRemove)
          newArray.splice(valueIndex, 1)
          newFormAnswers[fieldIndex].fieldValue = newArray
        }
        if (newArray.length == 0) {
          newFormAnswers.splice(fieldIndex, 1)
        }
      }
      else {
        newFormAnswers[fieldIndex].fieldValue = value
      }
    }
    else {
      let newField = {
        fieldId: fieldId,
        fieldValue: isArray ? [value] : value
      }
      newFormAnswers.push(newField)
    }
    //if .is-invalid class is present, remove it

    let t = document.querySelectorAll('[name="' + fieldId + '"]')
    t.forEach((item) => {
      item.classList.remove('is-invalid')
    })

    setFormAnswers(newFormAnswers)
  }
  useEffect(() => {
    handleSelectedProfile()
    handleLoadStates()
  }, [])
  useEffect(() => {
    if (stateList.length > 0 && cityList.length == 0) {
      handleLoadCities(stateList[0].id)
    }
  }, [stateList])
  useEffect(() => {
    if (selectedProfile) {
      handleLoadActivity()
    }
  }, [selectedProfile])
  useEffect(() => {
    if (activityData) {
      handleLoadForm()
    }
  }, [activityData])
  useEffect(() => {
    if (formData?.fields && firstTimeLoad) {
      handleAnswerOnLoad(id)
      handleAnswerLoad(id)
      setNavButtons(true);
      setFirstTimeLoad(false);
    }
  }, [formData?.fields])
  useEffect(() => {
  }, [activityData])
  useEffect(() => {
    if (formAnswers.length > 0) {
      //console.log(formAnswers)
    }
  }, [formAnswers])
  useEffect(() => {
    if (formAnswersOnLoad.length > 0) {
    }
  }, [formAnswersOnLoad])
  useEffect(() => {
  }, [formData])
  return (
    <>
      <SiteHeader
        selectedProfile={selectedProfile}
      />
      <MDBAnimation
        animation='fade-in'
        start='onLoad'
        duration={700}
      >
        <MDBRow className='my-4 ms-4'>
          <Breadcrumbs
            items={breadCrumbsData}
          />
        </MDBRow>
        <MDBContainer fluid>
          <MDBRow className='my-4 ms-4'>
            <MDBCol className='d-flex justify-content-end me-4'>
              <MDBBtn outline color='primary' className='ms-4' onClick={() =>
                navigateTo(goBackRoute)
              }>
                Volver
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow className='my-4 ms-4'>
            <h4>Formulario {formData?.formName}</h4>
          </MDBRow>
          {
            activityData?.activityStatus !== 'finalizada' && (
              <>
                <MDBRow className='my-4 ms-4'>
                  <p>
                    Esta actividad no ha finalizado su proceso.
                  </p>
                  <p>
                    Por favor, espere a que el establecimiento complete el formulario para ver los resultados.
                  </p>
                </MDBRow>
              </>
            )
          }
          {
            activityData?.activityStatus === 'finalizada' && (
              <>
                <form ref={formRef}>
                  <MDBRow className='my-4 mx-4'>
                    {
                      formData?.fields?.map((field, index) => {
                        return (
                          <Fragment key={index}>
                            {
                              field.fieldType == 'text' &&
                              <>
                                {
                                  field.fieldName.length > 50 ?
                                    <div className='col-12 my-4 px-4'>
                                      <p>
                                        {field.fieldName}
                                      </p>
                                      <MDBCol className=''>
                                        <MDBInput
                                          label={
                                            field.fieldName.length <= 50 ? field.fieldName : false
                                          }
                                          type='text'
                                          name={field.fieldCode}
                                          required={field.fieldRequired == '1'}
                                          className={'my-2'}
                                          onChange={(e) => {
                                            handleFieldValueChange(field.fieldCode, e.target.value)
                                          }
                                          }
                                          value={
                                            formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                          }
                                          disabled={true}
                                        />
                                      </MDBCol>
                                    </div> : <>
                                      <MDBCol sm={12} className='my-4 px-4'>
                                        <MDBInput
                                          label={
                                            field.fieldName.length <= 50 ? field.fieldName : false
                                          }
                                          type='text'
                                          name={field.fieldCode}
                                          required={field.fieldRequired == '1'}
                                          className={'my-2'}
                                          onChange={(e) => {
                                            handleFieldValueChange(field.fieldCode, e.target.value)
                                          }
                                          }
                                          value={
                                            formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                          }
                                          disabled={true}
                                        />
                                      </MDBCol>
                                    </>
                                }

                              </>
                            }
                            {
                              field.fieldType == 'textarea' &&
                              <MDBCol sm={12} className='my-4 px-4'>
                                <MDBTextArea
                                  label={field.fieldName}

                                  name={field.fieldCode}
                                  required={field.fieldRequired == '1'}
                                  rows={field.fieldRows}
                                  className={'my-2'}
                                  onChange={(e) => {
                                    handleFieldValueChange(field.fieldCode, e.target.value)
                                  }
                                  }
                                  value={
                                    formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                  }
                                  disabled={true}
                                />
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'number' &&
                              <MDBCol sm={12} className='my-4 px-4'>
                                <MDBInput
                                  label={field.fieldName}
                                  type='number'

                                  name={field.fieldCode}
                                  required={field.fieldRequired == '1'}
                                  kpi={field.fieldIsKPI == '1' ? 'true' : 'false'}
                                  min={field.fieldMin}
                                  max={field.fieldMax}
                                  step={field.fieldStep}
                                  className={'my-2'}
                                  onChange={(e) => {
                                    handleFieldValueChange(field.fieldCode, e.target.value)
                                  }
                                  }
                                  value={
                                    formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                  }
                                  disabled={true}
                                />
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'date' &&
                              <MDBCol sm={12} className='my-4 px-4'>
                                <MDBInput
                                  label={field.fieldName}
                                  type='date'

                                  name={field.fieldCode}
                                  required={field.fieldRequired == '1'}
                                  min={field.fieldMin}
                                  max={field.fieldMax}
                                  className={'my-2'}
                                  onChange={(e) => {
                                    handleFieldValueChange(field.fieldCode, e.target.value)
                                  }
                                  }
                                  value={
                                    formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                  }
                                  disabled={true}
                                />
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'time' &&
                              <MDBCol sm={12} className='my-4 px-4'>
                                <MDBInput
                                  label={field.fieldName}
                                  type='time'

                                  name={field.fieldCode}
                                  required={field.fieldRequired == '1'}
                                  min={field.fieldMin}
                                  max={field.fieldMax}
                                  className={'my-2'}
                                  onChange={(e) => {
                                    handleFieldValueChange(field.fieldCode, e.target.value)
                                  }
                                  }
                                  value={
                                    formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                  }
                                  disabled={true}
                                />
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'datetime' &&
                              <MDBCol sm={12} className='my-4 px-4'>
                                <MDBInput
                                  label={field.fieldName}
                                  type='datetime-local'

                                  name={field.fieldCode}
                                  required={field.fieldRequired == '1'}
                                  min={field.fieldMin}
                                  max={field.fieldMax}
                                  className={'my-2'}
                                  onChange={(e) => {
                                    handleFieldValueChange(field.fieldCode, e.target.value)
                                  }
                                  }
                                  value={
                                    formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                  }
                                  disabled={true}
                                />
                              </MDBCol>
                            }
                            {

                              field.fieldType == 'select' &&
                              <>
                                <MDBCol sm={12} className='my-4 px-4'>
                                  <p>
                                    {field.fieldName}
                                  </p>
                                  <MDBSelect
                                    name={field.fieldCode}
                                    required={field.fieldRequired == '1'}
                                    data={
                                      field.dataArray.map((item, optionIndex) => {
                                        return {
                                          text: item.text || '',
                                          value: item.value || '',
                                          score: item.score || '',
                                          defaultSelected: item.value == formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                        }
                                      })
                                    }
                                    multiple={field.fieldMultiple == '1'}
                                    selectAllLabel='Todos'
                                    className={'my-2'}
                                    onValueChange={
                                      (e) => {
                                        handleFieldValueChange(field.fieldCode, e.value)
                                      }
                                    }
                                    disabled={true}
                                  />
                                </MDBCol>
                              </>
                            }
                            {
                              field.fieldType == 'checkbox' &&
                              <MDBCol sm={12} className='my-4 px-4'>
                                <p>
                                  {field.fieldName}
                                </p>
                                <div className={'my-2'}>
                                  {
                                    field.fieldOptions.split(',').map((option, optionIndex) => {
                                      return (
                                        <MDBCheckbox
                                          key={optionIndex}
                                          label={option}
                                          name={field.fieldCode}
                                          required={field.fieldRequired == '1'}
                                          value={field.fieldValues.split(',')[optionIndex] || ''}
                                          score={field.fieldScores.split(',')[optionIndex] || ''}
                                          onChange={(e) => {
                                            handleFieldValueChange(field.fieldCode,
                                              e.target.checked ? e.target.value : null,
                                              true,
                                              e.target.value
                                            )
                                          }}
                                          /** 
                                           * checked
                                           * 1. Si el valor del campo es igual al valor de la opción
                                          */
                                          checked={
                                            formAnswers?.find(
                                              (answer) => {
                                                return answer.fieldId == field.fieldCode
                                              }
                                            )?.fieldValue?.includes(
                                              field.fieldValues.split(',')[optionIndex]
                                            ) || false
                                          }
                                          disabled={true}
                                        />
                                      )
                                    })
                                  }
                                </div>
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'radio' &&
                              <MDBCol sm={12} className='my-4 px-4'>
                                <p>
                                  {field.fieldName}
                                </p>
                                <div className={'my-2'}>
                                  {
                                    field.fieldOptions.split(',').map((option, optionIndex) => {
                                      return (
                                        <MDBRadio
                                          key={optionIndex}
                                          label={option}

                                          name={field.fieldCode}
                                          required={field.fieldRequired == '1'}
                                          value={field.fieldValues.split(',')[optionIndex] || ''}
                                          score={field.fieldScores.split(',')[optionIndex] || ''}
                                          onChange={(e) => {
                                            handleFieldValueChange(field.fieldCode,
                                              e.target.checked ? e.target.value : null
                                            )
                                          }}
                                          checked={
                                            field.fieldValues.split(',')[optionIndex] == formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || false
                                          }
                                          disabled={true}
                                        />
                                      )
                                    }
                                    )
                                  }
                                </div>
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'file' &&
                              <MDBCol sm={12} key={index}>
                                <MDBFile
                                  label={field.fieldName}

                                  type='file'
                                  name={field.fieldCode}
                                  required={field.fieldRequired == '1'}
                                  multiple={field.fieldMultiple == '1'}
                                  accept={field.fieldAccept}
                                  size={'lg'}
                                  className={'my-2'}
                                  onChange={(e) => {
                                    handleFieldValueChange(field.fieldCode, e.target.files)
                                  }
                                  }
                                  disabled={true}
                                />
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'email' &&
                              <MDBCol sm={12} className='my-4 px-4'>
                                <MDBInput
                                  label={field.fieldName}
                                  type='email'

                                  name={field.fieldCode}
                                  required={field.fieldRequired == '1'}
                                  className={'my-2'}
                                  onChange={(e) => {
                                    handleFieldValueChange(field.fieldCode, e.target.value)
                                  }
                                  }
                                  value={
                                    formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                  }
                                  disabled={true}
                                />
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'url' &&
                              <MDBCol sm={12} key={index}>
                                <MDBInput
                                  label={field.fieldName}
                                  type='url'

                                  name={field.fieldCode}
                                  required={field.fieldRequired == '1'}
                                  className={'my-2'}
                                  onChange={(e) => {
                                    handleFieldValueChange(field.fieldCode, e.target.value)
                                  }
                                  }
                                  value={
                                    formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                  }
                                  disabled={true}
                                />
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'password' &&
                              <MDBCol sm={12} key={index}>
                                <MDBInput
                                  label={field.fieldName}
                                  type='password'

                                  name={field.fieldCode}
                                  required={field.fieldRequired == '1'}
                                  className={'my-2'}
                                  onChange={(e) => {
                                    handleFieldValueChange(field.fieldCode, e.target.value)
                                  }
                                  }
                                  value={
                                    formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                  }
                                  disabled={true}
                                />
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'hidden' &&
                              <MDBCol lg={12} md={12} sm={12} key={index}>
                                <p>
                                  {field.fieldName}
                                </p>
                                <div className={'d-none'}>
                                  <div>
                                    <input
                                      type='hidden'
                                      className='d-none'
                                      name={field.fieldCode}
                                      value={field.fieldValue || ''}
                                      disabled={true}
                                    />
                                  </div>
                                  <p>
                                    &#60;
                                    {field.fieldValue}
                                    &#62;
                                  </p>
                                </div>
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'title' &&
                              <MDBCol lg={12} md={12} sm={12} className='my-4 px-4'>
                                <h4 className={'my-2'}>{field.fieldStatement}</h4>
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'statement' &&
                              <MDBCol lg={12} md={12} sm={12} className='my-4 px-4'>
                                <p className={'my-2'}>{field.fieldStatement}</p>
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'address_dropdown' &&
                              <MDBCol lg={12} md={12} sm={12} className='my-4 px-4'>
                                <MDBRow className={'my-2'}>
                                  <MDBCol size={6}>
                                    <MDBSelect
                                      label={'Región de ' + field.fieldName}
                                      name={field.fieldCode + '_state'}
                                      data={
                                        stateList.map((state, stateIndex) => {
                                          return {
                                            text: state.stateName,
                                            value: state.id,
                                            defaultSelected: state.id == formAnswers?.find((answer) => answer.fieldId == field.fieldCode + '_state')?.fieldValue || false
                                          }
                                        })
                                      }
                                      onValueChange={
                                        (e) => {
                                          handleLoadCities(e.value)
                                          handleFieldValueChange(field.fieldCode + '_state', e.value)
                                        }
                                      }
                                      search
                                      searchLabel='Buscar región'
                                      disabled={true}
                                    />
                                  </MDBCol>
                                  <MDBCol size={6}>
                                    <MDBSelect
                                      label={'Comuna de ' + field.fieldName}
                                      name={field.fieldCode + '_city'}
                                      data={
                                        cityList.map((city, cityIndex) => {
                                          return {
                                            text: city.cityName,
                                            value: city.id,
                                            defaultSelected: city.id == formAnswers?.find((answer) => answer.fieldId == field.fieldCode + '_city')?.fieldValue || false
                                          }
                                        })
                                      }
                                      onValueChange={
                                        (e) => {
                                          handleFieldValueChange(field.fieldCode + '_city', e.value)
                                        }
                                      }
                                      search
                                      searchLabel='Buscar comuna'
                                      disabled={true}
                                    />
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                            }
                            {
                              (['select', 'radio', 'checkbox'].includes(field.fieldType)) &&
                              <MDBRow>
                                <MDBCol className='text-end'>
                                  <p>
                                    Puntaje:
                                  </p>
                                  <h2>
                                    {
                                      /* Get answer score */
                                      answerScore?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || 0
                                    }
                                  </h2>
                                </MDBCol>
                              </MDBRow>
                            }
                            <hr className='mt-4' />
                          </Fragment>
                        )
                      }
                      )
                    }
                    <MDBCol className='text-end'>
                      <p>
                        Puntaje total:
                      </p>
                      <h2>
                        {
                          /* Get total score */
                          answerScore?.reduce((total, answer) => total + parseInt(answer.fieldValue), 0) || 0
                        }
                      </h2>
                    </MDBCol>
                  </MDBRow>
                </form>
              </>
            )
          }
          <MDBRow className='my-4 ms-4'>
            <MDBCol className='d-flex justify-content-end me-4'>
              <MDBBtn outline color='primary' className='ms-4' onClick={() =>
                navigateTo(goBackRoute)
              }>
                Volver
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBAnimation>
      <MDBModal tabIndex='-1'
        show={modalShow}
        setShow={setModalShow}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                {modalHandler.title}
              </MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={
                () => setModalShow(false)
              }></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {modalHandler.message}
            </MDBModalBody>
            <MDBModalFooter>
              {modalHandler.buttons}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  )
}
