import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import {
    MDBAnimation,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBTextArea,
    MDBSelect,
    MDBCheckbox,
    MDBRadio,
    MDBFile
} from 'mdb-react-ui-kit'
import formService from '../../services/forms'
import stateService from '../../services/states'
import cityService from '../../services/cities'

import encryption from '../../services/encryption'
import SiteHeader from '../../components/SiteHeader'
import Breadcrumbs from '../../components/Breadcrumbs'

export default function Index() {

    const navigateTo = useNavigate();
    const [selectedProfile, setSelectedProfile] = useState(null);
    const { version_id, id } = useParams()
    const [formData, setFormData] = useState(null)
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])

    const handleLoadForm = async () => {
        let data = await formService.get(id)

        let fields = data.fields;

        fields.map((field, index) => {
            if (field.fieldType == 'select') {
                let options = field.fieldOptions.split(',');
                let dataArray = [];
                options.map((option, optionIndex) => {
                    let valueArray = field.fieldValues.split(',');
                    let scoreArray = field.fieldScores.split(',');
                    let optionData = {
                        text: options[optionIndex],
                        value: valueArray[optionIndex],
                        score: scoreArray[optionIndex]
                    }
                    dataArray.push(optionData)
                })
                field.dataArray = dataArray;
            }
        })

        data.fields = fields;

        console.log(data)

        setFormData(data)
    }

    const handleSelectedProfile = () => {
        let token = localStorage.getItem('selectedProfile');
        if (token) {
            let descryptedToken = encryption.decrypt(token);
            setSelectedProfile(descryptedToken);
        }
    }

    const handleLoadStates = async () => {
        let data = await stateService.list()
        setStateList(data)
    }

    const handleLoadCities = async (stateId) => {
        let data = await cityService.listByState(stateId)
        setCityList(data)
    }

    const breadCrumbsData = [
        {
            text: 'Inicio',
            href: `/home`
        },
        {
            text: 'Formularios',
            href: `/forms/${version_id}`
        },
        {
            text: `${formData?.formName}`,
        },
    ];

    useEffect(() => {
        handleSelectedProfile()
        handleLoadStates()
    }, [])

    useEffect(() => {
        if (stateList.length > 0 && cityList.length == 0) {
            handleLoadCities(stateList[0].id)
        }
    }, [stateList])

    useEffect(() => {
        if (selectedProfile && cityList.length > 0) {
            handleLoadForm()
        }
    }, [selectedProfile, cityList])

    useEffect(() => {
        
    }, [formData?.fields])

    return (
        <>
            <SiteHeader
                selectedProfile={selectedProfile}
            />
            <MDBAnimation
                animation='fade-in'
                start='onLoad'
                duration={700}
            >
                <MDBRow className='my-4 ms-4'>
                    <Breadcrumbs
                        items={breadCrumbsData}
                    />
                </MDBRow>
                <MDBContainer fluid>
                    <MDBRow className='my-4 ms-4'>
                        <h4>Formulario {formData?.formName}</h4>
                    </MDBRow>
                    <MDBRow className='my-4 ms-4'>
                        <h5>
                            Campos
                        </h5>
                    </MDBRow>
                    <MDBRow className='my-4 ms-4'>
                        {
                            formData?.fields?.map((field, index) => {
                                console.log(field);
                                return (
                                    <MDBRow key={index} >
                                        <MDBCol size={3}>
                                            {field.fieldName}
                                        </MDBCol>
                                        <MDBCol size={9}>
                                            {
                                                field.fieldType == 'text' &&
                                                <MDBInput
                                                    label={field.fieldName}
                                                    type='text'
                                                    id={field.fieldCode}
                                                    name={field.fieldCode}
                                                    required={field.fieldRequired == '1'}
                                                    className={'my-2'}
                                                />
                                            }
                                            {
                                                field.fieldType == 'textarea' &&
                                                <MDBTextArea
                                                    label={field.fieldName}
                                                    id={field.fieldCode}
                                                    name={field.fieldCode}
                                                    required={field.fieldRequired == '1'}
                                                    rows={field.fieldRows}
                                                    className={'my-2'}
                                                />
                                            }
                                            {
                                                field.fieldType == 'number' &&
                                                <MDBInput
                                                    label={field.fieldName}
                                                    type='number'
                                                    id={field.fieldCode}
                                                    name={field.fieldCode}
                                                    required={field.fieldRequired == '1'}
                                                    kpi={field.fieldIsKPI == '1' ? 'true' : 'false'}
                                                    min={field.fieldMin}
                                                    max={field.fieldMax}
                                                    step={field.fieldStep}
                                                    className={'my-2'}
                                                />
                                            }
                                            {
                                                field.fieldType == 'date' &&
                                                <MDBInput
                                                    label={field.fieldName}
                                                    type='date'
                                                    id={field.fieldCode}
                                                    name={field.fieldCode}
                                                    required={field.fieldRequired == '1'}
                                                    min={field.fieldMin}
                                                    max={field.fieldMax}
                                                    className={'my-2'}
                                                />
                                            }
                                            {
                                                field.fieldType == 'time' &&
                                                <MDBInput
                                                    label={field.fieldName}
                                                    type='time'
                                                    id={field.fieldCode}
                                                    name={field.fieldCode}
                                                    required={field.fieldRequired == '1'}
                                                    min={field.fieldMin}
                                                    max={field.fieldMax}
                                                    className={'my-2'}
                                                />
                                            }
                                            {
                                                field.fieldType == 'datetime' &&
                                                <MDBInput
                                                    label={field.fieldName}
                                                    type='datetime-local'
                                                    id={field.fieldCode}
                                                    name={field.fieldCode}
                                                    required={field.fieldRequired == '1'}
                                                    min={field.fieldMin}
                                                    max={field.fieldMax}
                                                    className={'my-2'}
                                                />
                                            }
                                            {
                                                field.fieldType == 'select' && <MDBSelect
                                                    label={field.fieldName}
                                                    id={field.fieldCode}
                                                    name={field.fieldCode}
                                                    required={field.fieldRequired == '1'}
                                                    data={field.dataArray}
                                                    multiple={field.fieldMultiple == '1'}
                                                    selectAllLabel='Todos'
                                                    className={'my-2'}
                                                />
                                            }
                                            {
                                                field.fieldType == 'checkbox' && <div className={'my-2'}>
                                                    {
                                                        field.fieldOptions.split(',').map((option, optionIndex) => {
                                                            return (
                                                                <MDBCheckbox
                                                                    key={optionIndex}
                                                                    label={option}
                                                                    id={field.fieldCode}
                                                                    name={field.fieldCode}
                                                                    required={field.fieldRequired == '1'}
                                                                    value={field.fieldValues.split(',')[optionIndex]}
                                                                    score={field.fieldScores.split(',')[optionIndex]}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                            {
                                                field.fieldType == 'radio' && <div className={'my-2'}>
                                                    {
                                                        field.fieldOptions.split(',').map((option, optionIndex) => {
                                                            return (
                                                                <MDBRadio
                                                                    key={optionIndex}
                                                                    label={option}
                                                                    id={field.fieldCode}
                                                                    name={field.fieldCode}
                                                                    required={field.fieldRequired == '1'}
                                                                    value={field.fieldValues.split(',')[optionIndex]}
                                                                    score={field.fieldScores.split(',')[optionIndex]}
                                                                />
                                                            )
                                                        }
                                                        )
                                                    }
                                                </div>
                                            }
                                            {
                                                field.fieldType == 'file' && <>
                                                    <MDBFile
                                                        label={field.fieldName}
                                                        id={field.fieldCode}
                                                        type='file'
                                                        name={field.fieldCode}
                                                        required={field.fieldRequired == '1'}
                                                        multiple={field.fieldMultiple == '1'}
                                                        accept={field.fieldAccept}
                                                        size={'lg'}
                                                        className={'my-2'}
                                                    />
                                                </>
                                            }
                                            {
                                                field.fieldType == 'email' && <>
                                                    <MDBInput
                                                        label={field.fieldName}
                                                        type='email'
                                                        id={field.fieldCode}
                                                        name={field.fieldCode}
                                                        required={field.fieldRequired == '1'}
                                                        className={'my-2'}
                                                    />
                                                </>
                                            }
                                            {
                                                field.fieldType == 'url' && <>
                                                    <MDBInput
                                                        label={field.fieldName}
                                                        type='url'
                                                        id={field.fieldCode}
                                                        name={field.fieldCode}
                                                        required={field.fieldRequired == '1'}
                                                        className={'my-2'}
                                                    />
                                                </>
                                            }
                                            {
                                                field.fieldType == 'password' && <>
                                                    <MDBInput
                                                        label={field.fieldName}
                                                        type='password'
                                                        id={field.fieldCode}
                                                        name={field.fieldCode}
                                                        required={field.fieldRequired == '1'}
                                                        className={'my-2'}
                                                    />
                                                </>
                                            }
                                            {
                                                field.fieldType == 'hidden' && <div className={'d-none'}>
                                                    <div>
                                                        <input
                                                            type='hidden'
                                                            className='d-none'
                                                            id={field.fieldCode}
                                                            name={field.fieldCode}
                                                            value={field.fieldValue}
                                                        />
                                                    </div>
                                                    <p>
                                                        &#60;
                                                        {field.fieldValue}
                                                        &#62;
                                                    </p>
                                                </div>
                                            }
                                            {
                                                field.fieldType == 'title' && <>
                                                    <h4 className={'my-2'}>{field.fieldStatement}</h4>
                                                </>
                                            }
                                            {
                                                field.fieldType == 'statement' && <>
                                                    <p className={'my-2'}>{field.fieldStatement}</p>
                                                </>
                                            }
                                            {
                                                field.fieldType == 'address_dropdown' && <>
                                                    <MDBRow className={'my-2'}>
                                                        <MDBCol size={6}>
                                                            <MDBSelect
                                                                label={'Región de ' + field.fieldName}
                                                                id={field.fieldCode + '_state'}
                                                                name={field.fieldCode + '_state'}
                                                                data={
                                                                    stateList.map((state, stateIndex) => {
                                                                        return {
                                                                            text: state.stateName,
                                                                            value: state.id
                                                                        }
                                                                    })
                                                                }
                                                                onValueChange={
                                                                    (e) => {
                                                                        handleLoadCities(e.value)
                                                                    }
                                                                }
                                                            />
                                                        </MDBCol>
                                                        <MDBCol size={6}>
                                                            <MDBSelect
                                                                label={'Comuna de ' + field.fieldName}
                                                                id={field.fieldCode + '_city'}
                                                                name={field.fieldCode + '_city'}
                                                                data={
                                                                    cityList.map((city, cityIndex) => {
                                                                        return {
                                                                            text: city.cityName,
                                                                            value: city.id
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        </MDBCol>
                                                    </MDBRow>
                                                </>
                                            }
                                        </MDBCol>
                                    </MDBRow>
                                )
                            })
                        }
                    </MDBRow>
                </MDBContainer>
            </MDBAnimation>

        </>
    )
}
