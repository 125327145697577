import React, { useState, useEffect } from 'react';
import {
    MDBInput,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBContainer,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter

} from 'mdb-react-ui-kit';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import auth from '../services/authentication'
import encryption from '../services/encryption'
import userService from '../services/users'
import LOGO from '../assets/img/logo-color.png';

export default function MyProfile() {

    let credentials = localStorage.getItem('token');
    let descryptedToken = encryption.decrypt(credentials);

    const [loading, setLoading] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [userData, setUserData] = useState({
        userName: '',
        userEmail: ''
    });

    const [formValue, setFormValue] = useState({
        userName: '',
        userEmail: '',
        userPassword: '',
    });

    const navigateTo = useNavigate();

    const onChange = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const toggleShow = () => {
        setDisplayModal(!displayModal);
    };

    const handleUserDataLoad = async () => {

        setLoading(true);
        try {

            const userDataResult = await userService.get(descryptedToken.id);
            if (!userDataResult) {
                throw new Error('No se pudo cargar la información del usuario');
            }
            if (userDataResult) {
                setUserData({
                    userName: userDataResult.userName,
                    userEmail: userDataResult.userEmail
                });
                setFormValue({
                    userName: userDataResult.userName,
                    userEmail: userDataResult.userEmail,
                    userPassword: ''
                });
            } else {
                console.log(result)
                throw new Error('No se pudo cargar la información del usuario');
            }
            setLoading(false);
        } catch (error) {
            document.getElementById('modalMessage').innerHTML = error;
            toggleShow();
            //after 2000 miliseconds, redirect to sign-in page
            setTimeout(() => {
                setLoading(false);
                //navigateTo('/sign-in');
            }, 5000);
        }
    }

    useEffect(() => {

        handleUserDataLoad()
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {

            if (formValue.userName === '' || formValue.userEmail === '') {
                throw new Error('Debe ingresar todos los datos');
            }

            let newInput = {};

            if (formValue.userPassword === '') {
                newInput = {
                    userName: formValue.userName,
                    userEmail: userData.userEmail
                };
            } else {
                newInput = {
                    userName: formValue.userName,
                    userEmail: userData.userEmail,
                    userPassword: formValue.userPassword
                };
            }

            const result = await userService.update(descryptedToken.id, newInput);

            if (!result) {
                throw new Error('No se pudo actualizar la información del usuario');
            }

            if (result) {
                document.getElementById('modalMessage').innerHTML = 'Información actualizada correctamente';
                toggleShow();
                setTimeout(() => {
                    setLoading(false);
                    //navigateTo('/my-profile');
                }, 2000);
            } else {
                throw new Error('No se pudo actualizar la información del usuario');
            }
        } catch (error) {

            document.getElementById('modalMessage').innerHTML = error;
            toggleShow();
            setTimeout(() => {
                setLoading(false);
                //navigateTo('/sign-in');
            }, 2000);
        }
    };

    return (

        <MDBContainer className='vh-100'>
            <MDBModal tabIndex='-1' show={displayModal} setShow={setDisplayModal}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Aviso</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p id='modalMessage'>
                            </p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleShow}>
                                Ok
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                {
                    loading ? (
                        <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>Cargando...</span>
                        </div>
                    ) : (
                        <MDBCol lg={'6'} md={'8'}>
                            <MDBRow className=' text-center'>
                                <MDBCol
                                    size="12"
                                    className='my-4'
                                >
                                    <img src={LOGO} alt='Logo' className='img-fluid mx-auto w-75' />
                                    <hr />
                                    <h1 className='text-center'>Mi Perfil</h1>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow tag="form" className='d-flex justify-content-center align-items-center'>
                                <MDBCol
                                    size="12"
                                    className='my-2'
                                >
                                    Hola, {userData.userName}. Acá puedes cambiar tu perfil de usuario/a.
                                </MDBCol>
                                <MDBCol
                                    size="12"
                                    className='my-2'
                                >
                                    <MDBInput
                                        value={formValue.userName}
                                        name='userName'
                                        onChange={onChange}
                                        required
                                        type='text'
                                        label='Nombre de usuario'
                                        className='mb-3'
                                    />
                                    <MDBInput
                                        value={formValue.userEmail}
                                        name='userEmail'
                                        onChange={onChange}
                                        required
                                        type='text'
                                        label='Correo electrónico'
                                        className='mb-3'
                                        disabled
                                    />
                                    <MDBInput
                                        value={formValue.userPassword}
                                        name='userPassword'
                                        onChange={onChange}
                                        required
                                        type='password'
                                        label='Contraseña nueva (dejar en blanco para mantener)'
                                        className='mb-3'
                                    />
                                </MDBCol>
                                <MDBCol size="12" className='text-end my-2'>
                                    <MDBBtn
                                        type='button'
                                        className='me-2'
                                        color='primary'
                                        outline
                                        onClick={
                                            (e) => {
                                                e.preventDefault();
                                                navigateTo(-2);
                                            }
                                        }
                                    >Ir al inicio
                                    </MDBBtn>
                                    <MDBBtn
                                        type='submit'
                                        onClick={
                                            (e) => onSubmit(e)
                                        }
                                    >Confirmar
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    )
                }
            </MDBRow>

        </MDBContainer>

    );
}