import APIDriver from './api/APIDriver';

const api = new APIDriver('answers');

const service = {
    listByVersion: (id) => {
        api.setBearerProtection();
        return api.get('version', id);
    },
    listByForm: (id) => {
        api.setBearerProtection();
        return api.get('form', id);
    },
    listByField: (id) => {
        api.setBearerProtection();
        return api.get('field', id);
    },
    listByActivity: (id) => {
        api.setBearerProtection();
        return api.get('activity', id);
    },
    get: (id) => {
        api.setBearerProtection();
        return api.get('', id);
    },
    list: () => {
        api.setBearerProtection();
        return api.getAll('');
    },
    answerActivity: (id, data) => {
        api.setBearerProtection();
        return api.post(`answer-activity/${id}`, data);
    },
    create: (data) => {
        api.setBearerProtection();
        return api.post('', data);
    },
    update: (id, data) => {
        api.setBearerProtection();
        return api.patch('', id, data);
    },
    remove: (id) => {
        api.setBearerProtection();
        return api.delete('', id);
    }
}

export default service;