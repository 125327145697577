import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SiteHeader from '../components/SiteHeader'
import Breadcrumbs from '../components/Breadcrumbs'
import {
    MDBAnimation,
    MDBContainer,
    MDBRow,
    MDBDatatable,
    MDBBtn,
    MDBIcon,
    MDBCol,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBSelect,
} from 'mdb-react-ui-kit'
import encryption from '../services/encryption'
import roleService from '../services/roles'
import permissionService from '../services/permissions'

export default function Permissions() {

    const moduleName = 'permission';
    const navigateTo = useNavigate();
    const [selectedProfile, setSelectedProfile] = useState(null);

    const [modalAddShow, setModalAddShow] = useState(false);
    const [modalEditShow, setModalEditShow] = useState(false);
    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    const [entryId, setEntryId] = useState(null);
    const [state, setState] = useState({
        role_id: 1,
        permissionModule: 'versions',
        permissionAbility: 'list',
    });

    const [roleData, setRoleData] = useState([]);
    const [moduleData, setModuleData] = useState([]);
    const [abilityData, setAbilityData] = useState([]);

    const breadCrumbsData = [
        {
            text: 'Inicio',
            href: `/home`
        },
        {
            text: 'Permisos',
        }
    ];

    const loadRoleData = async () => {
        let roleList = await roleService.list()
            .then((response) => {
                return response.map((item) => {
                    return {
                        value: item.id,
                        text: item.roleName
                    }
                })
            })

        setRoleData([
            ...roleList
        ]);
    }

    const loadModuleData = async () => {
        setModuleData([
            {
                value: 'versions',
                text: 'Versiones'
            },
            {
                value: 'institutions',
                text: 'Establecimientos'
            },
            {
                value: 'activityTypes',
                text: 'Tipos de Actividad'
            },
            {
                value: 'activities',
                text: 'Actividades'
            },
            {
                value: 'forms',
                text: 'Formularios'
            },
            {
                value: 'users',
                text: 'Usuarios'
            }
        ]);
    }

    const loadAbilityData = async () => {
        setAbilityData([
            {
                value: 'create',
                text: 'Crear'
            },
            {
                value: 'read',
                text: 'Leer'
            },
            {
                value: 'update',
                text: 'Actualizar'
            },
            {
                value: 'delete',
                text: 'Eliminar'
            },
            {
                value: 'list',
                text: 'Listar'
            },
            {
                value: 'internal',
                text: 'Interno'
            }
        ]);
    }

    const handleSelectedProfile = () => {
        let token = localStorage.getItem('selectedProfile');
        if (token) {
            let descryptedToken = encryption.decrypt(token);
            setSelectedProfile(descryptedToken);
        }
    }

    const toggleAddModal = () => {
        if (modalAddShow === false) {
            setState({
                role_id: 1,
                permissionModule: 'versions',
                permissionAbility: 'list',
            });
        }
        setModalAddShow(!modalAddShow);
    }

    const toggleEditModal = (newState) => {
        setModalEditShow(newState);
    }

    const toggleDeleteModal = (newState) => {
        setModalDeleteShow(newState);
    }

    const btnActionEdit = (item, id) => {
        let newState = { ...item };
        delete newState.id;
        setEntryId(id);
        setState(newState);

        let newModuleData = moduleData.map((item) => {
            if (item.value === newState.permissionModule) {
                return {
                    ...item,
                    defaultSelected: true
                }
            } else {
                return {
                    ...item,
                    defaultSelected: false
                }
            }
        });

        let newAbilityData = abilityData.map((item) => {
            if (item.value === newState.permissionAbility) {
                return {
                    ...item,
                    defaultSelected: true
                }
            } else {
                return {
                    ...item,
                    defaultSelected: false
                }
            }
        });

        let newRoleData = roleData.map((item) => {
            if (item.value === newState.role_id) {
                return {
                    ...item,
                    defaultSelected: true
                }
            } else {
                return {
                    ...item,
                    defaultSelected: false
                }
            }
        });

        setModuleData(newModuleData);
        setAbilityData(newAbilityData);
        setRoleData(newRoleData);

        toggleEditModal(true);
    }

    const btnActionDelete = (item, id) => {
        let newState = { ...item };
        delete newState.id;
        setEntryId(id);
        setState(newState);
        toggleDeleteModal(true);
    }

    const [asyncData, setAsyncData] = useState({
        columns: [
            {
                label: 'ID',
                field: 'id',
            },
            {
                label: 'Módulo',
                field: 'permissionModule',
            },
            {
                label: 'Capacidad',
                field: 'permissionAbility',
            },
            {
                label: 'Rol',
                field: 'role',
            },
            {
                label: 'Acciones',
                field: 'actions',
            },
        ],
        rows: []
    });

    const [dataLoading, setDataLoading] = useState(false);

    const handleDataLoad = async () => {
        const data = await permissionService.list()

        setAsyncData({
            columns: asyncData.columns,
            rows: data.map((item, index) => {
                return {
                    id: index + 1,
                    permissionModule: moduleData.filter(
                        (entry) => {
                            return entry.value === item.permissionModule
                        }
                    )[0]?.text ?? '',
                    permissionAbility: abilityData.filter(
                        (entry) => {
                            return entry.value === item.permissionAbility
                        }
                    )[0]?.text ?? '',
                    role: item.role.roleName,
                    actions: (
                        <>
                            {
                                selectedProfile?.roleName === 'Administrador' && (
                                    <>
                                        <MDBBtn color='info' size='lg' className='me-2' floating onClick={() => btnActionEdit(item, item.id)}>
                                            <MDBIcon icon='edit' />
                                        </MDBBtn>
                                        <MDBBtn color='danger' size='lg' className='me-2' floating onClick={() => btnActionDelete(item, item.id)}>
                                            <MDBIcon icon='trash' />
                                        </MDBBtn>
                                    </>
                                )
                            }
                        </>
                    )
                }
            })
        })
    }

    const handleCreate = async () => {
        await permissionService.create({
            role_id: state.role_id,
            permissionModule: state.permissionModule,
            permissionAbility: state.permissionAbility
        });
        handleDataLoad();
        toggleAddModal();
        setState({
            role_id: 1,
            permissionModule: '',
            permissionAbility: '',
        });
    }

    const handleUpdate = async () => {
        await permissionService.update(entryId,
            {
                role_id: state.role_id,
                permissionModule: state.permissionModule,
                permissionAbility: state.permissionAbility
            }
        );
        handleDataLoad();
        toggleEditModal(false);
    }

    const handleDelete = async () => {
        await permissionService.remove(entryId);
        handleDataLoad();
        setState({
            role_id: 1,
            permissionModule: '',
            permissionAbility: '',
        });
        toggleDeleteModal(false);
    }

    useEffect(() => {
        handleDataLoad();
    }, [dataLoading, asyncData.columns]);

    useEffect(() => {
        if (asyncData.rows.length === 0) {
            setDataLoading(true);
        } else {
            setDataLoading(false);
        }
    }, [asyncData.rows]);

    useEffect(() => {
        handleSelectedProfile();
    }, []);

    useEffect(() => {
        loadModuleData();
        loadAbilityData();
        loadRoleData();
    }, []);

    useEffect(() => {
        //console.log(roleData)
    }, [roleData]);

    return (
        <>
            <SiteHeader
                selectedProfile={selectedProfile}
            />
            <MDBAnimation
                animation='fade-in'
                start='onLoad'
                duration={700}
            >
                <MDBRow className='my-4 ms-4'>
                    <Breadcrumbs
                        items={breadCrumbsData}
                    />
                </MDBRow>
                <MDBContainer>
                    <MDBRow className='my-4 ms-4'>
                        <h4>Permisos</h4>
                    </MDBRow>
                    {
                        selectedProfile?.roleName === 'Administrador' && (
                            <MDBRow className='text-end'>
                                <MDBCol className='me-0 pe-0'>
                                    <MDBBtn color='primary' className='my-4' size='lg' floating onClick={() => {
                                        //set the first element as the default selected
                                        let newModuleData = moduleData.map((item) => {
                                            return {
                                                ...item,
                                                defaultSelected: false
                                            }
                                        });
                                        newModuleData[0].defaultSelected = true;
                                        let newAbilityData = abilityData.map((item) => {
                                            return {
                                                ...item,
                                                defaultSelected: false
                                            }
                                        });
                                        newAbilityData[0].defaultSelected = true;
                                        let newRoleData = roleData.map((item) => {
                                            return {
                                                ...item,
                                                defaultSelected: false
                                            }
                                        });
                                        setModuleData(newModuleData);
                                        setAbilityData(newAbilityData);
                                        setRoleData(newRoleData);
                                        setState({
                                            role_id: 1,
                                            permissionModule: 'versions',
                                            permissionAbility: 'list',
                                        });
                                        toggleAddModal();
                                    }}>
                                        <MDBIcon icon='plus' />
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        )
                    }
                    <MDBRow>
                        <MDBDatatable
                            data={asyncData}
                            rowsText='Filas'
                            ofText='de'
                            searchLabel='Buscar'
                            search
                            noFoundMessage='No se encontraron resultados'
                            isLoading={dataLoading}
                            searchInputProps={
                                {
                                    'className': 'pb-1',
                                }
                            }
                        />
                    </MDBRow>
                </MDBContainer>
            </MDBAnimation>

            <MDBModal tabIndex='-1' show={modalAddShow} setShow={setModalAddShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Agregar permiso</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleAddModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBSelect
                                label='Módulo'
                                data={moduleData}
                                onValueChange={
                                    (e) => {

                                        setState({
                                            ...state,
                                            permissionModule: e.value
                                        });
                                    }
                                }
                                className='mb-4'
                                search
                                searchLabel='Buscar'
                            />
                            <MDBSelect
                                label='Capacidad'
                                data={abilityData}
                                onValueChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            permissionAbility: e.value
                                        });
                                    }
                                }
                                className='mb-4'
                                search
                                searchLabel='Buscar'
                            />
                            <MDBSelect
                                label='Rol'
                                data={roleData}
                                onValueChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            role_id: e.value
                                        });
                                    }
                                }
                                className='mb-4'
                                search
                                searchLabel='Buscar'
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={toggleAddModal}>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                color='primary'
                                onClick={
                                    () => {
                                        handleCreate()
                                    }
                                }

                            >Insertar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1' show={modalEditShow} setShow={setModalEditShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Editar permiso</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleEditModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBSelect
                                label='Módulo'
                                data={moduleData}
                                onValueChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            permissionModule: e.value
                                        });
                                    }
                                }
                                className='mb-4'
                                search
                                searchLabel='Buscar'
                            />
                            <MDBSelect
                                label='Capacidad'
                                data={abilityData}
                                onValueChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            permissionAbility: e.value
                                        });
                                    }
                                }
                                className='mb-4'
                                search
                                searchLabel='Buscar'
                            />
                            <MDBSelect
                                label='Rol'
                                data={roleData}
                                onValueChange={
                                    (e) => {
                                        setState({
                                            ...state,
                                            role_id: e.value
                                        });
                                    }
                                }
                                className='mb-4'
                                search
                                searchLabel='Buscar'
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleEditModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    handleUpdate();
                                }}
                            >Actualizar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1' show={modalDeleteShow} setShow={setModalDeleteShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Eliminar permiso</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleDeleteModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>¿Está seguro que desea eliminar el permiso {state.permissionModule} / {state.permissionAbility}?</p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleDeleteModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    handleDelete();
                                }}
                            >Eliminar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}
