import APIDriver from './api/APIDriver';

const api = new APIDriver('version_institutions');

const service = {
    listByVersion: (id) => {
        api.setBearerProtection();
        return api.get('version', id);
    },
    listByInstitution: (id) => {
        api.setBearerProtection();
        return api.get('institution', id);
    },
    get: (id) => {
        api.setBearerProtection();
        return api.get('', id);
    },
    list: () => {
        api.setBearerProtection();
        return api.getAll('');
    },
    create: (data) => {
        api.setBearerProtection();
        return api.post('', data);
    },
    update: (id, data) => {
        api.setBearerProtection();
        return api.patch('', id, data);
    },
    remove: (id) => {
        api.setBearerProtection();
        return api.delete('', id);
    }
}

export default service;