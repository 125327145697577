import React from 'react';
import {
    createBrowserRouter
} from 'react-router-dom';

import App from '../App';
import ProfileSelector from '../pages/ProfileSelector';
import SignIn from '../pages/SignIn';
import AccountActivate from '../pages/AccountActivate';

import Home from '../pages/Home';
import Users from '../pages/Users';
import Versions from '../pages/Versions';
import Version from '../pages/Version/Index';
import Institutions from '../pages/Institutions';
import Institution from '../pages/Institution/Index';
import Permissions from '../pages/Permissions';
import ActivityTypes from '../pages/ActivityTypes';
import ActivityType from '../pages/ActivityType/Index';
import Forms from '../pages/Forms'
import Form from '../pages/Form/Index'
import Activities from '../pages/Activities';
import SelfEvaluation from '../pages/ActivityExecution/SelfEvaluation';
import GenericActivity from '../pages/ActivityExecution/GenericActivity';
import CheckSelfEvaluation from '../pages/ActivityDescription/SelfEvaluation';
import CheckGenericActivity from '../pages/ActivityDescription/GenericActivity';
import TreeSelfEvaluation from '../pages/ActivityTree/SelfEvaluation';
import Slots from '../pages/Slots';
import SlotCities from '../pages/SlotCities';
import PasswordRecoverRequest from '../pages/PasswordRecoverRequest';
import PasswordRecover from '../pages/PasswordRecover';
import ScheduleView from '../pages/ActivityCalendar/ScheduleView';
import NotFound from '../pages/ErrorNotFound';
import MyProfile from '../pages/MyProfile';

const SystemRouter = () => {

    let commonRoutes = [
        {
            path: "/",
            element: <App />,
        },
        {
            path: "/profile-selector",
            element: <ProfileSelector />
        },
        {
            path: "/sign-in",
            element: <SignIn />
        },
        {
            path: "/password-recover-request",
            element: <PasswordRecoverRequest />
        },
        {
            path: "/password-recovery/:user_id/:token",
            element: <PasswordRecover />
        },
        {
            path: "/account-activate/:token",
            element: <AccountActivate />
        },
        {
            path: "/my-profile",
            element: <MyProfile />
        }
    ];

    let profileRoutes = [
        {
            path: "/permissions",
            element: <Permissions />
        },
        {
            path: "/home",
            element: <Home />
        },
        {
            path: "/users",
            element: <Users />
        },
        {
            path: "/versions",
            element: <Versions />
        },
        {
            path: "/version/:id",
            element: <Version />
        },
        {
            path: "/institutions",
            element: <Institutions />
        },
        {
            path: "/institution/:id",
            element: <Institution />
        },
        {
            path: "/activity-types/:version_id",
            element: <ActivityTypes />
        },
        {
            path: "/activity-type/:id",
            element: <ActivityType />
        },
        {
            path: "/forms/:version_id",
            element: <Forms />
        },
        {
            path: "/form/:version_id/:id",
            element: <Form />
        },
        {
            path: "/activities/:version_id",
            element: <Activities />
        },
        {
            path: "/do/self-evaluation/:id",
            element: <SelfEvaluation/>
        },
        {
            path: "/check/self-evaluation/:id",
            element: <CheckSelfEvaluation />
        },
        {
            path: "/tree/self-evaluation/:id",
            element: <TreeSelfEvaluation />
        },
        {
            path: "/slots/:version_id",
            element: <Slots />
        },
        {
            path: "/slot-cities/:version_id/:state_id",
            element: <SlotCities />
        },
        {
            path: "/schedule-view",
            element: <ScheduleView />
        },
        {
            path: "/do/generic-activity/:id",
            element: <GenericActivity />
        },
        {
            path: "/check/generic-activity/:id",
            element: <CheckGenericActivity />
        },
    ];

    let routes = [
        ...commonRoutes,
        ...profileRoutes,
        {
            path: "*",
            element: <NotFound />
        }
    ];

    let router = createBrowserRouter(
        routes,
        {
            basename: "/",
        }
    );
    return router;
}

export default SystemRouter;