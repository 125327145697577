import React, { useEffect } from 'react'
import { MDBBreadcrumb, MDBBreadcrumbItem } from 'mdb-react-ui-kit'

export default function Breadcrumbs({ items }) {

    return (
        <MDBBreadcrumb>
            {
                items.map((item, index) => {
                    let isActive = index === items.length - 1;
                    return (
                        <MDBBreadcrumbItem
                            key={index}
                            active={isActive}
                        >
                            {
                                !isActive ? (
                                    <a
                                        href={item.href}
                                    >
                                        {item.text}
                                    </a>
                                ) : (
                                    <span>
                                        {item.text}
                                    </span>
                                )
                            }

                        </MDBBreadcrumbItem>
                    )
                })
            }
        </MDBBreadcrumb>

    )
}
