import APIDriver from './api/APIDriver';

const api = new APIDriver('users');

const service = {
    getWithToken: (token) => { 
        api.unsetBearerProtection();
        return api.get('account-activation-validation', token);
    },
    updatePasswordOnActivate: (id, data) => {
        api.unsetBearerProtection();
        return api.patch('update-password-activate', id, data);
    },
    updatePasswordOnReset: (data) => {
        api.unsetBearerProtection();
        return api.post('update-password-reset', data);
    },
    get: (id) => {
        api.setBearerProtection();
        return api.get('', id);
    },
    list: () => {
        api.setBearerProtection();
        return api.getAll('');
    },
    create: (data) => {
        api.setBearerProtection();
        //data.userAccountStatus = 'creada';
        return api.post('', data);
    },
    update: (id, data) => {
        api.setBearerProtection();
        return api.patch('', id, data);
    },
    remove: (id) => {
        api.setBearerProtection();
        return api.delete('', id);
    }
}

export default service;