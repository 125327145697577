import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import { MDBAnimation, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit'
import institutionService from '../../services/institutions'
import activityService from '../../services/activities'
import encryption from '../../services/encryption'
import SiteHeader from '../../components/SiteHeader'
import Breadcrumbs from '../../components/Breadcrumbs'

export default function Index() {

    const navigateTo = useNavigate();
    const [selectedProfile, setSelectedProfile] = useState(null);
    const { id } = useParams()
    const [institutionData, setInstitutionData] = useState({})
    const [activitySelfDiagnosticData, setActivitySelfDiagnosticData] = useState([])

    const handleLoadInstitution = async () => {
        let data = await institutionService.get(id)
        setInstitutionData(data)
    }

    const handleSelectedProfile = () => {
        let token = localStorage.getItem('selectedProfile');
        console.log(token)
        if (token) {
            let descryptedToken = encryption.decrypt(token);
            setSelectedProfile(descryptedToken);
        }
    }

    const handleLoadActivitySelfDiagnostic = async () => {
        let data = await activityService.listByInstitution(id)
        setActivitySelfDiagnosticData(data)
    }

    const breadCrumbsData = [
        {
            text: 'Inicio',
            href: `/home`
        },
        {
            text: 'Establecimientos',
            href: `/institutions`
        },
        {
            text: `${institutionData.institutionName}`,
        },
    ];


    useEffect(() => {
        handleSelectedProfile()
    }, [])

    useEffect(() => {
        if (!selectedProfile) return;
        handleLoadInstitution()
    }, [selectedProfile])

    useEffect(() => {
        if (!institutionData) return;
        if (!institutionData?.id) return;
        handleLoadActivitySelfDiagnostic()
    }, [institutionData])

    useEffect(() => {
        if (!activitySelfDiagnosticData) return;
        if (!activitySelfDiagnosticData?.length) return;
        if (!activitySelfDiagnosticData.some(
            activity =>
                ['creada', 'en proceso', 'finalizada'].includes(activity.activityStatus)

        )) return;
        //get the first activity that is in created or in process status

        let activity = activitySelfDiagnosticData.find(
            activity =>
                ['creada', 'en proceso', 'finalizada'].includes(activity.activityStatus)
        );

        setTimeout(() => {
            navigateTo(`/do/self-evaluation/${activity.id}`)
        }, 500);

    }, [activitySelfDiagnosticData])

    return (
        <>
            <SiteHeader
                selectedProfile={selectedProfile}
            />
            <MDBAnimation
                animation='fade-in'
                start='onLoad'
                duration={700}
            >
                <MDBRow className='my-4 ms-4'>
                    <Breadcrumbs
                        items={breadCrumbsData}
                    />
                </MDBRow>
                <MDBContainer fluid>
                    <MDBRow className='my-4 ms-4'>
                        <h4>Establecimiento {institutionData.institutionName}</h4>
                    </MDBRow>
                    <MDBRow className='my-4 ms-4'>
                        <MDBCol>
                            ...Cargando...
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBAnimation>

        </>
    )
}
