import React, { Fragment, useEffect, useRef, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  MDBAnimation,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBTextArea,
  MDBSelect,
  MDBCheckbox,
  MDBRadio,
  MDBFile,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBCard,
  MDBCardBody,
} from 'mdb-react-ui-kit'
import authService from '../../services/authentication'
import formService from '../../services/forms'
import activityService from '../../services/activities'
import stateService from '../../services/states'
import cityService from '../../services/cities'
import answerService from '../../services/answers'
import encryption from '../../services/encryption'
import files from '../../services/files'
import SiteHeader from '../../components/SiteHeader'
import Breadcrumbs from '../../components/Breadcrumbs'
import moment from 'moment'

export default function GenericActivity() {
  const navigateTo = useNavigate();
  const [selectedProfile, setSelectedProfile] = useState(null);
  const { id } = useParams()
  const [formData, setFormData] = useState({})
  const [formAnswers, setFormAnswers] = useState([])
  const [formAnswersOnLoad, setFormAnswersOnLoad] = useState([])
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [navButtons, setNavButtons] = useState(false)
  const [activityData, setActivityData] = useState()
  const [firstTimeLoad, setFirstTimeLoad] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [modalHandler, setModalHandler] = useState({
    title: '',
    message: '',
    buttons: ''
  })
  const formRef = useRef()

  const handleLoadActivity = async () => {
    const activityDataObject = await activityService.get(id);
    if (!activityDataObject?.activityType?.form_id) {
      alert("No se ha asignado un formulario a esta actividad");
      authService.sign_out();
      navigateTo('/sign-in');
    }
    if (activityDataObject.length == 0) {
      alert("No se ha encontrado la actividad");
      authService.sign_out();
      navigateTo('/sign-in');
    }
    setActivityData(activityDataObject)
  }
  const handleLoadForm = async () => {
    let data = await formService.get(activityData.activityType.form_id);
    let fields = data.fields;
    fields.map((field, index) => {
      if (field.fieldType == 'select') {
        let options = field.fieldOptions.split(',');
        let dataArray = [];
        options.map((option, optionIndex) => {
          let valueArray = field.fieldValues.split(',');
          let scoreArray = field.fieldScores.split(',');
          let optionData = {
            text: options[optionIndex],
            value: valueArray[optionIndex],
            score: scoreArray[optionIndex]
          }
          dataArray.push(optionData)
        })
        field.dataArray = dataArray;
      }
    })
    data.fields = fields;
    setFormData(data)
  }
  const handleSelectedProfile = () => {
    let token = localStorage.getItem('selectedProfile');
    if (token) {
      let descryptedToken = encryption.decrypt(token);
      setSelectedProfile(descryptedToken);
    }
  }
  const handleLoadStates = async () => {
    let data = await stateService.list()
    setStateList(data)
  }
  const handleLoadCities = async (stateId) => {
    if (!stateId) return setCityList([])
    let data = await cityService.listByState(stateId)
    setCityList(data)
  }
  const breadCrumbsData = [
    {
      text: 'Inicio',
      href: `/home`
    },
    {
      text: `${formData?.formName}`,
    },
  ];
  const handleAnswerOnLoad = async (activityId) => {
    const data = await answerService.listByActivity(activityId);
    setFormAnswersOnLoad(
      data.map((answer) => {
        if (answer.field.fieldType == 'select' && answer.field.fieldMultiple == 1 || answer.field.fieldType == 'checkbox' || answer.field.fieldType == 'radio') {
          return {
            fieldId: answer.field.fieldCode,
            fieldValue: answer.answerValue.split(',').length > 0 ? answer.answerValue.split(',') : []
          }
        } else {
          let newAnswer = {
            fieldId: answer.field.fieldCode,
            fieldValue: answer.answerValue
          }

          if (answer.field.fieldType == 'file') {
            newAnswer.fieldContent = answer.field.file
          }

          return newAnswer
        }
      })
    )
  }
  const handleAnswerLoad = async (activityId) => {
    const data = await answerService.listByActivity(activityId);
    let newData = data.map((answer) => {
      if (answer.field.fieldType == 'select' && answer.field.fieldMultiple == 1 || answer.field.fieldType == 'checkbox') {
        return {
          fieldId: answer.field.fieldCode,
          fieldValue: answer.answerValue.split(',').length > 1 ? answer.answerValue.split(',') : []
        }
      }
      else

        if (answer.field.fieldType == 'address_dropdown') {
          let stateValue = answer.answerValue.split(',')[0];
          let cityValue = answer.answerValue.split(',')[1];
          return {
            fieldId: answer.field.fieldCode,
            fieldValue: stateValue + ',' + cityValue
          }
        }
        else {
          let newAnswer = {
            fieldId: answer.field.fieldCode,
            fieldValue: answer.answerValue
          }
          if (answer.field.fieldType == 'file') {
            newAnswer.fieldContent = answer.field.file
          }
          return newAnswer
        }
    })

    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.field.fieldType == 'address_dropdown') {
        let stateValue = item.answerValue.split(',')[0];
        let cityValue = item.answerValue.split(',')[1];
        handleLoadCities(stateValue);
        newData.push(
          {
            fieldId: item.field.fieldCode + '_state',
            fieldValue: stateValue
          }
        )
        newData.push(
          {
            fieldId: item.field.fieldCode + '_city',
            fieldValue: cityValue
          }
        )
      }
    }

    setFormAnswers(newData)
  }

  const closeModal = () => {
    setModalHandler({
      title: '',
      message: '',
      buttons: ''
    })
    setModalShow(false)
  }

  const handleNextStatus = async (newStatus) => {

    //check all formData.fields and rescue answers
    let newAnswerData = [];
    let errorInField = false;

    formData.fields.map(async (field, index) => {
      let fieldIndex = formAnswers?.findIndex((answer) => answer.fieldId == field.fieldCode);
      let answerValue;
      if (fieldIndex > -1) {
        if (field.fieldType == 'select' && field.fieldMultiple == 1 || field.fieldType == 'checkbox') {
          answerValue = formAnswers[fieldIndex].fieldValue.join(',')
        } else {
          if (field.fieldType == 'select') {
            t = document.querySelector('div[name="' + field.fieldCode + '"]')
            let selectedText = t?.querySelector('input')?.value || ''
            //search the value inside the field.dataArray array of objects which text is equal to selectedText
            let selectedValue = field.dataArray.find((option) => option.text == selectedText)?.value || ''
            console.log(selectedValue)
            answerValue = selectedValue
          } else {
            answerValue = ''
          }
          if (field.fieldType == 'address_dropdown') {
            //two fields: field.fieldCode + '_state' and field.fieldCode + '_city'
            let stateFieldIndex = formAnswers?.findIndex((answer) => answer.fieldId == field.fieldCode + '_state');
            let cityFieldIndex = formAnswers?.findIndex((answer) => answer.fieldId == field.fieldCode + '_city');
            answerValue = formAnswers[stateFieldIndex].fieldValue + ',' + formAnswers[cityFieldIndex].fieldValue
          } else {
            answerValue = formAnswers[fieldIndex].fieldValue
          }
        }
        if (newStatus == 'finalizada' && parseInt(field.fieldRequired) == 1 && answerValue === '') {
          errorInField = true;
          console.log('error in field', field.fieldCode)
          //set is-invalid class to the field
          let t = document.querySelectorAll('[name="' + field.fieldCode + '"]')
          t.forEach((item) => {
            item.classList.add('is-invalid')
          })
        }
        newAnswerData.push({
          activity_id: id,
          field_id: field.id,
          version_id: field.version_id,
          form_id: formData.id,
          answerValue: answerValue
        })
      } else {
        let answerValue, t;
        if (field.fieldType == 'select' && field.fieldMultiple == 1 || field.fieldType == 'checkbox') {
          answerValue = ''
        } else {
          if (field.fieldType == 'select') {
            t = document.querySelector('div[name="' + field.fieldCode + '"]')
            let selectedText = t?.querySelector('input')?.value || ''
            //search the value inside the field.dataArray array of objects which text is equal to selectedText
            let selectedValue = field.dataArray.find((option) => option.text == selectedText)?.value || ''
            answerValue = selectedValue
          } else {
            answerValue = ''
          }
          if (field.fieldType == 'address_dropdown') {
            let t_state = document.querySelector('div[name="' + field.fieldCode + '_state"]')
            let selectedText_state = t_state?.querySelector('input')?.value || ''
            let selectedValue_state = stateList.find((data) => data.stateName == selectedText_state)?.id || ''

            let t_city = document.querySelector('div[name="' + field.fieldCode + '_city"]')
            let selectedText_city = t_city?.querySelector('input')?.value || ''
            let selectedValue_city = cityList.find((data) => data.cityName == selectedText_city)?.id || ''

            answerValue = selectedValue_state + ',' + selectedValue_city
          }
        }
        if (newStatus == 'finalizada' && parseInt(field.fieldRequired) == 1 && answerValue === '') {
          errorInField = true;
          console.log('error in field', field.fieldCode)
          //set is-invalid class to the field
          let t = document.querySelectorAll('[name="' + field.fieldCode + '"]')
          t.forEach((item) => {
            item.classList.add('is-invalid')
          })
        }
        newAnswerData.push({
          activity_id: id,
          field_id: field.id,
          version_id: field.version_id,
          form_id: formData.id,
          answerValue: answerValue
        })
      }
    })
    if (errorInField) {
      setModalHandler({
        title: 'Atención',
        message: 'Por favor, complete todos los campos obligatorios',
        buttons: <button className="btn btn-primary" onClick={closeModal}>Aceptar</button>
      })
      setModalShow(true)
      return;
    }

    //set real date start and end

    let data = {};
    let prevStatus = activityData.activityStatus;
    if (activityData.activityStatus == 'creada') {
      data = {
        ...data,
        activityRealDateStart: moment().format('YYYY-MM-DD HH:mm:ss'),
      }
    }
    data = {
      ...data,
      activityStatus: newStatus
    }
    if (newStatus == 'finalizada') {
      data = {
        ...data,
        activityRealDateEnd: moment().format('YYYY-MM-DD HH:mm:ss'),
      }
    }

    await activityService.update(id, data);
    const activityDataObject = await activityService.get(id);
    setActivityData(activityDataObject)

    let form = {
      fields: newAnswerData
    }

    if (newStatus == 'en proceso' && prevStatus != 'creada') {
      console.log(form)
      await answerService.answerActivity(id, form)
      setModalHandler({
        ...modalHandler,
        title: 'Éxito',
        message: 'La actividad ha sido guardada',
        buttons: <>
          <MDBBtn
            color="secondary"
            onClick={
              () => {
                setModalShow(false)
              }
            }
          >
            Cerrar
          </MDBBtn>
        </>
      })
      setModalShow(true)
    }
    if (newStatus == 'finalizada') {

      await answerService.answerActivity(id, form)
      setModalHandler({
        ...modalHandler,
        title: 'Éxito',
        message: 'La actividad ha sido guardada y cerrada',
        buttons: <>
          <MDBBtn
            color="secondary"
            onClick={
              () => {
                setModalShow(false)
              }
            }
          >
            Cerrar
          </MDBBtn>
        </>
      })
      setModalShow(true)
    }

  }

  const handleFieldValueChange = async (fieldId, value, isArray = false, valueToRemove = null) => {
    let newFormAnswers = [...formAnswers];
    let fieldIndex = newFormAnswers?.findIndex((field) => field.fieldId == fieldId);
    if (fieldIndex > -1) {
      if (isArray) {
        let newArray = [...newFormAnswers[fieldIndex].fieldValue];
        if (value != null) {
          newArray.push(value)
          newFormAnswers[fieldIndex].fieldValue = newArray
        } else {
          let valueIndex = newArray.findIndex((val) => val == valueToRemove)
          newArray.splice(valueIndex, 1)
          newFormAnswers[fieldIndex].fieldValue = newArray
        }
        if (newArray.length == 0) {
          newFormAnswers.splice(fieldIndex, 1)
        }
      }
      else {
        newFormAnswers[fieldIndex].fieldValue = value
      }
    }
    else {
      let newField = {
        fieldId: fieldId,
        fieldValue: isArray ? [value] : value
      }
      newFormAnswers.push(newField)
    }

    let newField = formData.fields.find((field) => field.fieldCode == fieldId) || ''
    if (newField.fieldType == "file") {
      const fileResult = await files.upload(value)

      if (fileResult) {
        let insertField = {
          fieldId: fieldId,
          fieldValue: fileResult.id
        }

        if (newField.fieldMultiple == 0) {
          //replace field value in formAnswers
          let fieldIndex = newFormAnswers?.findIndex((field) => field.fieldId == fieldId);
          if (fieldIndex > -1) {
            newFormAnswers[fieldIndex].fieldValue = fileResult.id
          } else {
            newFormAnswers.push(insertField)
          }
        } else {
          //add field value in formAnswers
          let fieldIndex = newFormAnswers?.findIndex((field) => field.fieldId == fieldId);
          if (fieldIndex > -1) {
            newFormAnswers[fieldIndex].fieldValue.push(fileResult.id)
          } else {
            newFormAnswers.push(insertField)
          }
        }

        console.log(newFormAnswers)

        setFormAnswers(newFormAnswers)
      }
    } else {
      let t = document.querySelectorAll('[name="' + fieldId + '"]')
      t.forEach((item) => {
        item.classList.remove('is-invalid')
      })
      setFormAnswers(newFormAnswers)
    }
  }
  useEffect(() => {
    handleSelectedProfile()
    handleLoadStates()
  }, [])
  useEffect(() => {
    if (stateList.length > 0 && cityList.length == 0) {
      handleLoadCities(stateList[0].id)
    }
  }, [stateList])
  useEffect(() => {
    if (selectedProfile) {
      handleLoadActivity()
    }
  }, [selectedProfile])
  useEffect(() => {
    if (activityData) {
      handleLoadForm()
    }
  }, [activityData])
  useEffect(() => {
    if (formData?.fields && firstTimeLoad) {
      handleAnswerOnLoad(id)
      handleAnswerLoad(id)
      setNavButtons(true);
      setFirstTimeLoad(false);
    }
  }, [formData?.fields])
  useEffect(() => {
  }, [activityData])
  useEffect(() => {
    if (formAnswers.length > 0) {
      console.log(formAnswers)
    }
  }, [formAnswers])
  useEffect(() => {
    if (formAnswersOnLoad.length > 0) {
    }
  }, [formAnswersOnLoad])
  useEffect(() => {
  }, [formData])
  return (
    <>
      <SiteHeader
        selectedProfile={selectedProfile}
      />
      <MDBAnimation
        animation='fade-in'
        start='onLoad'
        duration={700}
      >
        <MDBRow className='my-4 ms-4'>
          <Breadcrumbs
            items={breadCrumbsData}
          />
        </MDBRow>
        <MDBContainer fluid>
          <MDBRow className='my-4 ms-4 row-flex'>
            <h4>Formulario {formData?.formName}</h4>
          </MDBRow>
          {
            (activityData?.activityStatus == 'en proceso' || activityData?.activityStatus == 'creada') && (
              <>
                <form ref={formRef}>
                  <MDBRow className='my-4 mx-4'>
                    {
                      formData?.fields?.map((field, index) => {
                        return (
                          <Fragment key={index}>
                            {
                              field.fieldType == 'text' &&
                              <>
                                <div className='col-lg-4 col-md-6 col-sm-12 my-4 px-4'>
                                  <MDBCard className='h-100'>
                                    <MDBCardBody>
                                      <p>
                                        {field.fieldName}
                                      </p>
                                      <MDBCol className=''>
                                        <MDBInput
                                          label={false}
                                          type='text'
                                          name={field.fieldCode}
                                          required={field.fieldRequired == '1'}
                                          className={'my-2'}
                                          onChange={(e) => {
                                            handleFieldValueChange(field.fieldCode, e.target.value)
                                          }
                                          }
                                          value={
                                            formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                          }
                                        />
                                      </MDBCol>
                                    </MDBCardBody>
                                  </MDBCard>
                                </div>
                              </>
                            }
                            {
                              field.fieldType == 'textarea' &&
                              <MDBCol lg={12} className='my-4 px-4'>
                                <MDBCard className='h-100'>
                                  <MDBCardBody>
                                    <p>
                                      {field.fieldName}
                                    </p>
                                    <MDBTextArea
                                      name={field.fieldCode}
                                      required={field.fieldRequired == '1'}
                                      rows={field.fieldRows}
                                      className={'my-2'}
                                      onChange={(e) => {
                                        handleFieldValueChange(field.fieldCode, e.target.value)
                                      }
                                      }
                                      value={
                                        formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                      }
                                    />
                                  </MDBCardBody>
                                </MDBCard>
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'number' &&
                              <div className='col-lg-4 col-md-6 col-sm-12 my-4 px-4'>
                                <MDBCard className='h-100'>
                                  <MDBCardBody>
                                    <p>
                                      {field.fieldName}
                                    </p>
                                    <MDBCol className=''>
                                      <MDBInput
                                        label={false}
                                        type='number'

                                        name={field.fieldCode}
                                        required={field.fieldRequired == '1'}
                                        kpi={field.fieldIsKPI == '1' ? 'true' : 'false'}
                                        min={field.fieldMin}
                                        max={field.fieldMax}
                                        step={field.fieldStep}
                                        className={'my-2'}
                                        onChange={(e) => {
                                          handleFieldValueChange(field.fieldCode, e.target.value)
                                        }
                                        }
                                        value={
                                          formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                        }
                                      />
                                    </MDBCol>
                                  </MDBCardBody>
                                </MDBCard>
                              </div>
                            }
                            {
                              field.fieldType == 'date' &&
                              <div className='col-lg-4 col-md-6 col-sm-12 my-4 px-4'>
                                <MDBCard className='h-100'>
                                  <MDBCardBody>
                                    <p>
                                      {field.fieldName}
                                    </p>
                                    <MDBCol className=''>
                                      <MDBInput
                                        label={false}
                                        type='date'
                                        name={field.fieldCode}
                                        required={field.fieldRequired == '1'}
                                        min={field.fieldMin}
                                        max={field.fieldMax}
                                        className={'my-2'}
                                        onChange={(e) => {
                                          handleFieldValueChange(field.fieldCode, e.target.value)
                                        }
                                        }
                                        value={
                                          formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                        }
                                      />
                                    </MDBCol>
                                  </MDBCardBody>
                                </MDBCard>
                              </div>
                            }
                            {
                              field.fieldType == 'time' &&
                              <div className='col-lg-4 col-md-6 col-sm-12 my-4 px-4'>
                                <MDBCard className='h-100'>
                                  <MDBCardBody>
                                    <p>
                                      {field.fieldName}
                                    </p>
                                    <MDBCol className=''>
                                      <MDBInput
                                        label={false}
                                        type='time'
                                        name={field.fieldCode}
                                        required={field.fieldRequired == '1'}
                                        min={field.fieldMin}
                                        max={field.fieldMax}
                                        className={'my-2'}
                                        onChange={(e) => {
                                          handleFieldValueChange(field.fieldCode, e.target.value)
                                        }
                                        }
                                        value={
                                          formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                        }
                                      />
                                    </MDBCol>
                                  </MDBCardBody>
                                </MDBCard>
                              </div>
                            }
                            {
                              field.fieldType == 'datetime' &&
                              <div className='col-lg-4 col-md-6 col-sm-12 my-4 px-4'>
                                <MDBCard className='h-100'>
                                  <MDBCardBody>
                                    <p>
                                      {field.fieldName}
                                    </p>
                                    <MDBCol className=''>
                                      <MDBInput
                                        label={false}
                                        type='datetime-local'
                                        name={field.fieldCode}
                                        required={field.fieldRequired == '1'}
                                        min={field.fieldMin}
                                        max={field.fieldMax}
                                        className={'my-2'}
                                        onChange={(e) => {
                                          handleFieldValueChange(field.fieldCode, e.target.value)
                                        }
                                        }
                                        value={
                                          formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                        }
                                      />
                                    </MDBCol>
                                  </MDBCardBody>
                                </MDBCard>
                              </div>
                            }
                            {
                              field.fieldType == 'select' &&
                              <>
                                <MDBCol lg={4} md={6} sm={12} className='my-4 px-4 d-flex align-items-stretch'>
                                  <MDBCard className='h-100'>
                                    <MDBCardBody>
                                      <p>
                                        {field.fieldName}
                                      </p>
                                      <MDBSelect
                                        name={field.fieldCode}
                                        required={field.fieldRequired == '1'}
                                        data={
                                          field.dataArray.map((item, optionIndex) => {
                                            return {
                                              text: item.text || '',
                                              value: item.value || '',
                                              score: item.score || '',
                                              defaultSelected: item.value == formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                            }
                                          })
                                        }
                                        multiple={field.fieldMultiple == '1'}
                                        selectAllLabel='Todos'
                                        className={'my-2'}
                                        onValueChange={
                                          (e) => {
                                            handleFieldValueChange(field.fieldCode, e.value)
                                          }
                                        }
                                      />
                                    </MDBCardBody>
                                  </MDBCard>
                                </MDBCol>
                              </>
                            }
                            {
                              field.fieldType == 'checkbox' &&
                              <MDBCol lg={4} md={6} sm={12} className='my-4 px-4'>
                                <MDBCard className='h-100'>
                                  <MDBCardBody>
                                    <p>
                                      {field.fieldName}
                                    </p>
                                    <div className={'my-2'}>
                                      {
                                        field.fieldOptions.split(',').map((option, optionIndex) => {
                                          return (
                                            <MDBCheckbox
                                              key={optionIndex}
                                              label={option}
                                              id={field.fieldCode + optionIndex}
                                              name={field.fieldCode}
                                              required={field.fieldRequired == '1'}
                                              value={field.fieldValues.split(',')[optionIndex] || ''}
                                              score={field.fieldScores.split(',')[optionIndex] || ''}
                                              onChange={(e) => {
                                                handleFieldValueChange(field.fieldCode,
                                                  e.target.checked ? e.target.value : null,
                                                  true,
                                                  e.target.value
                                                )
                                              }}
                                              /** 
                                               * checked
                                               * 1. Si el valor del campo es igual al valor de la opción
                                              */
                                              checked={
                                                formAnswers?.find(
                                                  (answer) => {
                                                    return answer.fieldId == field.fieldCode
                                                  }
                                                )?.fieldValue?.includes(
                                                  field.fieldValues.split(',')[optionIndex]
                                                ) || false

                                              }
                                            />
                                          )
                                        })
                                      }
                                    </div>
                                  </MDBCardBody>
                                </MDBCard>
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'radio' &&
                              <MDBCol lg={4} md={6} sm={12} className='my-4 px-4'>
                                <MDBCard className='h-100'>
                                  <MDBCardBody>
                                    <p>
                                      {field.fieldName}
                                    </p>
                                    <div className={'my-2'}>
                                      {
                                        field.fieldOptions.split(',').map((option, optionIndex) => {
                                          return (
                                            <MDBRadio
                                              key={optionIndex}
                                              label={option}
                                              id={field.fieldCode + optionIndex}
                                              name={field.fieldCode}
                                              required={field.fieldRequired == '1'}
                                              value={field.fieldValues.split(',')[optionIndex] || ''}
                                              score={field.fieldScores.split(',')[optionIndex] || ''}
                                              onChange={(e) => {
                                                handleFieldValueChange(field.fieldCode,
                                                  e.target.checked ? e.target.value : null
                                                )
                                              }}
                                              checked={
                                                field.fieldValues.split(',')[optionIndex] == formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || false
                                              }
                                            />
                                          )
                                        }
                                        )
                                      }
                                    </div>
                                  </MDBCardBody>
                                </MDBCard>
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'file' &&
                              <MDBCol lg={4} md={6} sm={12} key={index} className='col-lg-4 col-md-6 col-sm-12 my-4 px-4'>
                                <MDBCard className='h-100'>
                                  <MDBCardBody>
                                    <MDBFile
                                      label={field.fieldName}
                                      type='file'
                                      name={field.fieldCode}
                                      required={field.fieldRequired == '1'}
                                      multiple={field.fieldMultiple == '1'}
                                      accept={field.fieldAccept}
                                      size={'lg'}
                                      className={'my-2'}
                                      onChange={(e) => {
                                        handleFieldValueChange(field.fieldCode, e.target.files)
                                      }
                                      }
                                      />
                                      <MDBRow>
                                        {
                                          //check answer of this form and display fieldContent if it exist
                                          formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldContent && (
                                            <MDBCol lg={12} md={12} sm={12} className='my-4 px-4'>
                                              <MDBCard className='h-100'>
                                                <MDBCardBody
                                                  color='primary-color'
                                                >
                                                  <p>
                                                    Archivo adjunto
                                                  </p>
                                                  <div className={'my-2'}>
                                                    <MDBBtn
                                                      color='primary'
                                                      size='sm'
                                                      onClick={
                                                        () => {
                                                          let file = formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldContent;
                                                          //window.open(file.filePath, '_blank')
                                                        }
                                                      }
                                                      target='_blank'
                                                    >
                                                      Descargar
                                                    </MDBBtn>
                                                  </div>
                                                </MDBCardBody>
                                              </MDBCard>
                                            </MDBCol>
                                          )
                                        }
                                      </MDBRow>
                                  </MDBCardBody>
                                </MDBCard>
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'email' &&
                              <div className='col-lg-4 col-md-6 col-sm-12 my-4 px-4'>
                                <MDBCard className='h-100'>
                                  <MDBCardBody>
                                    <p>
                                      {field.fieldName}
                                    </p>
                                    <MDBCol className=''>
                                      <MDBInput
                                        label={false}
                                        type='email'
                                        name={field.fieldCode}
                                        required={field.fieldRequired == '1'}
                                        className={'my-2'}
                                        onChange={(e) => {
                                          handleFieldValueChange(field.fieldCode, e.target.value)
                                        }
                                        }
                                        value={
                                          formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                        }
                                      />
                                    </MDBCol>
                                  </MDBCardBody>
                                </MDBCard>
                              </div>
                            }
                            {
                              field.fieldType == 'url' &&
                              <div className='col-lg-4 col-md-6 col-sm-12 my-4 px-4'>
                                <MDBCard className='h-100'>
                                  <MDBCardBody>
                                    <p>
                                      {field.fieldName}
                                    </p>
                                    <MDBCol className=''>
                                      <MDBInput
                                        label={false}
                                        type='url'

                                        name={field.fieldCode}
                                        required={field.fieldRequired == '1'}
                                        className={'my-2'}
                                        onChange={(e) => {
                                          handleFieldValueChange(field.fieldCode, e.target.value)
                                        }
                                        }
                                        value={
                                          formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                        }
                                      />
                                    </MDBCol>
                                  </MDBCardBody>
                                </MDBCard>
                              </div>
                            }
                            {
                              field.fieldType == 'password' &&
                              <div className='col-lg-4 col-md-6 col-sm-12 my-4 px-4'>
                                <MDBCard className='h-100'>
                                  <MDBCardBody>
                                    <p>
                                      {field.fieldName}
                                    </p>
                                    <MDBCol className=''>
                                      <MDBInput
                                        label={false}
                                        type='password'

                                        name={field.fieldCode}
                                        required={field.fieldRequired == '1'}
                                        className={'my-2'}
                                        onChange={(e) => {
                                          handleFieldValueChange(field.fieldCode, e.target.value)
                                        }
                                        }
                                        value={
                                          formAnswers?.find((answer) => answer.fieldId == field.fieldCode)?.fieldValue || ''
                                        }
                                      />
                                    </MDBCol>
                                  </MDBCardBody>
                                </MDBCard>
                              </div>
                            }
                            {
                              field.fieldType == 'hidden' &&
                              <MDBCol lg={12} md={12} sm={12} key={index}>
                                <p>
                                  {field.fieldName}
                                </p>
                                <div className={'d-none'}>
                                  <div>
                                    <input
                                      type='hidden'
                                      className='d-none'
                                      name={field.fieldCode}
                                      value={field.fieldValue || ''}
                                    />
                                  </div>
                                  <p>
                                    &#60;
                                    {field.fieldValue}
                                    &#62;
                                  </p>
                                </div>
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'title' &&
                              <MDBCol lg={12} md={12} sm={12} className='my-4 px-4'>
                                <hr />
                                <h4 className={'my-2'}>{field.fieldStatement}</h4>
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'statement' &&
                              <MDBCol lg={12} md={12} sm={12} className='my-4 px-4'>
                                <p className={'my-2'}>{field.fieldStatement}</p>
                              </MDBCol>
                            }
                            {
                              field.fieldType == 'address_dropdown' &&
                              <MDBCol lg={12} md={12} sm={12} className='my-4 px-4'>
                                <MDBCard className='h-100'>
                                  <MDBCardBody>
                                    <MDBRow className={'my-2'}>
                                      <MDBCol size={6}>
                                        <MDBSelect
                                          label={'Región de ' + field.fieldName}
                                          name={field.fieldCode + '_state'}
                                          data={
                                            stateList.map((state, stateIndex) => {
                                              return {
                                                text: state.stateName,
                                                value: state.id,
                                                defaultSelected: state.id == formAnswers?.find((answer) => answer.fieldId == field.fieldCode + '_state')?.fieldValue || false
                                              }
                                            })
                                          }
                                          onValueChange={
                                            (e) => {
                                              handleLoadCities(e.value)
                                              handleFieldValueChange(field.fieldCode + '_state', e.value)
                                            }
                                          }
                                          search
                                          searchLabel='Buscar región'
                                        />
                                      </MDBCol>
                                      <MDBCol size={6}>
                                        <MDBSelect
                                          label={'Comuna de ' + field.fieldName}
                                          name={field.fieldCode + '_city'}
                                          data={
                                            cityList.map((city, cityIndex) => {
                                              return {
                                                text: city.cityName,
                                                value: city.id,
                                                defaultSelected: city.id == formAnswers?.find((answer) => answer.fieldId == field.fieldCode + '_city')?.fieldValue || false
                                              }
                                            })
                                          }
                                          onValueChange={
                                            (e) => {
                                              handleFieldValueChange(field.fieldCode + '_city', e.value)
                                            }
                                          }
                                          search
                                          searchLabel='Buscar comuna'
                                        />
                                      </MDBCol>
                                    </MDBRow>
                                  </MDBCardBody>
                                </MDBCard>
                              </MDBCol>
                            }

                          </Fragment>
                        )
                      }
                      )
                    }
                  </MDBRow>
                  <MDBRow className='my-4 ms-4 me-2 text-center'>
                    <MDBCol>
                      <MDBBtn
                        type='button'
                        color='primary'
                        className='me-4'
                        onClick={() =>
                          handleNextStatus('en proceso')
                        }
                      >
                        Guardar
                      </MDBBtn>
                      <MDBBtn
                        type='button'
                        color='primary'
                        className=''
                        outline
                        onClick={() =>
                          handleNextStatus('finalizada')
                        }
                      >
                        Guardar y finalizar
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </form>
              </>
            )
          }
          {
            activityData?.activityStatus == 'finalizada' && (
              <>
                <MDBRow className='my-4 ms-4'>
                  <p>
                    Actividad completada
                  </p>
                </MDBRow>
              </>
            )
          }
        </MDBContainer>
      </MDBAnimation>
      <MDBModal tabIndex='-1'
        show={modalShow}
        setShow={setModalShow}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                {modalHandler.title}
              </MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={
                () => setModalShow(false)
              }></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {modalHandler.message}
            </MDBModalBody>
            <MDBModalFooter>
              {modalHandler.buttons}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  )
}
