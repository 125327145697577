import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import {
    MDBAnimation,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBTextArea,
    MDBSelect,
    MDBCheckbox,
    MDBRadio,
    MDBFile,
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit'
import { MDBTreeTable, MDBTreeTableItem, MDBTreeTableHead, MDBTreeTableBody } from 'mdb-react-treetable';

import stateService from '../../services/states'
import slotService from '../../services/slots'
import cityService from '../../services/cities'
import versionCityService from '../../services/versionCities'
import versionInstitutionService from '../../services/versionInstitutions'
import activityTypeService from '../../services/activityTypes'
import activityService from '../../services/activities'
import institutionService from '../../services/institutions'
import userService from '../../services/users'
import roleService from '../../services/roles'
import userRoleService from '../../services/userRoles'
import SiteHeader from '../../components/SiteHeader'
import Breadcrumbs from '../../components/Breadcrumbs'
export default function SelfEvaluation() {
    const navigateTo = useNavigate();
    const [state, setState] = useState(null)
    const [entryId, setEntryId] = useState(null)
    const [userId, setUserId] = useState(null);
    const [modalAssignAdviserUserShow, setModalAssignAdviserUserShow] = useState(false);
    
    const [userData, setUserData] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [userRoleData, setUserRoleData] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [loadedData, setLoadedData] = useState(false);
    const { id } = useParams()


    const breadCrumbsData = [
        {
            text: 'Inicio',
            href: `/home`
        },
        {
            text: 'Versiones',
            href: `/versions`
        },
        {
            text: `Autodiagnósticos`,
        },
    ];

    const handleDataLoad = async () => {
        const activityTypes = await activityTypeService.listByVersion(id)
        const activities = await activityService.listByVersion(id)
        const institutions = await institutionService.list()
        const states = await stateService.list()
        const slots = await slotService.listByVersion(id)
        const cities = await cityService.list()
        const versionCities = await versionCityService.listByVersion(id)
        const versionInstitutions = await versionInstitutionService.listByVersion(id)

        let activityTypeSelfEvaluation = activityTypes.filter(activityType => activityType.activityTypeName === 'Autodiagnóstico')[0]

        let newLoadedData;

        newLoadedData = states.map(state => {
            return {
                ...state,
                slots: slots.filter(
                    slot => slot.state_id === state.id
                )[0],
                usedSlots: 0,
                cities: cities.filter(
                    city => city.state_id === state.id
                ),
                cities: cities.map(
                    city => {
                        let versionCityExist = versionCities.filter(
                            versionCity => versionCity.city_id === city.id
                        ).length > 0;
                        let versionCityStatus = null;
                        if (versionCityExist) {
                            versionCityStatus = versionCities.filter(
                                versionCity => versionCity.city_id === city.id
                            )[0].versionCityStatus
                        }
                        return {
                            ...city,
                            activities: activities
                                .filter(
                                    activity => activity.city_id === city.id
                                )
                                .filter(
                                    activity => activity.activityStatus === 'finalizada'
                                )
                                .filter(
                                    activity => activity.activityType_id === activityTypeSelfEvaluation.id
                                ),
                            selfEvaluationScore: activities
                                .filter(
                                    activity => activity.city_id === city.id
                                )
                                .filter(
                                    activity => activity.activityStatus === 'finalizada'
                                )
                                .filter(
                                    activity => activity.activityType_id === activityTypeSelfEvaluation.id
                                )[0]?.activityTotalScore || 0,
                            versionCityExist: versionCityExist,
                            versionCityStatus: versionCityStatus,
                        }
                    }
                ),
                institutions: institutions.filter(
                    institution => institution.state_id === state.id
                ).map(
                    institution => {
                        let versionInstitutionExist = versionInstitutions.filter(
                            versionInstitution => versionInstitution.institution_id === institution.id
                        ).length > 0;
                        let versionInstitutionStatus = null;
                        if (versionInstitutionExist) {
                            versionInstitutionStatus = versionInstitutions.filter(
                                versionInstitution => versionInstitution.institution_id === institution.id
                            )[0].versionInstitutionStatus
                        }
                        let thisCity = cities.filter(
                            city => city.id === institution.city_id
                        )[0];

                        let versionCityExist = versionCities.filter(
                            versionCity => versionCity.city_id === institution.city.id
                        ).length > 0;
                        let versionCityStatus = null;
                        if (versionCityExist) {
                            versionCityStatus = versionCities.filter(
                                versionCity => versionCity.city_id === institution.city.id
                            )[0].versionCityStatus
                        }

                        thisCity = {
                            ...thisCity,
                            versionCityExist: versionCityExist,
                            versionCityStatus: versionCityStatus,
                        }

                        return {
                            ...institution,
                            activities: activities
                                .filter(
                                    activity => activity.institution_id === institution.id
                                )
                                .filter(
                                    activity => activity.activityStatus === 'finalizada'
                                )
                                .filter(
                                    activity => activity.activityType_id === activityTypeSelfEvaluation.id
                                ),
                            selfEvaluationScore: activities
                                .filter(
                                    activity => activity.institution_id === institution.id
                                )
                                .filter(
                                    activity => activity.activityStatus === 'finalizada'
                                )
                                .filter(
                                    activity => activity.activityType_id === activityTypeSelfEvaluation.id
                                )[0]?.activityTotalScore || 0,
                            versionInstitutionExist: versionInstitutionExist,
                            versionInstitutionStatus: versionInstitutionStatus,
                            city: thisCity
                        }
                    }
                ).filter(
                    institution => institution.activities.length > 0
                ).sort(
                    (a, b) => b.selfEvaluationScore - a.selfEvaluationScore
                )
            }
        }).map(state => {
            //check all institutions in state that are in version
            let usedSlots = 0;
            state.institutions.forEach(institution => {
                if (institution.versionInstitutionExist) {
                    usedSlots += 1;
                }
            })

            return {
                ...state,
                usedSlots: usedSlots
            }
        })

        setLoadedData(newLoadedData)

        //console.log(newLoadedData)

    }

    const handleRemoveFromVersion = async (institutionId) => {
        const versionInstitution = await versionInstitutionService
            .listByVersion(id)

        const filtered = versionInstitution.filter(versionInstitution => versionInstitution.institution_id === institutionId)

        if (filtered.length > 0) {
            await versionInstitutionService.remove(filtered[0].id)
            handleDataLoad()
        }

    }

    const handleAddToVersion = async (institutionId) => {
        const versionInstitution = await versionInstitutionService
            .listByVersion(id)

        const filtered = versionInstitution.filter(versionInstitution => versionInstitution.institution_id === institutionId)

        if (filtered.length == 0) {
            await versionInstitutionService.create({
                version_id: id,
                institution_id: institutionId
            })
            handleDataLoad()
        }
    }

    const toggleAssignAdviserUserModal = (newState) => {
        setModalAssignAdviserUserShow(newState);
    }

    const btnActionAssignAdviserUser = (item, id) => {
        setEntryId(id);
        let newState = { ...item };
        setState(newState);
        toggleAssignAdviserUserModal(true);
    }

    const loadUserData = async () => {
        let userList = await userService.list();
        setUserData(
            userList
                .filter((item) => item.userAccountStatus === 'activa')
                .map((item) => {
                    return {
                        value: item.id,
                        text: item.userName + ' - ' + item.userEmail
                    }
                })
        )
    }

    useEffect(() => {
        if (userId) {
            setUserData(
                userData.map((item) => {
                    if (item.value === userId) {
                        return {
                            ...item,
                            defaultSelected: true,
                        }
                    } else {
                        return {
                            ...item,
                            defaultSelected: false
                        }
                    }
                }
                )
            )
        }
    }, [userId])

    const loadRoleData = async () => {
        let roleList = await roleService.list();
        setRoleData(
            roleList.map((item) => {
                return {
                    value: item.id,
                    text: item.roleName
                }
            })
        )
    }

    const handleAssignAdviserUser = async (newUserId) => {
        setUserId(newUserId);
        let institution_id = entryId;
        let user_id = newUserId;
        //get role "Establecimiento"
        let role = roleData.find((item) => item.text === 'Asesor');

        let userRoleInInstitution = await userRoleService.list()
        userRoleInInstitution = userRoleInInstitution.find((item) => item.user_id === user_id && item.role_id === role.value && item.institution_id === institution_id);
        if (userRoleInInstitution) {
            let onConfirm = confirm('El usuario ya tiene asignado el rol de Asesor en este establecimiento ¿Desea remover el rol de Asesor al usuario?')
            if (onConfirm) {
                await userRoleService.remove(userRoleInInstitution.id);
                alert('Asesor removido correctamente del establecimiento');
            } else {
                alert('El asesor no fue removido del establecimiento')
            }
            toggleAssignAdviserUserModal(false);
        } else {
            await userRoleService.create({
                user_id,
                role_id: role.value,
                institution_id
            });
            alert('Usuario asignado correctamente');
            toggleAssignAdviserUserModal(false);
        }
    }

    useEffect(() => {
        loadRoleData();
        loadUserData();
        handleDataLoad()
    }, [])

    return (
        <>
            <SiteHeader
                selectedProfile={selectedProfile}
            />
            <MDBAnimation
                animation='fade-in'
                start='onLoad'
                duration={700}
            >
                <MDBRow className='my-4 ms-4'>
                    <Breadcrumbs
                        items={breadCrumbsData}
                    />
                </MDBRow>
                <MDBContainer>
                    {
                        loadedData &&
                        <MDBTreeTable>
                            <MDBTreeTableHead heads={['Nombre', 'Comuna', 'Valor', '']} />
                            <MDBTreeTableBody>
                                {
                                    loadedData
                                        .filter(
                                            state => state.institutions.filter(
                                                institution => institution.activities.filter(
                                                    activity => activity.activityStatus === 'finalizada'
                                                ).length > 0
                                            ).length > 0
                                        )
                                        .map(state => {
                                            return <Fragment key={state.id}>
                                                <MDBTreeTableItem
                                                    className={
                                                        state.slots?.slotQuantity > 0 ? 'tree-item text-success' : 'tree-item text-danger'
                                                    }
                                                    depth={1}
                                                    values={
                                                        [
                                                            'Región: ' + state.stateName,
                                                            'Comunas: '
                                                            + state.institutions
                                                                .filter(
                                                                    institution => {
                                                                        return institution
                                                                            .activities
                                                                            .filter(activity => activity.activityStatus === 'finalizada')
                                                                    }
                                                                ).length,
                                                            `Cupos ocupados: ${state.usedSlots} de ${state.slots?.slotQuantity || 0}`,
                                                            ''
                                                        ]
                                                    }
                                                />
                                                {
                                                    state.institutions
                                                        .filter(
                                                            institution => institution.activities.filter(
                                                                activity => activity.activityStatus === 'finalizada'
                                                            ).length > 0
                                                        )
                                                        .map(
                                                            institution => {
                                                                //check versionCities to see if the institution is in the version
                                                                return <Fragment key={institution.id}>
                                                                    <MDBTreeTableItem
                                                                        depth={2}
                                                                        className={
                                                                            `tree-item 
                                                                            ${institution.city.versionCityStatus === 'inactiva' ?
                                                                                'text-warning' :
                                                                                'text-success'
                                                                            }
                                                                            `}
                                                                        values={
                                                                            [
                                                                                'Establecimiento: ' + institution.institutionName + ' (' + institution.institutionRBD + ')',
                                                                                'Comuna: ' + institution.city.cityName + ' (' + institution.city.versionCityStatus + ')',
                                                                                'Puntaje: ' + institution.activities[0].activityTotalScore,
                                                                                <>
                                                                                    <MDBBtn
                                                                                        color='success'
                                                                                        onClick={() => {
                                                                                            navigateTo(`/check/self-evaluation/${institution.activities[0].id}`, {
                                                                                                state: {
                                                                                                    origin: '/tree/self-evaluation',
                                                                                                    version: id,
                                                                                                }
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        Ver
                                                                                    </MDBBtn>
                                                                                    {
                                                                                        institution?.versionInstitutionExist && institution?.versionInstitutionStatus == 'en espera' &&
                                                                                        <MDBBtn
                                                                                            color='info'
                                                                                            className='ms-2'
                                                                                            outline
                                                                                            onClick={() => {
                                                                                                btnActionAssignAdviserUser(institution, institution.id)
                                                                                            }}
                                                                                        >
                                                                                            Asesor
                                                                                        </MDBBtn>
                                                                                    }
                                                                                    {
                                                                                        institution.versionInstitutionExist ?
                                                                                            institution.versionInstitutionStatus === 'en espera' &&
                                                                                            <MDBBtn
                                                                                                color='danger ms-2'
                                                                                                outline
                                                                                                onClick={() => {
                                                                                                    handleRemoveFromVersion(institution.id)
                                                                                                }}
                                                                                            >
                                                                                                Quitar
                                                                                            </MDBBtn>
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    state.slots?.slotQuantity > 0
                                                                                                    && state.usedSlots < state.slots?.slotQuantity
                                                                                                    && institution.city.versionCities?.versionCityStatus !== 'activa'
                                                                                                    &&
                                                                                                    <MDBBtn
                                                                                                        color='danger ms-2'
                                                                                                        onClick={() => {
                                                                                                            handleAddToVersion(institution.id)
                                                                                                        }}
                                                                                                    >
                                                                                                        Escoger
                                                                                                    </MDBBtn>
                                                                                                }
                                                                                                {
                                                                                                    state.slots?.slotQuantity > 0
                                                                                                    && state.usedSlots < state.slots?.slotQuantity
                                                                                                    && institution.city.versionCities?.versionCityStatus === 'activa'
                                                                                                    &&
                                                                                                    <MDBBtn
                                                                                                        color='primary ms-2'
                                                                                                        onClick={() => {
                                                                                                            handleAddToVersion(institution.id)
                                                                                                        }}
                                                                                                    >
                                                                                                        Escoger
                                                                                                    </MDBBtn>
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                            ]
                                                                        }
                                                                    />
                                                                </Fragment>
                                                            }
                                                        )
                                                }
                                            </Fragment>
                                        })
                                }
                            </MDBTreeTableBody>
                        </MDBTreeTable>
                    }
                    {
                        !loadedData &&
                        <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                            <div className='spinner-border text-primary' role='status'>
                                <span className='visually-hidden'>Cargando...</span>
                            </div>
                        </MDBRow>
                    }
                </MDBContainer>
            </MDBAnimation>
            <MDBModal tabIndex='-1'
                show={modalAssignAdviserUserShow}
                setShow={setModalAssignAdviserUserShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Asignar asesor</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleAssignAdviserUserModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>
                                ¿Está seguro que desea asignar el asesor al establecimiento {state?.institutionName}?
                            </p>
                            <MDBSelect
                                label='Usuario'
                                name='adviserUserId'
                                data={
                                    userData
                                }
                                onValueChange={
                                    (e) => {
                                        setUserId(e.value);
                                    }
                                }
                                className='mb-4'
                                search
                                searchLabel='Buscar'
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleAssignAdviserUserModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    if (userId == null) {
                                        handleAssignAdviserUser(userData[0].value);
                                    } else {
                                        handleAssignAdviserUser(userId);
                                    }
                                }}
                            >Asignar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            
        </>
    )
}
