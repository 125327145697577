import APIDriver from './api/APIDriver';

const api = new APIDriver('notifications');

const service = {
    notifyCreatedAccounts: () => {
        api.setBearerProtection();
        return api.post('notify-created-accounts');
    },
    notifySelectedInstitutions: () => {
        api.setBearerProtection();
        return api.post('notify-selected-institutions');
    },
    passwordRecoverEmail: (userEmail) => {
        api.unsetBearerProtection();
        return api.post('password-recovery-email', {
            userEmail: userEmail
        });
    }
}

export default service;