import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    MDBAnimation,
    MDBContainer,
    MDBRow,
    MDBDatatable,
    MDBBtn,
    MDBIcon,
    MDBCol,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBSelect,
} from 'mdb-react-ui-kit'
import SiteHeader from '../components/SiteHeader'
import Breadcrumbs from '../components/Breadcrumbs'
import encryption from '../services/encryption'
import version from '../services/versions'
import modulePermissions from '../utilities/modulePermissions'

export default function Versions() {

    const moduleName = 'version';
    const navigateTo = useNavigate();
    const [selectedProfile, setSelectedProfile] = useState(null);

    const [modalAddShow, setModalAddShow] = useState(false);
    const [modalEditShow, setModalEditShow] = useState(false);
    const [modalDeleteShow, setModalDeleteShow] = useState(false);

    const [entryId, setEntryId] = useState(null);
    const [versionStatusList, setVersionStatusList] = useState([
        {
            value: 'creada',
            text: 'Creada'
        },
        {
            value: 'activa',
            text: 'Activa'
        },
        {
            value: 'inactiva',
            text: 'Inactiva'
        }
    ]); // [
    const [formData, setFormData] = useState(
        {
            versionName: '',
            versionStatus: 'creada',
        }
    );
    const [modules, setModules] = useState({});

    const breadCrumbsData = [
        {
            text: 'Inicio',
            href: `/home`
        },
        {
            text: 'Versiones',
        }
    ];

    const handleSelectedProfile = () => {
        let token = localStorage.getItem('selectedProfile');
        if (token) {
            let descryptedToken = encryption.decrypt(token);
            setSelectedProfile(descryptedToken);
        }
    }

    const toggleAddModal = () => {
        if (modalAddShow === false) {
            setFormData({
                versionName: '',
                versionStatus: 'creada',
            });
        }
        setModalAddShow(!modalAddShow);
    }

    const toggleEditModal = (newState) => {
        setModalEditShow(newState);
    }

    const toggleDeleteModal = (newState) => {
        setModalDeleteShow(newState);
    }

    const btnActionEdit = (item) => {
        setEntryId(item.id);
        setFormData({
            versionName: item.versionName,
            versionStatus: item.versionStatus,
        });
        toggleEditModal(true);
    }

    const btnActionDelete = (item) => {
        setEntryId(item.id);
        setFormData({
            versionName: item.versionName,
            versionStatus: item.versionStatus,
        });
        toggleDeleteModal(true);
    }

    const [asyncData, setAsyncData] = useState({
        columns: [
            {
                label: 'ID',
                field: 'id',
            },
            {
                label: 'Nombre',
                field: 'versionName',
            },
            {
                label: 'Estado',
                field: 'versionStatus',
            },
            {
                label: 'Acciones',
                field: 'actions',
            },
        ],
        rows: []
    });

    const [dataLoading, setDataLoading] = useState(false);

    const handleDataLoad = async () => {
        const data = await version.list()
        setAsyncData({
            columns: asyncData.columns,
            rows: data.map((item, index) => {
                return {
                    id: index + 1,
                    versionName: item.versionName,
                    versionStatus: versionStatusList.find((status) => status.value === item.versionStatus).text,
                    actions: <>
                        {
                            selectedProfile && modules?.versions?.includes('update') && (
                                <MDBBtn color='info' size='lg' className='me-2' floating onClick={() => btnActionEdit(item)}>
                                    <MDBIcon icon='edit' />
                                </MDBBtn>
                            )
                        }
                        {
                            selectedProfile && modules?.versions?.includes('delete') && (
                                <MDBBtn color='danger' size='lg' className='me-2' floating onClick={() => btnActionDelete(item)}>
                                    <MDBIcon icon='trash' />
                                </MDBBtn>
                            )
                        }
                        {
                            selectedProfile && modules?.versions?.includes('internal') && (
                                <MDBBtn
                                    color='secondary'
                                    size='lg'
                                    floating
                                    type='button'
                                    onClick={() => {
                                        navigateTo(`/${moduleName}/${item.id}`);
                                    }}
                                >
                                    <MDBIcon icon='eye' />
                                </MDBBtn>
                            )
                        }
                    </>
                }
            })

        })
    }

    const handleCreate = async () => {
        await version.create(formData);
        handleDataLoad();
        toggleAddModal();
        setFormData({
            versionName: '',
            versionStatus: 'creada',
        });
    }

    const handleUpdate = async () => {
        await version.update(entryId, formData);
        handleDataLoad();
        toggleEditModal(false);
    }

    const handleDelete = async () => {
        await version.remove(entryId);
        handleDataLoad();
        setFormData({
            versionName: '',
            versionStatus: 'creada',
        });
        toggleDeleteModal(false);
    }

    const handlePageModules = async () => {
        let modulesData = {};
        modulesData = {
            versions: [],
            institutions: []
        }
        let available = false;

        available = await modulePermissions(selectedProfile.roleName, 'versions', 'create');
        if (available) modulesData.versions.push('create');
        available = await modulePermissions(selectedProfile.roleName, 'versions', 'update');
        if (available) modulesData.versions.push('update');
        available = await modulePermissions(selectedProfile.roleName, 'versions', 'delete');
        if (available) modulesData.versions.push('delete');
        available = await modulePermissions(selectedProfile.roleName, 'versions', 'internal');
        if (available) modulesData.versions.push('internal');

        setModules(modulesData);
    }

    useEffect(() => {
    }, [modules]);

    useEffect(() => {
        if (selectedProfile) {
            handlePageModules();
        }
    }, [selectedProfile]);

    useEffect(() => {
        handleDataLoad();
    }, [dataLoading, asyncData.columns, modules]);

    useEffect(() => {
        if (asyncData.rows.length === 0) {
            setDataLoading(true);
        } else {
            setDataLoading(false);
        }
    }, [asyncData.rows]);

    useEffect(() => {
        handleSelectedProfile();
    }, []);

    return (
        <>
            <SiteHeader
                selectedProfile={selectedProfile}
            />
            <MDBAnimation
                animation='fade-in'
                start='onLoad'
                duration={700}
            >
                <MDBRow className='my-4 ms-4'>
                    <Breadcrumbs
                        items={breadCrumbsData}
                    />
                </MDBRow>
                <MDBContainer>
                    <MDBRow className='my-4 ms-4'>
                        <h4>Versiones</h4>
                    </MDBRow>
                    {
                        selectedProfile && modules?.versions?.includes('create') && (
                            <MDBRow className='text-end'>
                                <MDBCol className='me-0 pe-0'>
                                    <MDBBtn color='primary' className='my-4' size='lg' floating onClick={() => {
                                        toggleAddModal();
                                    }}>
                                        <MDBIcon icon='plus' />
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        )
                    }
                    <MDBRow>
                        <MDBDatatable
                            data={asyncData}
                            rowsText='Filas'
                            ofText='de'
                            searchLabel='Buscar'
                            search
                            noFoundMessage='No se encontraron resultados'
                            isLoading={dataLoading}
                            searchInputProps={
                                {
                                    'className': 'pb-1',
                                }
                            }
                        />
                    </MDBRow>
                </MDBContainer>
            </MDBAnimation>

            <MDBModal tabIndex='-1' show={modalAddShow} setShow={setModalAddShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Agregar versión</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleAddModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput
                                label='Nombre versión'
                                value={formData.versionName}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        versionName: e.target.value
                                    })
                                }}
                                className='mb-4'
                            />
                            <MDBSelect
                                label='Estado'
                                name='versionStatus'
                                id='versionStatus'
                                data={
                                    versionStatusList.map((item, index) => {
                                        return {
                                            text: item.text,
                                            value: item.value,
                                            defaultSelected: item.value === formData.versionStatus
                                        }
                                    })
                                }
                                onValueChange={
                                    (e) => {
                                        setFormData({
                                            ...formData,
                                            versionStatus: e.value
                                        })
                                    }
                                }
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={toggleAddModal}>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                color='primary'
                                onClick={
                                    () => {
                                        handleCreate()
                                    }
                                }
                            >Insertar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1' show={modalEditShow} setShow={setModalEditShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Editar versión</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleEditModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput
                                label='Nombre versión'
                                value={formData.versionName}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        versionName: e.target.value
                                    })
                                }}
                                className='mb-4'
                            />
                            <MDBSelect
                                label='Estado'
                                name='versionStatus'
                                id='versionStatus'
                                data={
                                    versionStatusList.map((item, index) => {
                                        return {
                                            text: item.text,
                                            value: item.value,
                                            defaultSelected: item.value === formData.versionStatus
                                        }
                                    })
                                }
                                onValueChange={
                                    (e) => {
                                        setFormData({
                                            ...formData,
                                            versionStatus: e.value
                                        })
                                    }
                                }
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleEditModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    handleUpdate();
                                }}
                            >Actualizar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal tabIndex='-1' show={modalDeleteShow} setShow={setModalDeleteShow}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Eliminar versión</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={
                                () => toggleDeleteModal(false)
                            }></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>¿Está seguro que desea eliminar la versión {formData.versionName}?</p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' outline onClick={
                                () => toggleDeleteModal(false)
                            }>
                                Cancelar
                            </MDBBtn>
                            <MDBBtn
                                onClick={() => {
                                    handleDelete();
                                }}
                            >Eliminar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}
