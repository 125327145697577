import React, { useState, useEffect } from 'react';
import {
    MDBInput,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBContainer,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter

} from 'mdb-react-ui-kit';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import auth from '../services/authentication'
import userService from '../services/users'
import LOGO from '../assets/img/logo-color.png';

export default function AccountActivate() {

    const [loading, setLoading] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [userData, setUserData] = useState({
        userName: '',
        userEmail: '',
        userId: ''
    });

    const [formValue, setFormValue] = useState({
        userPassword: '',
        userPasswordConfirm: ''
    });

    const navigateTo = useNavigate();

    const { user_id, token } = useParams();

    const onChange = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const toggleShow = () => {
        setDisplayModal(!displayModal);
    };

    const handleUserDataLoad = async () => {
        try {
            const userDataResult = await userService.get(user_id);
            if (!userDataResult) {
                throw new Error('No se pudo cargar la información del usuario');
            }
            if (userDataResult) {
                setUserData({
                    userName: userDataResult.userName,
                    userEmail: userDataResult.userEmail,
                    userId: userDataResult.id
                });
            } else {
                console.log(result)
                throw new Error('No se pudo cargar la información del usuario');
            }
        } catch (error) {
            document.getElementById('modalMessage').innerHTML = error;
            toggleShow();
            //after 2000 miliseconds, redirect to sign-in page
            setTimeout(() => {
                //navigateTo('/sign-in');
            }, 5000);
        }
    }

    useEffect(() => {
        handleUserDataLoad()
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        setTimeout(async () => {
            try {

                if (formValue.userPassword.length < 6) {
                    document.getElementById('modalMessage').innerHTML = 'La contraseña debe tener al menos 6 caracteres';
                    toggleShow();
                    setLoading(false);
                    return;
                }

                if (formValue.userPassword !== formValue.userPasswordConfirm) {
                    document.getElementById('modalMessage').innerHTML = 'Las contraseñas no coinciden';
                    toggleShow();
                    setLoading(false);
                    return;
                }

                const result = await userService.updatePasswordOnReset({
                    userEmail: userData.userEmail,
                    userPassword: formValue.userPassword,
                    userPasswordConfirmation: formValue.userPasswordConfirm,
                    userPasswordRecoveryToken: token
                })

                if (result) {
                    if (result.success === false) {
                        throw new Error(result.message);
                    }
                }

                alert('Contraseña actualizada correctamente')

                await auth.sign_in(userData.userEmail, formValue.userPassword)
                navigateTo('/profile-selector');
            } catch (error) {
                if (error.name === "AxiosError") {
                    document.getElementById('modalMessage').innerHTML = error;
                    toggleShow();
                } else {
                    document.getElementById('modalMessage').innerHTML = error;
                    toggleShow();
                }
            }
            setLoading(false);
        }, 1000);

    };

    return (

        <MDBContainer className='vh-100'>
            <MDBModal tabIndex='-1' show={displayModal} setShow={setDisplayModal}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Aviso</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p id='modalMessage'>
                            </p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleShow}>
                                Ok
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                {
                    loading ? (
                        <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>Cargando...</span>
                        </div>
                    ) : (
                        <MDBCol lg={'6'} md={'8'}>
                            <MDBRow className=' text-center'>
                                <MDBCol
                                    size="12"
                                    className='my-4'
                                >
                                    <img src={LOGO} alt='Logo' className='img-fluid mx-auto w-75' />
                                    <hr />
                                    <h1 className='text-center'>Iniciar sesión</h1>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol
                                    size="12"
                                    className='my-4'
                                >
                                    <h2 className='text-center'>Reiniciar contraseña</h2>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow tag="form" className='d-flex justify-content-center align-items-center'>
                                <MDBCol
                                    size="12"
                                    className='my-2'
                                >
                                    Bienvenido(a), {userData.userName}. Por favor ingrese su nueva contraseña para continuar.
                                </MDBCol>
                                <MDBCol
                                    size="12"
                                    className='my-2'
                                >
                                    <MDBInput
                                        value={formValue.userPassword}
                                        name='userPassword'
                                        onChange={onChange}
                                        required
                                        type='password'
                                        label='Contraseña nueva'
                                        className='mb-2'
                                    />
                                    <MDBInput
                                        value={formValue.userPasswordConfirm}
                                        name='userPasswordConfirm'
                                        onChange={onChange}
                                        required
                                        type='password'
                                        label='Confirme su nueva contraseña'
                                    />
                                </MDBCol>
                                <MDBCol size="12" className='text-end my-2'>
                                    <MDBBtn
                                        type='submit'
                                        onClick={
                                            (e) => onSubmit(e)
                                        }
                                    >Confirmar y entrar</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    )
                }
            </MDBRow>

        </MDBContainer>

    );
}