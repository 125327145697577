import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBDatatable } from 'mdb-react-ui-kit';
import version from './services/versions';

/* Set logics to handle signon credentials and redirect to each profile screen */

function App() {

  const navigateTo = useNavigate();

  const [userCredentials, setUserCredentials] = useState(null);

  useEffect(() => {
    const credentials = localStorage.getItem('token');
    if (credentials) {
      setUserCredentials(credentials);
    }
  }, []);

  useEffect(() => {
    if (userCredentials) {
      navigateTo('/profile-selector');
    } else {
      navigateTo('/sign-in');
    }
  }, [userCredentials]);

  return;
}

export default App;
