import CryptoJS from 'crypto-js';

const encrypt = (data) => {

    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_ENCRYPTION_KEY).toString();
    return ciphertext;

}

const decrypt = (data) => {

    var bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_ENCRYPTION_KEY);

    try {
        var plaintext = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return plaintext;
    } catch (e) {
        return null;
    }
}

const toExport = {
    encrypt,
    decrypt
}

export default toExport